import React, {  useState } from 'react';
import Sidebar from '../common/sidebar/Sidebar';
import { axiosInstance } from '../../config';
import { useNavigate } from 'react-router-dom'



const AdminAddFaqCategory = () => {
  const navigate = useNavigate();
  const [questiontype, setquestiontype] = useState()
  const [loader, setloader] = useState(false)



  const togglehandle = (e) => {
    e.persist();
    var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav")
    container.classList.toggle("active-cont");
  }


  const handleUserdataSubmit = async (e) => {
    e.preventDefault();

    if (questiontype == undefined) {
      alert("Fill Question Type")
      return
    }
    setloader(true)

    try {
      const { data } = await axiosInstance.post("/api/faqcategory", {
        "faqQuestionType": questiontype,
      })
      setloader(false)

      alert(data.message)
      navigate("/admin/faqcategory")

    } catch (error) {
      setloader(false)
      alert(error.response.data)

    }
  }


  return (
    <>

      <Sidebar />

      <div className="p-1 my-container active-cont">
        {/* <!-- Top Nav --> */}
        <nav className="navbar top-navbar navbar-light bg-light px-5">
          <a className="btn border-0" id="menu-btn" onClick={togglehandle}><i className="bx bx-menu"></i></a>
        </nav>
        {/* <!--End Top Nav --> */}
        <h3 className="text-dark p-3">ADMIN ADD FAQ 💻 📱
        </h3>



        <div className="row mx-2 " style={{ backgroundColor: "#FDF8DB" }}>


          {/* <div className="toogler"><h3>Add Review</h3></div> */}

          <form onSubmit={handleUserdataSubmit}>
            <div className="col-md-6 align-items-center">
              <label> Question Type Name</label>
              <input className="form-control " type="text" onChange={e => setquestiontype(e.target.value)} placeholder=" Enter Question Type" />
            </div>
            
            

            <div className="col-md-6" style={{ padding: "5px" }}>
              <div className="d-flex flex-row">
                {
                  loader ? <>
                   <button className="btn btn-success" disabled>Please Wait...</button>
                  </>: <button className="btn btn-success" >Submit</button>
                }
               
              </div>
            </div>
          </form>

        </div>

      </div>

    </>
  )
}

export default AdminAddFaqCategory