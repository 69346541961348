import React from "react";
// http://layerdrops.com/moniz/assets/images/resources/reasons-img-1.jpg"
import EquipmentImg from "../../images/safetypage/expertisecert.png";
const Certifications = () => {
  return (
    <section
      class="reasons"
      id="safety"
      className="py-5"
      style={{ backgroundColor: "#414142" }}
    >
      <div class="container">
        <div class="row d-flex ">
          <div class="col-xl-6 col-lg-6 d-flex align-items-streatch">
            <img
              style={
                {
                  // height: "64vh",
                }
              }
              className="d-none d-lg-block img-fluid"
              src={EquipmentImg}
              alt=""
              loading="lazy"
            />
          </div>
          {/* <div class="col-xl-1 col-lg-1"></div> */}
          <div class="col-xl-6 col-lg-6">
            <div class="reasons__right ProcessBar p-3 ml-3">
              <div class="section-title text-left">
                {/* <span class="section-title__tagline">Reasons why we are best</span> */}
                <h2 class="section-title__title text-white">
                  <b style={{ color: "#FEBD15" }}>Expertise</b> And{" "}
                  <b style={{ color: "#FEBD15" }}>Certifications</b>
                  <hr
                    style={{
                      marginTop: "0px",
                      height: "2px",
                    }}
                  />
                </h2>
              </div>
              <p
                class="reasons__text text-white"
                style={{
                  textAlign: "left",
                  // lineHeight: "2rem",
                }}
              >
                Our Team Members are experienced and qualified in their own right, and work constantly to keep their skills and qualifications up to date in a quickly evolving industry.
We have certifications from Premier Mountaineering Institutes in India, National Outdoor Leadership School (NOLS USA), Industrial Rope Access Trade Association (IRATA), Rigging Masterclass Certification by Event Equipment & Service Association (EESA). Prolyte Campus Asian Rigging Course, Stage Automation Courses and more.
              </p>
              {/* <ul class="list-unstyled reasons__list">
                <li>
                  <div class="">
                    <span class="">
                      <i
                        class="fa-solid fa-check"
                        style={{
                          fontSize: "1.4rem",
                          color: "#febd15",
                        }}
                      ></i>
                    </span>
                  </div>
                  <div class="text">
                    <b>
                      {" "}
                      <p class="text-white">
                        Certification from National Outdoor Leadership School
                        (NOLS)
                      </p>
                    </b>
                  </div>
                </li>
                <li>
                  <div class="">
                    <span class="">
                      <i
                        class="fa-solid fa-check"
                        style={{
                          fontSize: "1.4rem",
                          color: "#febd15",
                        }}
                      ></i>
                    </span>
                  </div>
                  <div class="text">
                    <b>
                      {" "}
                      <p class="text-white">
                        Certification from Wilderness Medicine Institute of
                        America
                      </p>
                    </b>
                  </div>
                </li>
                <li>
                  <div class="">
                    <span class="">
                      <i
                        class="fa-solid fa-check"
                        style={{
                          fontSize: "1.4rem",
                          color: "#febd15",
                        }}
                      ></i>
                    </span>
                  </div>
                  <div class="text">
                    <b>
                      {" "}
                      <p class="text-white">
                        Certification from Nehru Institute of Mountaineering
                      </p>
                    </b>
                  </div>
                </li>
                <li>
                  <div class="">
                    <span class="">
                      <i
                        class="fa-solid fa-check"
                        style={{
                          fontSize: "1.4rem",
                          color: "#febd15",
                        }}
                      ></i>
                    </span>
                  </div>
                  <div class="text">
                    <b>
                      {" "}
                      <p class="text-white">IRATA Level 1</p>
                    </b>
                  </div>
                </li>
                <li>
                  <div class="">
                    <span class="">
                      <i
                        class="fa-solid fa-check"
                        style={{
                          fontSize: "1.4rem",
                          color: "#febd15",
                        }}
                      ></i>
                    </span>
                  </div>
                  <div class="text">
                    <b>
                      <p class="text-white">
                        Prolyte Campus Asian Rigging Course
                      </p>
                    </b>
                  </div>
                </li>
              </ul> */}
              {/* <a href="contact.html" class="thm-btn reasons__btn"><span>Free consultation</span></a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certifications;
