import React, { useEffect, useState } from "react";
import bg from "../images/client/logohori.png";
import { Link } from "react-router-dom";
import { AiOutlineSafety, AiOutlineTeam } from "react-icons/ai";
import { FaQrcode } from "react-icons/fa";
import { AiTwotoneHome } from "react-icons/ai";
import { ImSearch } from "react-icons/im";
import { axiosInstance } from "../config";
import { BsFillChatRightQuoteFill } from "react-icons/bs";
// import bg from "../images/client/logohori.png";
export const Header = () => {
  const [category, setcategory] = useState();
  const [headerclose, setheaderclose] = useState(true);

  const closemenu = (e) => {
    setheaderclose(false);
    btnn();
  };
  setTimeout(() => {
    setheaderclose(true);
  }, 100);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const btnn = () => {
    let btn = document.querySelector(".menu_btn");
    let navbar = document.querySelector(".nav_icon");

    navbar.classList.toggle("mobile");
    btn.classList.toggle("fa-times");
  };

  const getcategory = async () => {
    try {
      const { data } = await axiosInstance.get("/api/category");
      setcategory(data);
    } catch (error) {}
  };
  useEffect(() => {
    getcategory();
  }, []);

  return (
    <div>
      {/* <!-- <Header Start============ --> */}

      <header className="">
        <nav
        // px-lg-5 px-5 ps-0 uncomment
          className="header_box  px-2 "
          style={{ backgroundColor: "#FEBD15" ,}}
        >
          <div
            className="rounded  py-2 d-lg-none d-md-none d-flex justify-content-center align-items-center "
            style={{ position: "relative", zIndex: "999999", height: "131px" }}
          >
            <Link to="/">
              {" "}
              <img
                className="heade_logo LOGO-SIZE"
                src={require("../images/natura/black.png")}
                // src={bg}
             
                alt=""
              />
            </Link>
          </div>

          <div className="nav_icon container-fluid align-items-center">
            <Link to="/" className="" onClick={btnn}>
              <li className="AiTwotoneHomeiCON">
              <AiTwotoneHome />
              </li>
            </Link>

            <Link to="/our-events" className="" onClick={btnn}>
              <li className="">
                <b>Our Events</b>
              </li>
            </Link>

            <Link to="/safety" className="" onClick={btnn}>
              <li>
                <b>Safety </b>
              </li>
            </Link>
            <li class="mydropdown d-none d-lg-block ">
              <a
                class="service1 p-2"
                id="about_open"
                onClick={() => {
                  return false;
                }}
              >
                Services
              </a>
              {headerclose && (
                <div class="service-menu" id="service-menu">
                  <div className="container-fluid">
                    <div className="text-center text-white py-5 mt-3">
                      <h2>
                        <b className="">Services</b>
                      </h2>
                    </div>
                    <div className="row justify-content-around">
                      <div className="col-lg-2 text-center">
                        {category && (
                          <Link
                            to={`/service/${category[0]?.categoryName}`}
                            className="text-center mybtns"
                            onClick={(e) => {
                              closemenu(e);
                            }}
                            id="submenu"
                            style={{ cursor: "pointer" }}
                            state={{
                              image: "flyingfx.jpg",
                              content:
                                "Recognised as India's first and only professional rigging company, we are the go-to for rigging people and props for live events; we're also the first name in India when it comes to providing safety support. With our rigging services, Natura provides unique solutions that allow event managers and choreographers to think outside the box and visualize the unusual! Whether it's for a product or a building launch, a grand unveiling, an aerial element in a performance, banner and prop rigging, or anything else, you can make your event so much more exciting.\nRigging 101:Depending on what your concept is, there are quite a few rigging ideas that you can execute.",
                            }}
                          >
                            <div className="text-center d-flex justify-content-center flex-column align-items-center">
                              <div
                                class="service-submenu border d-flex justify-content-center align-items-center rounded "
                                style={{
                                  width: "290px",
                                  height: "60vh",
                                  backgroundSize: "cover",
                                  backgroundImage: `linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, 0.4)), url(${
                                    process.env.REACT_APP_IMGURL
                                  }/images/product/${
                                    category[0]?.CategoryHeaderImage
                                      ? category[0]?.CategoryHeaderImage
                                      : category[0]?.CategoryImage
                                  })`,
                                }}
                              >
                                <a style={{ cursor: "pointer" }}>
                                  <b>{category[0]?.categoryName}</b>
                                </a>
                                <br />
                              </div>
                            </div>
                          </Link>
                        )}
                      </div>
                      <div className="col-lg-2 text-center">
                        {category && (
                          <Link
                            className="text-center mybtns"
                            onClick={closemenu}
                            style={{ cursor: "pointer" }}
                            to={`/service/${category[1]?.categoryName}`}
                            state={{
                              category: "Cotton Fabrics",
                              image: "aerialenteries.jpg",
                              content:
                                "Whether you’re looking for a great entertainment act or trying to figure out a spectacular entry, sometimes aerial is the only way to go…why walk or dance on the ground when you can fly?\nCollaborating with some of the top choreographers and performers in the world, Natura has developed a number of unique aerial acts in-house that can be effortlessly integrated into any live event or show, with minimal tweaking to suit whatever theme you have in mind! All you need to do is choose your act and we’ll take care of the rest – rigging, artists, choreography, makeup, soundtrack, and everything in between. On the other hand, perhaps a grand aerial entry is what your event needs! For your bridal couple, a varmala act, or your celebrity entry on stage, nothing catches the eye like an aerial entry. Even for your corporate events…nothing livens up a conference like a CEO or a MD flying in!",
                            }}
                          >
                            <div className="text-center d-flex justify-content-center flex-column align-items-center">
                              <div
                                class="service-submenu border d-flex justify-content-center align-items-center rounded "
                                style={{
                                  width: "290px",
                                  height: "70vh",
                                  backgroundSize: "cover",

                                  backgroundImage: `linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, 0.4)), url(${
                                    process.env.REACT_APP_IMGURL
                                  }/images/product/${
                                    category[1]?.CategoryHeaderImage
                                      ? category[1]?.CategoryHeaderImage
                                      : category[1]?.CategoryImage
                                  })`,
                                }}
                              >
                                <a style={{ cursor: "pointer" }}>
                                  <b>{category[1]?.categoryName}</b>
                                </a>
                                <br />
                              </div>
                            </div>
                          </Link>
                        )}
                      </div>
                      <div className="col-lg-2 text-center">
                        {category && (
                          <Link
                            onClick={closemenu}
                            className="mybtns"
                            style={{ cursor: "pointer" }}
                            // to="/service/Aerial Acts"
                            to={`/service/rig_tech`}
                          >
                            <div className="text-center d-flex justify-content-center flex-column align-items-center">
                              <div
                                class="service-submenu border d-flex justify-content-center align-items-center rounded "
                                style={{
                                  width: "290px",
                                  height: "70vh",
                                  backgroundSize: "cover",
                                  backgroundImage: `linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, 0.4)), url(${
                                    process.env.REACT_APP_IMGURL
                                  }/images/product/${
                                    category[2]?.CategoryHeaderImage
                                      ? category[2]?.CategoryHeaderImage
                                      : category[2]?.CategoryImage
                                  })`,
                                  // backgroundImage: `url(${process.env.REACT_APP_IMGURL}/images/product/${category[2]?.CategoryImage})`,
                                }}
                              >
                                <a style={{ cursor: "pointer" }}>
                                  <b>{category[2]?.categoryName}</b>
                                </a>
                                <br />
                              </div>
                            </div>
                          </Link>
                        )}
                      </div>

                      <div className="col-lg-2 text-center">
                        {category && (
                          <Link
                            onClick={closemenu}
                            className="mybtns"
                            style={{ cursor: "pointer" }}
                            // to="/service/Adventure Service"
                            to={`/service/${category[3]?.categoryName}`}
                            state={{
                              category: "Poplin",
                              image: "adventure.jpg",
                              content:
                                "Adventure, be it sports or experiences, is a field that is becoming increasingly more popular everyday. Whatever the age, whatever the dream, everyone wants a thrill of 'adventure' at least once in their lives and when you want the best that adventure has to offer, you go to the experts. From its inception, Natura has quickly developed and maintained its well-earned reputation as an adventure expert. This reputation begins with the expertise possessed by our core team of adventure professionals, who each have at minimum over a decade of rich and varied experience.",
                            }}
                          >
                            <div className="text-center d-flex justify-content-center flex-column align-items-center">
                              <div
                                class="service-submenu border d-flex justify-content-center align-items-center rounded "
                                style={{
                                  width: "290px",
                                  height: "70vh",
                                  backgroundSize: "cover",
                                  backgroundImage: `linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, 0.4)), url(${
                                    process.env.REACT_APP_IMGURL
                                  }/images/product/${
                                    category[3]?.CategoryHeaderImage
                                      ? category[3]?.CategoryHeaderImage
                                      : category[3]?.CategoryImage
                                  })`,
                                  // backgroundImage: `url(${process.env.REACT_APP_IMGURL}/images/product/${category[3]?.CategoryImage})`,
                                }}
                              >
                                <a style={{ cursor: "pointer" }}>
                                  {category[3]?.categoryName}
                                </a>
                                <br />
                              </div>
                            </div>
                          </Link>
                        )}
                      </div>
                      <div className="col-lg-2 text-center">
                        {category && (
                          <Link
                            onClick={closemenu}
                            className="mybtns"
                            style={{ cursor: "pointer" }}
                            // to="/service/Rig Tech"
                            to={`/service/${category[4]?.categoryName}`}
                            state={{
                              category: "Polyester",
                              image: "tech.jpg",
                              content:
                                "Natura Rigtech is the exclusive provider for some of the world’s most advanced stage rigging and safety equipment in india.\nNatura started as India’s first and only professional aerial rigging company in India. Natura’s focus on safety and adherence to global rigging standards has made Natura the perfect choice as Exclusive Distributors for Eventions Products and Protos Rigging. Natura is also the Exclusive Service Partner for Pro Lyft in India",
                            }}
                          >
                            <div className="text-center d-flex justify-content-center flex-column align-items-center">
                              <div
                                class="service-submenu border d-flex justify-content-center align-items-center rounded "
                                style={{
                                  width: "290px",
                                  height: "60vh",
                                  backgroundSize: "cover",
                                  backgroundImage: `url(${process.env.REACT_APP_IMGURL}/images/product/${category[4]?.CategoryImage})`,
                                }}
                              >
                                <a style={{ cursor: "pointer", color: "#000" }}>
                                  {category[4]?.categoryName}
                                </a>
                                <br />
                              </div>
                            </div>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </li>

            {/* ====================== Mobile Drop */}
            <li class="d-md-none d-block ">
              <a
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Services »
              </a>
              {headerclose && category && category.length !== 0 && (
                <div
                  class="collapse text-start"
                  id="collapseExample"
                  style={{ paddingLeft: "100px" }}
                >
                  <Link
                    class="dropdown-item"
                    to={`/service/${category[0]?.categoryName}`}
                    onClick={closemenu}
                    state={{ category: "Linen fabrics", image: "flyingfx.jpg" }}
                    style={{ color: "#37423b", fontSize: "15px" }}
                  >
                    {category[0]?.categoryName}
                  </Link>
                  <Link
                    class="dropdown-item"
                    to={`/service/${category[1]?.categoryName}`}
                    onClick={closemenu}
                    state={{
                      category: "Cotton Fabrics",
                      image: "aerialenteries.jpg",
                    }}
                    style={{ color: "#37423b", fontSize: "15px" }}
                  >
                    {category[1]?.categoryName}
                  </Link>
                  <Link
                    class="dropdown-item"
                    to={`/service/rig_tech`}
                    onClick={closemenu}
                    state={{
                      category: "Khadi Fabrics",
                      image: "aerialacts.jpg",
                    }}
                    style={{ color: "#37423b", fontSize: "15px" }}
                  >
                    {category[2]?.categoryName}
                  </Link>
                  <Link
                    class="dropdown-item"
                    to={`/service/${category[3]?.categoryName}`}
                    onClick={closemenu}
                    state={{ category: "Poplin", image: "adventure.jpg" }}
                    style={{ color: "#37423b", fontSize: "15px" }}
                  >
                    {category[3]?.categoryName}
                  </Link>
                  <Link
                    class="dropdown-item"
                    to={`/service/${category[4]?.categoryName}`}
                    onClick={closemenu}
                    state={{ category: "Polyester", image: "tech.jpg" }}
                    style={{ color: "#37423b", fontSize: "15px" }}
                  >
                    {category[4]?.categoryName}
                  </Link>
                </div>
              )}
            </li>
            {/* ====================== Mobile Drop */}

            <li className="d-lg-block d-md-block d-none">
              <div class="dropdown">
                <Link
                  class="dropdown-toggle py-2 d-flex justify-content-center align-items-center bg-dark"
                  style={{
                    position: "relative",
                    zIndex: "2",
                    height: "130px",
                    clipPath:
                      " polygon(0 0, 100% 0, 100% 75%, 50% 99%, 0 75%, 0% 50%)",
                  }}
                  to="/allevent/Films"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* headericon-height in class */}
                  <img
                    className="heade_logo px-5"
                    src={bg}
                    width="100%"
                    height="50px"
                    alt=""
                    style={{ marginTop: "0.4rem" }}
                  />
                </Link>

                <ul
                  class="dropdown-menu py-3 bg-dark"
                  aria-labelledby="dropdownMenuLink"
                  style={{
                    clipPath:
                      "polygon(0px 0px, 100% 0px, 100% 90%, 50% 99%, 0px 90%, 0% 50%)",
                  }}
                >
                  <li>
                    <Link
                      class="dropdown-item"
                      to={`/allevent/Launch,Unveiling Events`}
                    >
                      <div className="d-flex align-items-center">
                        <span className="headerdropdowntext">
                          Launch/ Unveiling
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      class="dropdown-item"
                      to="/allevent/Opening Ceremonies"
                    >
                      <div className="d-flex align-items-center">
                        <span className="headerdropdowntext">
                          Opening Ceremonies
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    {/* name: ["Weddings", "Celebrations"], */}
                    <Link
                      class="dropdown-item"
                      to="/allevent/Weddings,Celebrations"
                    >
                      <div className="d-flex align-items-center">
                        <span className="headerdropdowntext">
                          Weddings/ Celebrations
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/allevent/Adventure">
                      <div className="d-flex align-items-center">
                        <span className="headerdropdowntext">Adventure</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/allevent/Art,Theatre">
                      <div className="d-flex align-items-center">
                        <span className="headerdropdowntext">Art/Theatre</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      class="dropdown-item"
                      to="/allevent/Corporate Brand Events"
                    >
                      <div className="d-flex align-items-center">
                        <span className="headerdropdowntext">
                          {/* Corporate/<br/>Brand Events */}
                          Corporates and Brands
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/allevent/Films,TV,Ads">
                      <div className="d-flex align-items-center">
                        <span className="headerdropdowntext">Films/TV/Ads</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/allevent/Award shows">
                      <div className="d-flex align-items-center">
                        <span className="headerdropdowntext">Award shows</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      class="dropdown-item"
                      to="/allevent/Natura Promotional events"
                    >
                      <div className="d-flex align-items-center">
                        <span className="headerdropdowntext">
                          Promotional Events
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/allevent/Rigging Workshop">
                      <div className="d-flex align-items-center">
                        <span className="headerdropdowntext">
                          Rigging Workshop
                        </span>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <a>
                      <span>
                        <i>&nbsp;&nbsp;</i>
                      </span>{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>
                        <i>&nbsp;&nbsp;</i>
                      </span>{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <Link to="/Blog" className="p-2" onClick={btnn}>
              <li className="">
                <b>Blog</b>
              </li>
            </Link>
            <Link to="" className=" d-none dropdown d-lg-block">
              <div
                class=" dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Link to="/about-natura">
                  <li>
                    <b>About Us</b>
                  </li>
                </Link>
              </div>
              <div
                class="dropdown-menu mt-2"
                aria-labelledby="dropdownMenuButton"
              >
                <div class="dropdown">
                  <Link class="dropdown-item" to="/about-natura">
                    {" "}
                    <AiOutlineTeam size={20} style={{ marginRight: "5px" }} />
                    About Natura
                  </Link>
                  <Link class="dropdown-item" to="/Featured/event">
                    <AiOutlineSafety size={20} style={{ marginRight: "5px" }} />
                    Featured Events
                  </Link>
                  <Link class="dropdown-item" to="/faq">
                    <FaQrcode size={20} style={{ marginRight: "5px" }} />
                    FAQ
                  </Link>
                  <Link class="dropdown-item" to="/testimonial">
                    <BsFillChatRightQuoteFill
                      size={20}
                      style={{ marginRight: "5px" }}
                    />
                    Testimonials
                  </Link>
                </div>
              </div>
            </Link>

            <li class="d-md-none d-block">
              <a
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                About »
              </a>
              <div
                class="collapse text-start"
                id="collapseOne"
                style={{ paddingLeft: "100px" }}
              >
                <div>
                  <Link class="dropdown-item" to="/about-natura" onClick={btnn}>
                    About Natura
                  </Link>
                </div>

                <Link
                  class="dropdown-item"
                  to="/Featured/event"
                  style={{ color: "#37423b", fontSize: "15px" }}
                  onClick={btnn}
                >
                  Featured Events
                </Link>
                <Link class="dropdown-item" to="/faq" onClick={btnn}>
                  FAQ
                </Link>
                <Link class="dropdown-item" to="/testimonial" onClick={btnn}>
                  Testimonials
                </Link>
              </div>
            </li>

            <Link to="/contact-us" className="p-2" onClick={btnn}>
              <li>
                <b>Contact Us</b>
              </li>
            </Link>

            <li>
              <Link className="p-0" to="/search" onClick={btnn}>
                <ImSearch />
              </Link>
            </li>
          </div>

          <i className="menu_btn fas fa-bars text-dark" onClick={btnn}></i>
        </nav>
      </header>
      {/* <!-- <Header End============= --> */}
    </div>
  );
};
