import "../../styles/breadcrumb.scss";
import { Link } from "react-router-dom";
const Breadcrumb = ({name}) => {

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li class="breadcrumb-item">
            <Link to="/service/rig_tech">Service</Link>
          </li>
          <li class="breadcrumb-item">
            <Link to="/service/rig_tech">Rig Tech</Link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {name}
          </li>
        </ol>
      </nav>
    </>
  );
};

export default Breadcrumb;
