import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../config";
import Sidebar from "../common/sidebar/Sidebar";
import { Link } from "react-router-dom";
import AdminLoader from "../components/AdminLoader";
import { AiFillDelete, AiFillEdit, AiTwotoneDelete } from "react-icons/ai";

const Testimonial = () => {
  const [testimonial, settestimonial] = useState();
  const [del, setdel] = useState(false);

  useEffect(() => {
    gettestimonial();
  }, [!del]);

  const gettestimonial = async () => {
    try {
      const { data } = await axiosInstance.get("/api/testimonial");
      settestimonial(data);
      
    } catch (error) {
      
    }
  };

  const togglehandle = (e) => {
    e.persist();

    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };

  const deletetestimonial = async (id) => {
    var answer = window.confirm("Are you sure To delete?");
    if (answer) {
      await axiosInstance.delete(`/api/testimonial/${id}`, {
        headers: {
          token: localStorage.getItem("token"),
        },
      });

      alert("deleted");

      setTimeout(() => {
        setdel(!del);
      }, 100);
    }
  };
  const deleteImage = async (id) => {
    var answer = window.confirm("Are you sure To delete?");
    if (answer) {
      const { data } = await axiosInstance.put(`/api/testimonial/${id}`, {
        AuthorImg: "",
      });
      alert(data.message);
      gettestimonial()
    }
  };

  return (
    <>
      <Sidebar />
      <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
        <nav className="navbar top-navbar navbar-light bg-light px-5">
          <button className="btn border-0" id="menu-btn" onClick={togglehandle}>
            <i className="bx bx-menu"></i>
          </button>
        </nav>
        {/* <!--End Top Nav --> */}
        <h3 className="text-dark p-3">ADMIN TESTIMONIAL</h3>

        <div
          className="container p-3"
          style={{ boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
        >
          <div className="row text-start">
            <div className="">
              <Link
                to="/admin/addtestimonial"
                className="text-white btn btn-success rounded px-5"
              >
                Add New testimonial{" "}
              </Link>
            </div>
          </div>
          <div className="row mb-4 border-bottom text-start">
            <div className="col-md-2 ">
              <b> Author Name</b>
            </div>
            <div className="col-md-2 ">
              <b> Author Image</b>
            </div>
            <div className="col-md-2 ">
              <b> Designation</b>
            </div>
            <div className="col-md-2 ">
              <b>companyName</b>
            </div>
            <div className="col-md-2 ">
              <b>Testimonial Type</b>
            </div>

            <div className="col-md-2 ">
              <b>EXTRA OPTIONS</b>
            </div>
          </div>

          {!testimonial ? (
            <AdminLoader />
          ) : testimonial.length === 0 ? (
            <>
              <div className="row d-flex justify-content-center mt-5">
                <div className="col-md-8 text-center">
                  <h4>No testimonial Available</h4>
                </div>
              </div>
            </>
          ) : (
            testimonial &&
            testimonial.map((testimonial) => (
              <>
                <div className="row text-start pb-2">
                  <div className="col-md-2 ">{testimonial.AuthorName}</div>
                  <div className="col-md-2 ">
                    <img
                      src={testimonial.AuthorImg}
                      alt="AuthorImg"
                      height="80px"
                      width="80px"
                    />
                    {testimonial?.AuthorImg && (
                      <AiTwotoneDelete
                        size={30}
                        onClick={() => deleteImage(testimonial._id)}
                      />
                    )}
                  </div>
                  <div className="col-md-2 ">{testimonial.Designation}</div>
                  <div className="col-md-2 ">{testimonial?.companyName}</div>
                  <div className="col-md-2 ">
                    {testimonial?.testimonialType}
                  </div>

                  <div className="col-md-2 p">
                    <div className="row">
                      {/* <div className="col-md-4">
                                                    <AiFillEye color='red' size={25} onClick={() => showdetails(category)} />
                                                </div> */}
                      <div className="col-md-4" style={{ cursor: "pointer" }}>
                        <AiFillDelete
                          color="black"
                          size={30}
                          onClick={() => deletetestimonial(testimonial._id)}
                        />
                      </div>
                      <div className="col-md-4">
                        <Link
                          className="col-md-1"
                          to={`/admin/edittestimonial/${testimonial._id}`}
                        >
                          <AiFillEdit size={25} color="blue" />{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Testimonial;
