import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from '../config'
import { Footer } from './Footer'
import { Header } from './Header'

export const Login = () => {

     // login state
     const [username, setusername] = useState();
     const [password, setpassword] = useState();
    const [user, setUser] = useState();
    const navigate = useNavigate();
    


    useEffect(() => {
        getdata();
    },[])

    
    const getdata = async () => {
        const { data } = await axiosInstance.get("/api/user")
        
        setUser(data)

    }

    const handlelogin = async (e) => {
        e.preventDefault();
         const cond = !username == "" && !password == "";
        try{
            if(cond){
            const { data } = await axiosInstance.post("/api/user/login",{
                "UserName":username,
                "Password":password
            })
            localStorage.setItem("token",data.token );
            alert("Login Succesfully")

            window.location.href="/admin/event"


            // navigate('/admin/service')
            setUser(data)
        }else{
            alert("Please Fill All the filled")
        }
        }catch{
           alert("Wrong Credentials")
        }
        // const cond = !username == "" && !password == "";
        // if(cond){
        //  e.preventDefault();
        
        //  navigate('/admin/product')
        // }else{
        //  e.preventDefault();
        //     alert("Please Fill All the filled")
        // }
         
        
     }

    return (
        <div>

            <Header />


            <main class="form-signin d-flex justify-content-center align-items-center bg-light" style={{height:'90vh',marginTop:'60px'}}>
                <form className="col-lg-4 bg-white p-5" style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
                    {/* <img class="mb-4" src="/docs/5.0/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57"/> */}
                        <div className="text-center">
                                <h1 class="h3 mb-3 fw-normal">Please sign in</h1>
                        </div>

                        <div class="form-floating py-2 ">
                            <input class="form-control form-control-sm" type="text" value={username} onChange={e => setusername(e.target.value)} placeholder=".form-control-sm" aria-label=".form-control-sm example"/>
                            <label for="floatingInput">UserName</label>
                        </div>
                        <div class="form-floating py-3">
                            <input class="form-control form-control-sm" type="text" value={password} onChange={e => setpassword(e.target.value)} placeholder=".form-control-sm" aria-label=".form-control-sm example"/>
                            <label for="floatingPassword">Password</label>
                        </div>

                       
                        <button class="w-100 btn btn-lg  bg-dark text-white" type="submit" onClick={handlelogin}>Sign in</button>
                        
                </form>
            </main>


{/* 
            <Footer /> */}

        </div>
    )
}
