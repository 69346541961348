import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../config';
import Sidebar from '../common/sidebar/Sidebar';


const EditFaqCategory = () => {
    const { id } = useParams();
    const navigate = useNavigate()

    const [questiontype, setquestiontype] = useState()
    const [loader, setloader] = useState(false)


    useEffect(() => {
        getfaqcategory()
    }, [])


    const getfaqcategory = async () => {
        try {
            const { data } = await axiosInstance.get(`/api/faqcategory/${id}`);
            setquestiontype(data.faqQuestionType)
            
        } catch (error) {
            
        }
    }



    const togglehandle = (e) => {
        e.persist();
        var menu_btn = document.querySelector("#menu-btn");
        var sidebar = document.querySelector("#sidebar");
        var container = document.querySelector(".my-container");
        sidebar.classList.toggle("active-nav")
        container.classList.toggle("active-cont");
    }



    const handlefaqcategorySubmit = async (e) => {
        e.preventDefault();

        if (questiontype == undefined) {
            alert("Fill All the input field")
            return
          }
          setloader(true)


        try {
            const { data } = await axiosInstance.put(`/api/faqcategory/${id}`, {
                "faqQuestionType": questiontype,
              })
              
              setloader(false)
            alert(data.message)
            navigate("/admin/faqcategory")
        } catch (error) {
            setloader(false)
            alert(error.response.data)
        }



        // navigate("/admin/user");
    }






    return (
        <>
            <Sidebar />
            <div className="p-1 my-container active-cont">
                <nav className="navbar top-navbar navbar-light bg-light px-5">
                    <a className="btn border-0" id="menu-btn" onClick={togglehandle}><i className="bx bx-menu"></i></a>
                </nav>

                <h3 className="text-dark p-3"> ADMIN EDIT FAQ
                </h3>
                <div className="row mx-2 " style={{ backgroundColor: "#FDF8DB" }}>

                    <form onSubmit={handlefaqcategorySubmit}>
                        <div className="col-md-6 align-items-center">
                            <label> Question Type Name</label>
                            <input className="form-control " type="text" value={questiontype} onChange={e => setquestiontype(e.target.value)} placeholder=" Enter Question Type"  />
                        </div>
                        <div className="col-md-6" style={{ padding: "5px" }}>
                            <div className="d-flex flex-row">
                                {
                                    loader ? <>
                                    <button className="btn btn-success" disabled>Updating Please Wait...</button>
                                    </>:<>
                                    <button className="btn btn-success" >Submit</button>
                                    </>
                                }
                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default EditFaqCategory