import React, { useState, useEffect } from "react";
// import Sidebar from '../../components/sidebar/Sidebar';
import { Link } from "react-router-dom";
import {
  AiFillDelete,
  AiOutlineFileAdd,
  AiTwotoneDelete,
} from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
// import { axiosInstance } from '../../../config';
import parse from "html-react-parser";
import { axiosInstance } from "../../config";
import Sidebar from "../common/sidebar/Sidebar";

const AdminContactUs = () => {
  const [Query, setQuery] = useState([]);

  useEffect(() => {
    getQuery();
  }, []);

  const getQuery = async () => {
    try {
      const { data } = await axiosInstance.get("/api/ContactUs");
      if (data.success) {
        setQuery(data.data);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const togglehandle = (e) => {
    e.persist();
    var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };

  return (
    <>
      <Sidebar />

      <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
        <nav className="navbar top-navbar navbar-light bg-light px-5">
          <a className="btn border-0" id="menu-btn" onClick={togglehandle}>
            <i className="bx bx-menu"></i>
          </a>
        </nav>
        {/* <!--End Top Nav --> */}
        <h3 className="text-dark p-3">Form Query</h3>

        <div
          className="p-3"
          style={{ boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
        >
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Sr. No.</th>
                <th scope="col">Name</th>
                <th scope="col">Number</th>
                <th scope="col">Email</th>
                <th scope="col">Message</th>
              </tr>
            </thead>
            <tbody>
              {Query &&
                Query.map((u, no) => (
                  <>
                    <tr>
                      <th scope="row">{no + 1}</th>
                      <td>{`${u?.Firstname} ${u.Lastname}`}</td>
                      <td>{u.Number}</td>
                      <td>{u.Usermial}</td>
                      <td>{u.UserMessage}</td>
                    </tr>
                  </>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AdminContactUs;
