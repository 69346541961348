import React, { useEffect, useState,useRef } from 'react';
import Blogcard from './Blogcard';
// import Header from '../../Header/Header';

import { axiosInstance } from '../../config';
// import img from "../../../assests/img/blogbanner1.jpg"
// import mobimg from "../../../assests/img/RSFMOBILEVIEW.jpg";
import Helmet from 'react-helmet'
import { Header } from '../../Home/Header';
import Topbanner from '../topbanner/Topbanner';
import { Footer } from '../../Home/Footer';




const Blog = () => {
  const [blog, setblog] = useState([])
  const [page, setpages] = useState(1);
  const [loading, setloading] = useState(false);
  const scorllToRef = useRef();

  const getblog = async () => {
    try {
      setloading(true)
      const { data } = await axiosInstance.get("/api/blog");
      setblog(data.blog)
      setloading(false)
      console.log("f", data)
    } catch (error) {
      setloading(false)
      console.log("error", error)
    }
  }

  useEffect(() => {
    getblog();
  }, [])

  const setselecedpage = (page) => {

    if (page < 1 || page > Math.ceil(blog.length / 10 )) {
      return
    }
    setpages(page)
    scorllToRef.current.scrollIntoView()
  }


  return (
    <>
      <Helmet>
        <title>Natura | Blog</title>
        <meta name="keywords" content="gym ,equipments,franchise" />
        <meta name="description" content="Royal Sports N Fitness" />
      </Helmet>
      <Header />
      <Topbanner heading="Blog" height="45vh" />
   
   
      <div className="container " style={{ marginTop: "60px" }} ref={scorllToRef}>
        <div className="row">

          {
           loading ? <div className='row d-flex justify-content-center position-relative top-50 mt-5 ' style={{ height: "100vh" }}>
              <div className="col-md-8 text center position-absolute top-40 start-50">
                <div class="spinner-border text-dark " role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div> : blog.length === 0 ? <>
              <div className="row d-flex justify-content-center mt-5">
                <div className="col-md-8 text-center">
                  <h4>No Blog Available</h4>
                </div>
              </div>
            </> :
              blog && blog.slice(page * 10 - 10, page * 10).map((b) => (
                <>
               
                
                  <Blogcard blog={b} />
                
                  
                </>
              ))

          }


          {

            blog.length > 0 &&
            <div className="row justify-content-center ">
              <div className="col-md-12 text-center">
                <nav aria-label="Page navigation example" style={{display:"flex",justifyContent:'center'}}>
                  <ul class="pagination">
                    <li class="page-item">
                      <button class="page-link" aria-label="Previous" onClick={() => setselecedpage(page - 1)}>
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only" >Previous</span>
                      </button>
                    </li>
                    {[...Array(Math.ceil(blog.length / 10))].map((_, i) => (
                      <>
                        <li key={i} className={page === i+1 ? "page-item active" : "page-item "}><button class="page-link" onClick={() => setselecedpage(i + 1)}>{i + 1}</button></li>
                      </>
                    ))}

                    <li class="page-item">
                      <button class="page-link"  aria-label="Next" onClick={() => setselecedpage(page + 1)}>
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only" >Next</span>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

          }


         
        </div>
      </div>
      <Footer />

    </>
  )
}

export default Blog