import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
import Sidebar from "../common/sidebar/Sidebar";

const Editcategory = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [categoryname, setcategoryname] = useState();
    const [categorydescription, setcategorydescription] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [YoutubeUrl, setYoutubeUrl] = useState();
    const [shortDescription, setShortDescription] = useState();
    const [IsYoutubeVideo, setIsYoutubeVideo] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getcategory();
    }, []);

    const getcategory = async () => {
        try {
            const { data } = await axiosInstance.get(`/api/category/${id}`);
            setcategoryname(data.categoryName);
            setShortDescription(data?.shortDistractions);

            setYoutubeUrl(data?.YoutubeUrl ?? "");
            setcategorydescription(data.CategoryDescription);
        } catch (error) { }
    };

    const togglehandle = (e) => {
        e.persist();
        var menu_btn = document.querySelector("#menu-btn");
        var sidebar = document.querySelector("#sidebar");
        var container = document.querySelector(".my-container");
        sidebar.classList.toggle("active-nav");
        container.classList.toggle("active-cont");
    };

    const handledataSubmit = async (e) => {
        e.preventDefault();
        if (categoryname == "" || categoryname == undefined) {
            alert("Category Name Can't be empty");
            return;
        }
        if (!categorydescription) {
            alert("Long Description Can't be empty");
            return;
        }
        if (!shortDescription) {
            alert("short Description Can't be empty");
            return;
        }

        // if (selectedFile && selectedFile.size >= 5000000) {
        //     alert("File Size Should Less Than 5 Mb");
        //     return;
        // }


        setLoading(true);
        const formdata = new FormData();
        formdata.append("categoryName", categoryname);
        formdata.append("CategoryDescription", categorydescription);
        formdata.append("shortDistractions", shortDescription);
        if (IsYoutubeVideo) {
            formdata.append("file", selectedFile);
            // formdata.append("YoutubeUrl", "");
        } else {
            formdata.append("YoutubeUrl", YoutubeUrl);
            // formdata.append("file", "");
        }


        try {
            const { data } = await axiosInstance.put(
                `/api/category/${id}`,
                formdata,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            alert(data.message);
            setLoading(false);
            navigate("/admin/service");
        } catch (error) {
            setLoading(false);
            alert(error.response.data);
        }
    };

    return (
        <>
            <Sidebar />
            <div className="p-1 my-container active-cont">
                <nav className="navbar top-navbar navbar-light bg-light px-5">
                    <a className="btn border-0" id="menu-btn" onClick={togglehandle}>
                        <i className="bx bx-menu"></i>
                    </a>
                </nav>

                <h3 className="text-dark p-3"> ADMIN EDIT SERVICE</h3>
                <div style={{ backgroundColor: "#FDF8DB" }}>
                    {/* <div className="toogler"><h3>Edit </h3></div> */}

                    <form onSubmit={handledataSubmit} className="row mx-2 gap-3">
                        <div className="col-md-6 align-items-start">
                            <label>Service Name</label>
                            <input
                                className="form-control "
                                type="text"
                                value={categoryname}
                                onChange={(e) => setcategoryname(e.target.value)}
                                placeholder="Service Name"
                            />
                        </div>
                        <div className="col-md-6 align-items-start">
                            <label>Service short Description</label>
                            <textarea
                                className="form-control "
                                type="text"
                                value={shortDescription}
                                onChange={(e) => setShortDescription(e.target.value)}
                                placeholder="Service short Description    "
                            />
                        </div>
                        <div className="col-md-6 align-items-start">
                            <label>Service Long Description</label>
                            <textarea
                                className="form-control "
                                type="text"
                                value={categorydescription}
                                onChange={(e) => setcategorydescription(e.target.value)}
                                placeholder="Service long Description    "
                            />
                        </div>
                        <div class="form-switch col-md-6 align-items-start mx-3 ">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckChecked"
                                checked={IsYoutubeVideo}
                                onClick={(e) => setIsYoutubeVideo(!IsYoutubeVideo)}
                            />{" "}
                            &nbsp;
                            <label class="form-check-label" for="flexSwitchCheckDefault">
                                Select video type
                            </label>
                        </div>
                        {IsYoutubeVideo ? (
                            <div className="col-md-6 align-items-start">
                                <input
                                    type="file"
                                    class="form-control"
                                    aria-describedby="emailHelp"
                                    // placeholder="Enter Youtube Link Url"
                                    onChange={(e) => setSelectedFile(e.target.files[0])}
                                />
                            </div>
                        ) : (
                            <div className="col-md-6 align-items-start">

                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter Youtube Link "
                                    value={YoutubeUrl}
                                    onChange={(e) => setYoutubeUrl(e.target.value)}
                                />
                            </div>
                        )}

                        <div className="col-md-6" style={{ padding: "5px" }}>
                            <div className="d-flex flex-row">
                                {loading ? (
                                    <>
                                        <button className="btn btn-success disabked" disabled>
                                            Loading
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button className="btn btn-success">Submit</button>
                                    </>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Editcategory;
