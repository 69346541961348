import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Topbanner from "../../components/topbanner/Topbanner";
import { Footer } from "../../Home/Footer";
import { Header } from "../../Home/Header";
import abhinav from "../../images/team/Abhinav3.jpg";
import aviskar from "../../images/team/aviskar.jpg";
import maria from "../../images/team/maria.jpg";
import rohit from "../../images/team/rohit2.jpg";
import shyam from "../../images/team/shyam-1.jpg";
import snehal from "../../images/team/snehal2.png";
import wasim from "../../images/team/wasim3.png";
import nemichand from "../../images/team/nemichand.jfif";
import jayesh from "../../images/team/jayesh.png";
import sujit from "../../images/team/sujit3.png";
import dhanuja from "../../images/team/dhanuja.png";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import HeaderNew from "../../Home/HeaderNew";
import { axiosInstance } from "../../config";

const AboutNatura = () => {
  const [Team, setTeam] = useState([]);
  const getTeam = async () => {
    try {
      const { data } = await axiosInstance.get("/api/Team");
      if (data.success) {
        console.log(data.Team, "data.Teamdata.Team");
        setTeam(data.Team);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    getTeam();
  }, []);
  return (
    <>
      <Header />
      {/* <HeaderNew/> */}

      <Topbanner heading="About Natura" height="45vh" />
      <section
        className="team  py-5"
        style={{ backgroundColor: "rgb(204, 204, 204)" }}
      >
        <div className="container">
          <div
            className="row justify-content-between align-items-center "
            style={{ overflow: "hidden" }}
          >
            {/* <Breadcrumbs/> */}
            <div className="col-lg-6 col-md-6 col-6">
              <div className="text-start">
                {/* <h2 style={{ color: '#FEBD15' }}><b>ABOUT US</b></h2> */}
                <h2 style={{ color: "#000" }}>
                  <b>ABOUT US</b>
                </h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <div className="text-end">
                <Link to="/">
                  {" "}
                  <img
                    className="heade_logo px-5"
                    height="60px"
                    src={require("../../images/client/logohori.png")}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
          {/* <hr style={{ backgroundColor: '#e6e6dc', height: '10px' }} /> */}
          {/* <hr style={{ backgroundColor: "#000", height: "10px" }} /> */}
          <p className="text-start ">
            <br />
            Natura is India's first and only Professional Aerial Rigging
            Company, rigging people and props to fly in front of live audiences.
            Natura collaborates with choreographers and event planners to
            create, design, develop, and execute aerial performances, entries,
            and unveilings, among other things, to add excitement to any stage
            performance, brand launch, or celebration. <br />
            <br />
            Natura was established as a Pvt. Ltd. in 2008. Natura began as a
            sole proprietorship, then as an Outdoor Educational trust, with the
            sole purpose of changing how safety and risk are perceived in the
            industry in which we worked. <br />
            <br />
            Our big break into aerial rigging came in 2006 when we designed and
            built India's first flying system on stage at Nokia New Year's Eve
            party.
            <br />
            <br />
            We ended up rigging many similar programmes in the run-up to the
            first edition of Dance India Dance in 2009, where we collaborated
            closely with Terence Lewis on his various aerial segments - what
            started as an experiment in 2006 turned out to be our most
            profitable division, both financially and artistically.
            <br />
            Since transitioning from Outdoors to Entertainment, we have
            partnered with majority of the major ticket events in India and
            around the world. Because of our expertise, we had progressed from
            being just Entertainment Riggers to Rigging Safety Consultants by
            2014.
          </p>
        </div>
      </section>

      {/* <section style={{ backgroundColor: "rgb(204, 204, 204)" }}> */}
      <section className="colorSection">
        <div className="container py-5 text-start">
          <div class="d-flex ">
            <div class="section-title text-start col-lg-6 text-muted">
              <h2 className="text-dark">
                Our <span> Team</span>
              </h2>
              {/* <p>
                We have delivered many memorable and stunning events for our
                clients which struck the audience with awe.
              </p> */}
            </div>
          </div>

          <div className="row">
     
            {Team.map((team, i) => (
              <>
                <div className="col-md-4 py-4">
                  <div id="team">
                    <img
                      src={`${process.env.REACT_APP_IMGURL}/images/TeamImage/${team.TeamImage}`}
                      alt={team?.TeamImage}
                      className="img-fluid"
                      style={{
                        width: "100%",
                        // height: "350px",
                        filter: "grayscale(100%)",
                      }}
                    />

                    <h5 className="my-3 text-uppercase">
                      <strong>{team.Name}</strong>
                    </h5>

                    <h6 className=" pb-1  ">{team.Position}</h6>

                    <p>
                      <div
                        className="d-flex align-items-baseline "
                        style={{
                          gap: "10px",
                        }}
                      >
                        {" "}
                        {/* <b>Contact</b> */}
                        {team?.Email && (
                          <a
                            href={`mailto: ${team?.Email}`}
                            style={{
                              color: "black",
                            }}
                          >
                            {" "}
                            <i class="fa-solid fa-envelope"></i>
                          </a>
                        )}
                        {team?.Phone && (
                          <a
                            href={`tel: ${team.Phone}`}
                            style={{
                              color: "black",
                            }}
                          >
                            {" "}
                            <i class="fa-solid fa-phone  "></i>{" "}
                          </a>
                        )}
                        {team?.Linkedin && (
                          <a
                            href={`${team.Linkedin}`}
                            style={{
                              color: "black",
                            }}
                          >
                            {" "}
                            <i class="fa-brands fa-linkedin"></i>
                          </a>
                        )}
                      </div>
                    </p>

                    <p>{team.description}</p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AboutNatura;
