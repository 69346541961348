import React from "react";
import "./maintestimonial.css";
import parse from "html-react-parser";

const TextTestiMonial = ({ p }) => {
  return (
    <>
      <div className="px-4 ">
        <div class="testimonial-box">
          <div class="client-comment text-start">
            {parse(`${p.TestimonialContent}`)}
            <div
              className="row"
              style={{marginTop:"60px"}}
              // style={{
              //   display: "flex",
              //   // alignItems: "center",
              //   gap: "10px",
              // }}
            >
              <div className="col-md-3  col-12 d-flex justify-content-lg-end  justify-content-center ">
                <span
                  style={{
                    width: "85px",
                    height: "85px",
                    objectFit: "cover",
                    borderRadius: "50%",
                    overflow: "hidden",
                    border: "2px solid #febd15",
                  }}
                >
                  <img src={p.AuthorImg} alt="name" className="img-fluid" />
                </span>
              </div>
              <div className="col-md-9 col-12 text-center text-lg-start">
                <h5>
                  <b>{p.AuthorName}</b>
                </h5>
                <h6>
                  <b>{p.Designation}</b>{" "}
                </h6>
                <h6>
                  {" "}
                  <b>{p.companyName}</b>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TextTestiMonial;
