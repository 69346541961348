import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../../config';
import Sidebar from '../common/sidebar/Sidebar';
import { Link } from 'react-router-dom';
import AdminLoader from '../components/AdminLoader';
import {  AiFillDelete, AiFillEdit } from "react-icons/ai";
import parse from "html-react-parser";



const AdminFaq = () => {
    const [faq, setfaq] = useState()
    const [del, setdel] = useState(false)

    useEffect(() => {
        getfaq()
    }, [!del])



    const getfaq = async () => {
        try {
            const { data } = await axiosInstance.get("/api/faq/");
            setfaq(data)
            
        } catch (error) {
            
        }
    }


    const togglehandle = (e) => {
        e.persist();
        var menu_btn = document.querySelector("#menu-btn");
        var sidebar = document.querySelector("#sidebar");
        var container = document.querySelector(".my-container");
        sidebar.classList.toggle("active-nav")
        container.classList.toggle("active-cont");
    }

    const deletefaq = async (id) => {
        var answer = window.confirm("Are you sure To delete?");
        if (answer) {
            await axiosInstance.delete(`/api/faq/${id}`, {
                headers: {
                    'token': localStorage.getItem("token")
                },
            });

            alert("deleted");

            setTimeout(() => {
                setdel(!del);
            }, 100);
        }
    }



  return (
    <>
      <Sidebar />
      <div className="p-1 my-container active-cont overflow-hidden">
                {/* <!-- Top Nav --> */}
                <nav className="navbar top-navbar navbar-light bg-light px-5">
                    <a className="btn border-0" id="menu-btn" onClick={togglehandle}><i className="bx bx-menu"></i></a>
                </nav>
                {/* <!--End Top Nav --> */}
                <h3 className="text-dark p-3">ADMIN FAQ TYPES
                </h3>



                <div className="container p-3" style={{ boxShadow: ' rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                    <div className="row text-start">
                        <div className=""><Link to="/admin/addfaq" className="text-white btn btn-success rounded px-5">Add New FAQ </Link></div>
                    </div>
                    <div className="row mb-4 border-bottom text-start">
                        <div className="col-md-3 "><b> Question Name</b></div>
                        <div className="col-md-3 "><b> Answer</b></div>
                        <div className="col-md-3 "><b> Question Type</b></div>

                        <div className="col-md-3 "><b>EXTRA OPTIONS</b></div>
                    </div>


                    {
                        !faq ? <AdminLoader/>: faq.length === 0 ? <>
                            <div className="row d-flex justify-content-center mt-5">
                                <div className="col-md-8 text-center">
                                    <h4>No faq Available</h4>
                                </div>
                            </div>
                        </> :

                            faq && faq.map((faq) => (
                                <>
                                    <div className="row text-start pb-2">
                                        <div className="col-md-3 ">
                                        <textarea  value={faq.question}></textarea>
                                        </div>
                                        <div className="col-md-3 ">
                                        <textarea value={(faq.answer)}></textarea>
                                        </div>
                                        <div className="col-md-3 ">
                                        {faq?.questionType?.faqQuestionType}

                                        
                                        </div>
                            
                                      
                                        <div className="col-md-3 p">
                                            <div className="row">
                                                {/* <div className="col-md-4">
                                                    <AiFillEye color='red' size={25} onClick={() => showdetails(category)} />
                                                </div> */}
                                                <div className="col-md-4" style={{cursor:"pointer"}}>
                                                    <AiFillDelete color='black' size={30} onClick={() =>
                                                        deletefaq(faq._id)} />
                                                </div>
                                                <div className="col-md-4">
                                                    <Link className="col-md-1" to={`/admin/editfaq/${faq._id}`} ><AiFillEdit size={25} color="blue" />  </Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </>
                            ))
                    }
                </div>
            </div>


    
    </>
  )
}

export default AdminFaq