import React from "react";
import {
  GiBasketballJersey,
  GiFullMotorcycleHelmet,
  GiSkippingRope,
  GiTensionSnowflake,
  GiTightrope,
} from "react-icons/gi";
import { BsFillDoorOpenFill } from "react-icons/bs";
import { FaSkating } from "react-icons/fa";
import { TbDevicesPcOff } from "react-icons/tb";

import EquipmentImg from "../../images/safetypage/equipment.png";
import newSafety from "../../images/safetypage/newSafety.jpeg";
const Equipment = () => {
  const myEquipment = [
    {
      heading: "ROPES",
      content: (
        <>
          {" "}
          We largely use Dyneema ropes for our performances and aerial acts
          thanks to its maximum load- bearing capacity versus its lightness of
          weight. it’s stronger than a steel despite being a fibre rope. <br />
          Dyneema is an UHMwPE (Ultra High Molecular weight Polyethylene) or
          HMPE (High Modulus Polyethylene) fibre developed in the Netherlands
          some 30 years ago. Known as the world’s strongest, lightest fiber – 15
          times stronger than steel, yet floats on water and works to stop
          bullets, repair human joints and improve the longevity of apparel.
        </>
      ),
      icon: (
        <GiSkippingRope
          style={{
            fontSize: "1.5rem",
          }}
        />
      ),
    },
    {
      heading: "MOUNTAINEERING OR INDUSTRIAL HARDWARE PIECES",
      content: (
        <>
          {" "}
          Every one of our units are approved and rated for use in the
          entertainment and industrial sectors, and is certified for static
          loads in accordance with EN standards and for human application with
          dynamic loads in accordance with UIAA requirements;
        </>
      ),
      icon: (
        <BsFillDoorOpenFill
          style={{
            fontSize: "1.5rem",
          }}
        />
      ),
    },
    {
      heading: "ACTUATORS - WINCHES, HOISTS AND MOTORS",
      content: (
        <>
          {" "}
          We have a variety of winches that offer variable lifting capacities
          and speeds. We custom manufacture our motors from a Italian
          manufacturer. As an added measure, we also derate our winches or a
          hoist for human flying Eg: If our motor is certified for 1,000kgs, we
          derate it for human flying by applying a minimum safety factor ratio
          of 1:3 and maximum of 1:10 depending on other risk factors.
        </>
      ),
      icon: (
        <GiTightrope
          style={{
            fontSize: "1.5rem",
          }}
        />
      ),
    },
    {
      heading: "HARNESSES",
      content: (
        <>
          {" "}
          We have a huge variety of harnesses – Jerk Vests, Bikini Harnesses,
          Sit Harnesses, Full-Body Harnesses, Chest Harnesses, Rotating Flying
          Harnesses etc. Each of these harnesses is certified under UIAA or EN
          standards.
        </>
      ),
      icon: (
        <GiBasketballJersey
          style={{
            fontSize: "1.5rem",
          }}
        />
      ),
    },
    {
      heading: "LOAD MONITORING SYSTEMS",
      content: (
        <>
          {" "}
          We are one of the few early adopters in India of Load Monitoring
          Systems. We use a variety of Load Monitoring Systems ranging from
          Wireless, Blue Tooth, Tension Meter, Anamometer and more. <br />
          Load Monitoring Systems are a advanced devices that monitors the load,
          speed and tension on a variety of surfaces. It allows us to monitor
          and understand how the different forces work on our rigs and factors
          for a fail proof setup.
        </>
      ),
      icon: (
        <GiTensionSnowflake
          style={{
            fontSize: "1.5rem",
          }}
        />
      ),
    },
    {
      heading: "TRACKS AND TROLLEYS",
      content: (
        <>
          {" "}
          We use Tracks and Skates along with rope based cross travel system.
          The equipment isn’t available off the shelf and a lot of it is
          designed by Natura or custom made by manufacturers as per our need. We
          always ensure that the unit strength exceeds the safe working load by
          atleast 3 times the device limit.
        </>
      ),
      icon: (
        <FaSkating
          style={{
            fontSize: "1.5rem",
          }}
        />
      ),
    },
    {
      heading: "TENSIONING DEVICE",
      content: (
        <>
          {" "}
          We use Tirfors, Ratchets and Turn Buckles to creating tension / taut
          line systems or Ziplines, rather than doing this the old-school way –
          with a whole group of people pulling on the rope until it has the
          needed tension
        </>
      ),
      icon: (
        <TbDevicesPcOff
          style={{
            fontSize: "1.5rem",
          }}
        />
      ),
    },
    {
      heading: "HELMETS, GLOVES & SAFETY SHOES",
      content: (
        <>
          {" "}
          Everyone from our team is kitted out in the requisite helmets, gloves
          and safety shoes.
        </>
      ),
      icon: (
        <GiFullMotorcycleHelmet
          style={{
            fontSize: "1.5rem",
          }}
        />
      ),
    },
  ];
  return (
    <section style={{ backgroundColor: "#414142" }}>
      <div className="container">
        <div className="row align-item-center text-start py-5 ">
          <div className="col-xl-6 col-lg-6 d-flex align-items-streatch">
            <img
              src={newSafety}
              className="img-fluid"
              // style={{
              //   height: "129vh",
              //   objectFit: "cover",
              // }}
              alt=""
              loading="lazy"
            />
          </div>
          <div className="col-md-6 col-xl-6 col-lg-6">
            <h5
              class="text-white mb-3"
              style={{
                fontSize: "2.1rem",
              }}
            >
              <b style={{ color: "#FEBD15" }}>Equipment </b> Safety
            </h5>
            <hr
              style={{
                marginTop: "0px",
                height: "2px",
                color: "white",
              }}
            />
            <p className="text-white">
              {" "}
              Every piece of equipment used by Natura is tested and certified
              and operated within the recommendations given by the equipment
              manufacturers. We maintain detailed logs for reference and
              observation post usage at any event.
            </p>

            <p
              style={{
                color: "white",
                width: "100%",
                textAlign: "left",
              }}
            >
              We maintain detailed logs for reference and observation post usage
              at any event.
            </p>
            <p
              style={{
                color: "white",
                width: "100%",
                textAlign: "left",
              }}
            >
              Some of the Equipment used commonly by us -
            </p>
            <div className="col-md-12">
              <div
                class="accordion accordion-flush"
                id="faqlist"
                style={{
                  border: "1px solid white",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                {myEquipment.map((data, index) => (
                  <Accrodianitem data={data} index={index + 1} key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Accrodianitem = ({ data, index }) => (
  <>
    <div class="accordion-item">
      <h3 class="accordion-header">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#faq-content-${index}`}
          aria-expanded="false"
        >
          <b>
            {" "}
            {data.icon} &nbsp; {data.heading}
          </b>
        </button>
      </h3>
      <div
        id={`faq-content-${index}`}
        class="accordion-collapse collapse"
        data-bs-parent="#faqlist"
      >
        <div class="accordion-body">
          {" "}
          <div>{data.content}</div>
        </div>
      </div>
    </div>
  </>
);

export default Equipment;
