import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
import Sidebar from "../common/sidebar/Sidebar";

const AddProduct = () => {

    const navigate = useNavigate();
    const [productTitle, setproductTitle] = useState();
    const [productdescription, setproductdescription] = useState();
    const [ProductShortDescription, setProductShortDescription] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [productType, setproductType] = useState();
    const [loading, setLoading] = useState(false);
    const [cate, setcate] = useState()



    useEffect(() => {
        getcategory()
    }, [])

    const getcategory = async () => {
        try {
            const { data } = await axiosInstance.get(`/api/category`)
            setcate(data)
        } catch (error) {
            
            alert(error.response.data)
        }

    }


    const togglehandle = (e) => {
        e.persist();
        var menu_btn = document.querySelector("#menu-btn");
        var sidebar = document.querySelector("#sidebar");
        var container = document.querySelector(".my-container");
        sidebar.classList.toggle("active-nav")
        container.classList.toggle("active-cont");
    }


    const handleUserdataSubmit = async (e) => {
        e.preventDefault();

        if(selectedFile && selectedFile.size >= 2000000){
            alert("Product Image size should be less than 2Mb")
            return
        }




        try {
            setLoading(true)
            const formdata = new FormData();
            formdata.append("ProductTitle", productTitle)
            formdata.append("ProductDescription", productdescription)
            formdata.append("productType", productType)
            formdata.append("productimage", selectedFile)
            formdata.append("ProductShortDescription", ProductShortDescription)

            const { data } = await axiosInstance.post(`/api/product`, formdata, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            
            setLoading(false)
            alert(data.message)
            navigate("/admin/product")
        } catch (error) {
            alert(error.response.data.message)
            setLoading(false)
        }
    }


    return (
        <>
            <Sidebar />

            <div className="p-1 my-container active-cont">
                {/* <!-- Top Nav --> */}
                <nav className="navbar top-navbar navbar-light bg-light px-5">
                    <a className="btn border-0" id="menu-btn" onClick={togglehandle}><i className="bx bx-menu"></i></a>
                </nav>
                {/* <!--End Top Nav --> */}
                <h3 className="text-dark p-3">ADMIN ADD PRODUCT 💻 📱
                </h3>

                <div className="row mx-2 " style={{ backgroundColor: "#FDF8DB" }}>

                    {/* <div className="toogler"><h3>Add Category</h3></div> */}

                    <form onSubmit={handleUserdataSubmit}>
                        <div className="col-md-6 align-items-center">
                            <label>Product Title</label>
                            <input className="form-control " type="text" onChange={e => setproductTitle(e.target.value)} placeholder="Product Title" required />
                        </div>
                        <div className="col-md-6 align-items-center">
                            <label>Product short Description</label>
                            <input className="form-control " type="text" value={ProductShortDescription } onChange={e => setProductShortDescription(e.target.value)} placeholder="Description" required />
                        </div>
                        <div className="col-md-6 align-items-center">
                            <label>Product Description</label>
                            <input className="form-control " type="text" onChange={e => setproductdescription(e.target.value)} placeholder="Description" required />
                        </div>
                        <div className="col-md-6">
                            <label>Service Type</label>
                            <select class="form-select form-select-sm" aria-label=".form-select-sm example" onChange={(e) => setproductType(e.target.value)} required>
                                <option value="">Select Category</option>
                                {
                                    cate && cate.map((category) => (
                                        <>
                                            <option value={category._id}>{category.categoryName}</option>
                                        </>
                                    ))
                                }
                                {/* <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option> */}
                            </select>
                        </div>

                        <div className="col-md-6 align-items-center">
                            <label>Product Image (1600 X 700 px) (2 mb size) </label>
                            <label > image extensions : jpeg | JPEG | jpg |JPG |png |PNG </label>
                            <input type="file" class="form-control" aria-describedby="emailHelp" placeholder="Enter file" onChange={e => setSelectedFile(e.target.files[0])} required />
                        </div>

                        <div className="col-md-6" style={{ padding: "5px" }}>
                            <div className="d-flex flex-row">
                                {
                                    loading ? <>
                                        <button className="btn btn-success" >Loading</button>
                                    </> : <>
                                        <button className="btn btn-success" >Submit</button>
                                    </>
                                }
                            </div>
                        </div>
                    </form>

                </div>

            </div>



        </>
    )
}


export default AddProduct