import React, { useEffect } from 'react';
import "./thankyou.css";
import {Header} from "../Header" 
import { useLocation, useNavigate } from 'react-router-dom';


const ThankYou = () => {
    const navigate = useNavigate()
    // let location = useLocation();
    // const setBreadcrumbs = async (nameData) => {
    //     // let location = useLocation();
    //     const breadcrumbs = JSON.parse(localStorage.getItem("breadcrumbs"));
    //     
    //     const index = breadcrumbs.findIndex((e) => e.name === nameData);
    //     
    //     if (index !== -1) {
    //       breadcrumbs.splice(1, index);
    //     }
    //     if (breadcrumbs.length < 5) {
    //       breadcrumbs.push({ name: nameData, path: location.pathname });
    //       await localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
    //     } else {
    //       breadcrumbs.shift();
    //       await localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
    //       breadcrumbs.push({ name: nameData, path: location.pathname });
    //       await localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
    //     }
    //   };
    //   useEffect(() => {
    //     setBreadcrumbs("ThankYou");
    //   }, []);
    return (
        <>
        <Header/>
        <div className="main-container text-center" >
            <div className="clswrapper mt-5" style={{marginTop:"120px"}}>
            <div class="content" style={{marginTop:"120px"}}>
            <div class="wrapper-1">
                <div class="wrapper-2">
                    <h1>Thank you !</h1>
                    {/* <p>Thanks for subscribing to our news letter.  </p> */}
                    <p>Our Team will connect and assist you soon!  </p>
                    <button class="go-home" onClick={()=>navigate("/")}>
                        go home
                    </button>
                </div>
                {/* <div class="footer-like">
                    <p>
                        Email not received?
                        <a href="#">Click here to send again</a>
                    </p>
                </div> */}
            </div>
        </div>
            </div>
        

        </div>
       
       
        </>
    )
}

export default ThankYou