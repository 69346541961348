import React from "react";
import { Link } from "react-router-dom";
import "./sidebar.css";
import bg from "../../../images/client/logohori.png";

const logout = () => {
  localStorage.clear();
  alert("remove successfuuly");
};

const Sidebar = () => {
  const logouthandle = (e) => {
    e.preventDefault();
    alert("Logout Successfully");
  };
  return (
    <>
      <div
        style={{ height: "100vh", overflowY: "scroll" }}
        className="side-navbar active-nav d-flex justify-content-between flex-wrap flex-column"
        id="sidebar"
      >
        <ul className="nav flex-column text-white w-100 ">
          <a href="#" className="nav-link h6 text-white my-2">
            <div className="logo py-3">
              {/* <h1>Innov<span style={{color:'gray',fontSize:'2.3rem',opacity:'0.3'}}>.8</span> </h1> */}
              <img className=" foteer_img img-fluid" src={bg} alt="" />
            </div>
          </a>
          <Link to="/" className="nav-link">
            <i className="bx bx-home text-white"></i>
            <span style={{ fontSize: "1.2rem" }} className="mx-2 text-white">
              Home
            </span>
          </Link>
          {/* <Link to="/admin/user" className="nav-link" >
                        <i className="bx bx-user-check text-white"></i>
                        <span style={{fontSize:'1.2rem'}} className="mx-2 text-white">User</span>
                    </Link> */}
          <Link to="/admin/service" className="nav-link">
            <i className="bx bx-user-check text-white"></i>
            <span style={{ fontSize: "1.2rem" }} className="mx-2 text-white">
              Services
            </span>
          </Link>
          <Link to="/admin/GalleryDashboard" className="nav-link">
            <i className="bx bx-user-check text-white"></i>
            <span style={{ fontSize: "1.2rem" }} className="mx-2 text-white">
              GalleryDashboard
            </span>
          </Link>
          <Link to="/admin/product" className="nav-link">
            <i className="bx bx-body text-white"></i>
            <span style={{ fontSize: "1.2rem" }} className="mx-2 text-white">
              Product
            </span>
          </Link>
          <Link to="/admin/event" className="nav-link">
            <i className="bx bx-user-check text-white"></i>
            <span style={{ fontSize: "1.2rem" }} className="mx-2 text-white">
              Event
            </span>
          </Link>
          <Link to="/admin/faqcategory" className="nav-link">
            <i className="bx bx-user-check text-white"></i>
            <span style={{ fontSize: "1.2rem" }} className="mx-2 text-white">
              FAQ Category
            </span>
          </Link>
          <Link to="/admin/ContactUs" className="nav-link">
            <i className="bx bx-user-check text-white"></i>
            <span style={{ fontSize: "1.2rem" }} className="mx-2 text-white">
              Contact Us
            </span>
          </Link>
          <Link to="/admin/faq" className="nav-link">
            <i className="bx bx-user-check text-white"></i>
            <span style={{ fontSize: "1.2rem" }} className="mx-2 text-white">
              FAQ
            </span>
          </Link>
          <Link to="/admin/testimonial" className="nav-link">
            <i className="bx bx-user-check text-white"></i>
            <span style={{ fontSize: "1.2rem" }} className="mx-2 text-white">
              Testimonial
            </span>
          </Link>
          <Link to="/admin/blog" className="nav-link">
            <i className="bx bx-user-check text-white"></i>
            <span style={{ fontSize: "1.2rem" }} className="mx-2 text-white">
              Blog
            </span>
          </Link>
          <Link to="/admin/Team" className="nav-link">
            <i className="bx bx-user-check text-white"></i>
            <span style={{ fontSize: "1.2rem" }} className="mx-2 text-white">
              Team
            </span>
          </Link>

          <Link to="/" className="nav-link" onClick={logout}>
            <i className="bx bx-user-check text-white"></i>
            <span style={{ fontSize: "1.2rem" }} className="mx-2 text-white">
              Logout
            </span>
          </Link>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
