import React, { useEffect } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import "./Home.css";
import { RIG_TECH_DATA } from "../Rig Tech/RigTech";
import Topbanner from "../components/topbanner/Topbanner";
import "../styles/environment-list.scss";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import Breadcrumb from "../components/common/breadcrumb";
import ProductCarousal from "../components/testimonalcarousal/ProductCarousal";
export const Rig_Tech_Services_Product = (props) => {
  const { name } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const details = RIG_TECH_DATA.find((ele) => ele.url === name);

  return (
    <div className="colorSection">
      <Header />

      <Topbanner
        heading="Rig Tech"
        height="45vh"
        img="https://natura.co.in/images/gallery/1711309434473_rigtec.jpg"
      />
      <section
        className="team  py-5"
        style={{ backgroundColor: "rgb(204, 204, 204)" }}
      >
        <div className="container">
          <div
            className="row justify-content-between align-items-center "
            style={{ overflow: "hidden" }}
          >
            <div className="col-12">
              <div className="text-start">
                {/* <h2 style={{ color: '#FEBD15' }}><b>ABOUT US</b></h2> */}
                <Breadcrumb name={details?.productName} />
                <h2 style={{ color: "#000" }}>
                  <b>{details?.productName}</b>
                </h2>
              </div>
            </div>
          </div>
          {parse(details.description)}

          {!details.isMulti_product && (
            <>
                 <div className="d-flex justify-content-center">
                <a
                  href={details.button_link}
                  className="btn rig-tech-whatsapp-icon"
                >
                  <i className="fab fa-whatsapp" style={{ fontSize: "26px" }} />
                  <h6 className="Chat-On-WhatsApp"> Chat On WhatsApp</h6>
                </a>
              </div>

              <div className="rigtech_image">
                <img src={details.banner_image} alt={details?.productName} />
              </div>

              <ProductCarousal Data={details.product_Catalog_images} />
              <div className="d-flex justify-content-center">
                <a
                  href={details.button_link}
                  className="btn rig-tech-whatsapp-icon"
                >
                  <i className="fab fa-whatsapp" style={{ fontSize: "26px" }} />
                  <h6 className="Chat-On-WhatsApp"> Chat On WhatsApp</h6>
                </a>
              </div>
            </>
          )}
        </div>
      </section>

      {details.isMulti_product && (
        <section className="colorSection">
          <div className="container py-5 text-start">
            <div class="d-flex ">
              <div class="section-title text-start col-lg-6 text-muted">
                <h2 className="text-dark">
                  Our <span>Products</span>
                </h2>
              </div>
            </div>
            <Products products_Data={details.products} />
          </div>
        </section>
      )}
      <Footer />
    </div>
  );
};

const Products = ({ products_Data }) => {
  return (
    <section className="environment-list section-padding mt-4">
      <div className="items d-flex align-items-center">
        {products_Data.map((data) => (
          <>
            <div className="item">
              <div className="inner-content d-flex align-items-start">
                <div className="left-section">
                  <img
                    src={`${data?.image}`}
                    alt={data.name}
                    className="img-fluid"
                    loading="lazy"
                    // width={"767"}
                    // height={"569"}
                  />
                </div>
                <div className="right-section">
                  <div className="content">
                    <div className={`md-heading`}>
                      <h3>
                        {" "}
                        <b>{data.name}</b>
                      </h3>
                    </div>
                    {/* <p>{data.description}</p> */}
                    {parse(data.description)}
                    <a
                      href={data.button_link}
                      className="btn rig-tech-whatsapp-icon"
                    >
                      <i
                        className="fab fa-whatsapp"
                        style={{ fontSize: "26px" }}
                      />
                      <h6 className="Chat-On-WhatsApp"> Chat On WhatsApp</h6>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </section>
  );
};
