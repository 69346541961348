import React from "react";
// import Topbanner from "../../components/topbanner/Topbanner";
import { Footer } from "../../Home/Footer";
import { Header } from "../../Home/Header";
import SafImg from "../../images/natura/safety.jpg";

import "./safetypage.css";
import NumberScroller from "react-number-scroller";
import Insurance from "./Insurance";
import Certifications from "./Certifications";
import Equipment from "./Equipment";
import Eventbanner from "../../components/Eventbanner/Eventbanner";
import SafetyProcesses from "./SafetyProcesses";
import Education from "./Education";
import HeaderNew from "../../Home/HeaderNew";
// import { Link, useLocation } from "react-router-dom";
// import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const SafetyPage = () => {
  // let location = useLocation();

  return (
    <>
      <Header />
    

      <Eventbanner />
      <section id="counts" className="countsold  py-lg-4 py-1">
        <div className="container">
          <div className="row counters">
            <div className="col-lg-3 col-6 text-center">
              <span>
                <NumberScroller from={0} to={16} timeout={3000} />
              </span>
              &nbsp;<span> yrs</span>
              <p>Experience</p>
            </div>

            <div className="col-lg-3 col-6 text-center">
              <span>
                <NumberScroller from={400} to={800} timeout={2000} />
              </span>
              &nbsp;<span>+</span>
              <p>Projects</p>
            </div>

            <div className="col-lg-3 col-6 text-center">
              <span>
                <NumberScroller from={1000} to={1500} timeout={2000} />
              </span>
              &nbsp;<span>+</span>
              <p>Clients</p>
            </div>
            <div className="col-lg-3 col-6 text-center">
              <p className="animate__animated animate__pulse animate__infinite	infinite Header_zero">
                ZERO
              </p>
              <p style={{ color: "#000" }}>MISHAPS</p>
            </div>
          </div>
        </div>
      </section>
      {/* <div
        className="section2 d-flex justify-content-start align-items-center"
        style={{
          height: "100vh",
        }}
      >
        <div className="container-fluid px-0">
          <div
            className="text-start text-white  col-md-7 p-lg-8 p-3 "
            style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
          >
            <div className="text-start text-white  col-md-9 p-lg-10  ">
            <div className="container px-lg-6">
              <p style={{ margin: "0px" }}>At Natura</p>
              <h2>SAFETY</h2>
              <br />
              <p>
                <span> “Responsible Play” </span> and <span> “#RigSafe” </span>{" "}
                are ideals we live by for ourselves and our clients at every
                stage of our work – from conception to execution! <br /> <br />
                We have had <span> “ZERO” </span> accidents in our entire
                existence <br/><b style={{ color: "#FEBD15" }}>(Absolute Zero)</b>
              </p>
            </div>
            </div>
            
          </div>
        </div>
      </div> */}

      <section
        id="safety"
        className="py-5"
        style={{ backgroundColor: "#414142" }}
      >
        <div className="container">
          <div className="row">
            {/* <Breadcrumbs/> */}
            <div className="col-md-12 text-start">
              <div class="section-title  col-lg-5 text-muted py-4">
                <h2 class="text-white">
                  Responsible <span> play</span>
                  <hr
                    style={{
                      // marginTop: "0px",
                      height: "2px",
                      color: "white",
                      // width:"30%"
                    }}
                  />
                </h2>
              </div>
              <p class="text-white">
                Cramped film / television production sets, live events,
                high-risk workplaces and industrial setups, camps, resorts,
                educational establishments, and so on - every location has risk
                scenarios. Even with the best infrastructure and personnel,
                accidents might occur due to unusual or regularly unaccounted
                for gaps.
              </p>
              <p class="text-white">
                We understand our client’s brief and what they’re looking to
                accomplish and we’re as flexible as what is possible within the
                Laws of Physics to give our clients what they want within the
                parameters of safety.
              </p>
              <p class="text-white">
                SAFETY isn&#39;t just a concept, it is a mindset espoused by all
                the teammates at all levels. Ensuring ZERO MISHAPS.
              </p>
              <p class="text-white">
                {" "}
                <b style={{ color: "#FEBD15" }}>
                  {" "}
                  PROBABILITY X CONSEQUENCE = RISK
                </b>
              </p>

              {/* <ul class="text-white">
                <li>Responsible play – info</li>
                <li>Then insurance</li>
                <li>Cert</li>
                <li>Safety p</li>
              </ul> */}
            </div>
          </div>
        </div>
      </section>

      <Insurance />
      <Certifications />
      <SafetyProcesses />
      <Equipment />
      <Education />





      {/* <section
        id="safety"
        className="py-4"
        style={{ backgroundColor: "#616164" }}
      >
        <div className="container">
          <div className="row">
            
            <div className="col-md-6 text-start">
              <div class="section-title  col-lg-5 text-muted">
                <h2 class="section-title__title text-white">
                  Education and <b style={{ color: "#FEBD15" }}>Consulting </b>
                  <hr
                    style={{
                     
                      height: "2px",
                      color: "white",
                     
                    }}
                  />
                </h2>
              </div>
              <p class="text-white">
                In 2014, we expanded our role from being only Entertainment
                Riggers to getting involved with projects at a conceptual,
                planning and execution stage as Rigging Safety Consultants.
              </p>

             
            </div>
            <div className="col-md-6 text-start">
              <img src="" alt="" />
             
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </>
  );
};

export default SafetyPage;
