import React, { useState, useRef } from "react";
import Sidebar from "../common/sidebar/Sidebar";
import { axiosInstance } from "../../config";
import { useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import FileBase64 from "react-file-base64";

const AddTestimonial = () => {
  const navigate = useNavigate();
  const editor = useRef(null);
  const [AuthorName, setAuthorName] = useState();
  const [AuthorImg, setAuthorImg] = useState({
    file: null,
    base64URL: "",
  });
  const [Designation, setDesignation] = useState("");
  const [companyName, setcompanyName] = useState();
  const [FeaturedTestimonials, setFeaturedTestimonials] = useState(false);
  const [TestimonialContent, setTestimonialContent] = useState("");
  const [testimonialType, settestimonialType] = useState();
  const [loading, setloading] = useState(false);

  const config = {
    uploader: {
      insertImageAsBase64URI: true,
    },
  };

  const handleTestimonial = async (e) => {
    e.preventDefault();
    console.log(AuthorImg.base64URL, "AuthorImg.base64URL");
    try {
      setloading(true);

      const apidata = {
        AuthorName: AuthorName,
        Designation: Designation,
        companyName: companyName,
        FeaturedTestimonials: FeaturedTestimonials,
        TestimonialContent,
        testimonialType,
        AuthorImg: AuthorImg.base64URL,
      };

      if (companyName) {
        apidata.companyName = companyName;
      }

      const { data } = await axiosInstance.post("/api/testimonial", apidata);
      setloading(false);

      setloading(false);

      alert(data.message);
      navigate("/admin/testimonial");
    } catch (error) {
      console.log("check this error", error);
      setloading(false);

      alert(error.response.data.message);
    }
  };

  const togglehandle = (e) => {
    e.persist();
    // var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };

  const getBase64 = async (file) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object

      baseURL = reader.result;
      setAuthorImg({
        file,
        base64URL: baseURL,
      });
    };
  };
  return (
    <>
      <Sidebar />

      <div
        className="p-1 my-container active-cont"
        style={{ backgroundColor: "#FDF8DB" }}
      >
        {/* <!-- Top Nav --> */}
        <nav className="navbar top-navbar navbar-light bg-light px-5">
          <button className="btn border-0" id="menu-btn" onClick={togglehandle}>
            <i className="bx bx-menu"></i>
          </button>
        </nav>
        {/* <!--End Top Nav --> */}
        <h3 className="text-dark p-3">ADMIN ADD TESTIMONIAL </h3>

        <form onSubmit={handleTestimonial}>
          <div className="row  justify-content-center">
            <div className="col-md-8 text-start py-3 ">
              <label className="text-start p-1">Author Name</label>
              <input
                className="form-control "
                type="text"
                onChange={(e) => setAuthorName(e.target.value)}
                placeholder="Enter Author Name"
                required
              />
            </div>
            <div className="col-md-8 text-start py-3 ">
              <label className="text-start p-1">Author Image</label>
              <input
                className="form-control "
                type="file"
                onChange={(e) => getBase64(e.target.files[0])}
                placeholder="Enter Author Image"
              />
              {/* <FileBase64
                className="form-control "
                multiple={false}
                onDone={(e) => {
                  console.log("check this 110", e);
                }}
                // onChange={(e) => {
                //     console.log("log check",e.target.files[0]);
                //     setAuthorImg(e.target.files[0])}}
              /> */}
            </div>
            <div className="col-md-8 text-start py-3">
              <label>Designation</label>
              <input
                className="form-control "
                type="text"
                onChange={(e) => setDesignation(e.target.value)}
                placeholder="Designation"
                required
              />
            </div>
            <div className="col-md-8 text-start py-3">
              <label>Company Name(optional)</label>
              <input
                className="form-control "
                type="text"
                onChange={(e) => setcompanyName(e.target.value)}
                placeholder="Enter Company Name"
              />
            </div>
            <div className="col-md-8 text-start py-3">
              <label>Featured Testimonial</label>
              <select
                class="form-select form-select-sm"
                aria-label=".form-select-sm example"
                value={FeaturedTestimonials}
                onChange={(e) => setFeaturedTestimonials(e.target.value)}
                required
              >
                <option>Select Featured Testimonial</option>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </select>
            </div>
            <div className="col-md-8 text-start py-3">
              <label>Testimonial Type</label>
              <select
                class="form-select form-select-sm"
                aria-label=".form-select-sm example"
                value={testimonialType}
                onChange={(e) => settestimonialType(e.target.value)}
                required
              >
                <option>Select Testimonial Type</option>
                <option value="Video">Video</option>
                <option value="Text">Text</option>
              </select>
            </div>

            <div className="col-md-8 text-start py-3">
              <JoditEditor
                ref={editor}
                value={TestimonialContent}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setTestimonialContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => {}}
              />
            </div>

            <div
              className="col-md-8 text-start py-3"
              style={{ padding: "5px" }}
            >
              <div className="d-flex flex-row">
                {loading ? (
                  <>
                    <button className="btn btn-success" disabled>
                      Loading
                    </button>
                  </>
                ) : (
                  <>
                    <button className="btn btn-success">Submit</button>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddTestimonial;
