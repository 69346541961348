import React from "react";
import SafetyProcessesImg from "../../images/safetypage/safetyprocesses.png";
// import newSafety from "../../images/safetypage/newSafety.jpeg";
import newSafety from "../../images/safetypage/safety.png";
import {
  GiBasketballJersey,
  GiFullMotorcycleHelmet,
  GiDwarfHelmet,
  GiTensionSnowflake,
  GiCardDraw,
} from "react-icons/gi";


import { AiFillSafetyCertificate } from "react-icons/ai";
import { FaHandshake } from "react-icons/fa";
import { RiMotorbikeFill } from "react-icons/ri";
import { MdOutlineWarning } from "react-icons/md";



const SafetyProcesses = () => {
  return (
    <section style={{ backgroundColor: "#656565" }}>
      <div className="container">
        <div className="row  text-start py-5">
          <div className="col-md-6">
            <h5
              class="text-white mb-3"
              style={{
                fontSize: "2.1rem",
              }}
            >
              <b style={{ color: "#FEBD15" }}>Safety</b> Processes
            </h5>
            <hr
              style={{
                marginTop: "0px",
                height: "2px",
                color: "white",
              }}
            />
            <p className="text-white">
              {" "}
              We follow stringent Standard Operating Procedures (SOPs) that are
              aligned to guidelines of international safety standards.
            </p>
            <p className="text-white">
              {" "}
              We maintain{" "}
              <b style={{ color: "#FEBD15" }}>
                clear and open communication
              </b>{" "}
              with the client, technical partners and performers in order to
              gain their adherence and commitment to the same safety standards.

            </p>
            <p className="text-white">
              <b style={{ color: "#FEBD15" }}>
                Post Event Review
              </b>

            &nbsp;is conducted for Incident logging and Root Cause
              Analysis so that learnings are documented and implemented in
              future events
            </p>
            <p className="text-white">
              We have a <b style={{ color: "#FEBD15" }}>
                secondary
              </b> independent manual system as well as <b style={{ color: "#FEBD15" }}>backup</b>  in
              case of an electrical or a mechanical failure or even sabotage.
            </p>
            <p className="text-white">
              We work according to pre-determined protocols, with every person
              assigned a pre-set role. Riggers maintain <b style={{ color: "#FEBD15" }}>adequate documentation</b>             {/* <b style={{ color: "#FEBD15" }}>
                adequate documentation 
              </b>  */}
              like
            </p>
            {/* <p
              style={{
                color: "white",
                width: "100%",
                textAlign: "left",
              }}
            >
              Some of the Equipment used commonly by us -
            </p> */}
            <div className="col-md-12">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingLast">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseLast"
                      aria-expanded="false"
                      aria-controls="collapseLast"
                    >
                      <b
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <AiFillSafetyCertificate
                          style={{
                            fontSize: "1.5rem",
                          }}
                        />{" "}
                        &nbsp; Safety Checklists{" "}
                      </b>
                    </button>
                  </h2>
                  <div
                    id="collapseLast"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingLast"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      To guide the supervisors and workers in ensuring that pre
                      - determined SOPs are being followed and safety as well as
                      working condition of the equipments used are checked
                      before each use.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <b
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <GiDwarfHelmet
                          style={{
                            fontSize: "1.5rem",
                          }}
                        />{" "}
                        &nbsp; Equipment Logs
                      </b>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      To record asset information, purpose, usage, maintenance,
                      the exact time of these tasks, tracking of failure and
                      repair patterns to determine when to replace an asset when
                      replacement is necessary.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <b
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <FaHandshake
                          style={{
                            fontSize: "1.5rem",
                          }}
                        />{" "}
                        &nbsp; Service Agreement
                      </b>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Natura enters into a legal service agreement with all
                      partners for every project in order to agree on the terms
                      and conditions as well as the deliverables that will be
                      provided.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="HARNESSES">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseHARNESSES"
                      aria-expanded="false"
                      aria-controls="collapse4"
                    >
                      <b
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        {" "}
                        <MdOutlineWarning
                          style={{
                            fontSize: "1.5rem",
                          }}
                        />{" "}
                        &nbsp; Risk Disclosure Form{" "}
                      </b>
                    </button>
                  </h2>
                  <div
                    id="collapseHARNESSES"
                    class="accordion-collapse collapse"
                    aria-labelledby="HARNESSES"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Aerial activities and adventure sports have inherent risks
                      and dangers, which are stated out so that participants in
                      the activity are aware of them and consent before using
                      our services.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="five">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefive"
                      aria-expanded="false"
                      aria-controls="collapsefive"
                    >
                      <b
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <GiTensionSnowflake
                          style={{
                            fontSize: "1.5rem",
                          }}
                        />{" "}
                        &nbsp; Method Statements
                      </b>
                    </button>
                  </h2>
                  <div
                    id="collapsefive"
                    class="accordion-collapse collapse"
                    aria-labelledby="five"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      We develop thorough method statements that enable every
                      member of the staff to be competent and knowledgeable
                      about the specific working processes for the act,
                      guaranteeing that all difficult work is carried out
                      precisely while lowering the likelihood of mishaps.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="heading7">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseheading7"
                      aria-expanded="false"
                      aria-controls="collapseheading7"
                    >
                      <b>
                        <RiMotorbikeFill
                          style={{
                            fontSize: "1.5rem",
                          }}
                        />{" "}
                        &nbsp;TECHANICAL RIDERS
                      </b>
                    </button>
                  </h2>
                  <div
                    id="collapseheading7"
                    class="accordion-collapse collapse"
                    aria-labelledby="heading7"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Tech requirement documents which has all the pertinent
                      information that a event manager / venue requires to
                      assist us with putting on a successful performance. It
                      will include information on who will be performing, what
                      equipment will be used, how the venue will be set up, and
                      much more.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="heading8">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse8"
                      aria-expanded="false"
                      aria-controls="collapse8"
                    >
                      <b>
                        <GiCardDraw
                          style={{
                            fontSize: "1.5rem",
                          }}
                        />{" "}
                        &nbsp; DRAWINGS
                      </b>
                    </button>
                  </h2>
                  <div
                    id="collapse8"
                    class="accordion-collapse collapse"
                    aria-labelledby="heading8"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Technical Drawings are made for every event to demonstrate
                      which rigging points in the venue will be used to hang the
                      show's structural requirements or where in the ceiling
                      rigging points must be "created" to allow for the
                      necessary suspension of those structures.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 d-flex align-items-streatch">

            <img
              style={
                {
                  // height: "64vh",
                }
              }
              className="img-fluid"
              src={newSafety}
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SafetyProcesses;
