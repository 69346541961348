import React from 'react'
import { Link } from 'react-router-dom'

const SafetyProductdesign = ({product}) => {
    // const localurl = "https://natura.co.in"
    const localurl = "https://natura.co.in"
    return (
        <>
         <Link to={`/singleproject/${product._id}`}>
            <section>
                <div class="blog-card text-start">
                    <div class="meta">
                        <div class="photo"  style={{ backgroundImage: `url(${localurl}/images/product/${product?.ProductImage})` }}></div>
                        {/* <ul class="details">
                            <li class="author"><a href="#">John Doe</a></li>
                            <li class="date">Aug. 24, 2015</li>
                            <li class="tags">
                                <ul>
                                    <li><a href="#">Learn</a></li>
                                    <li><a href="#">Code</a></li>
                                    <li><a href="#">HTML</a></li>
                                    <li><a href="#">CSS</a></li>
                                </ul>
                            </li>
                        </ul> */}
                    </div>
                    <div class="description">
                        <h1 className='text-black'>{product.ProductTitle}</h1>
                        <h2>Product</h2>
                        <p className='text-black'> {product.ProductDescription.slice(0,95)}...</p>
                        <p class="read-more">
                            <Link to={`/singleproject/${product._id}`}>Read More</Link>
                        </p>
                    </div>
                </div>

            </section>
            </Link>
        </>
    )
}

export default SafetyProductdesign