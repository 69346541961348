import React, { useEffect, useState, useRef } from "react";
import "./Home.css";

import { Header } from "./Header";
import { useLocation } from "react-router-dom";
import { Footer } from "./Footer";
import { axiosInstance } from "../config";
import Card from "../components/cardcomponent/Card";
import Breadcrumbs from "../pages/Breadcrumbs/Breadcrumbs";
import axios from "axios";
import { BiLoader } from "react-icons/bi";
import HeaderNew from "./HeaderNew";

export const Events = () => {
  const [eventcategory, seteventcategory] = useState("All");
  const [FilteredEvent, setFilteredEvent] = useState();
  const [eventcategoryindex, seteventcategoryindex] = useState(1000);
  const [loader, setloader] = useState(false);

  useEffect(() => {
    getfilterevent();

    // return () => {
    //   seteventcategory();
    // }
  }, [eventcategory]);

  const getfilterevent = async () => {
    try {
      setloader(true);
      const { data } = await axiosInstance.post(`/api/event/getmatchedevent`, {
        eventcategory: eventcategory,
      });

      setFilteredEvent(data);
      setloader(false);
    } catch (error) {
      setloader(false);
      alert(error.response.data.message);
    }
  };

  const eventcategorydata = [
    {
      name: ["Launch", "Unveiling Events"],
      displayName: "Launch/Unveiling",
    },
    {
      name: ["Opening Ceremonies"],
      displayName: "Opening Ceremonies",
    },
    {
      name: ["Weddings", "Celebrations"],
      displayName: "Weddings/ Celebrations",
    },
    // {
    //   name: ["Nature", "Adventure", "Outdoors"],
    // displayName:"",
    // },
    {
      name: ["Adventure"],
      displayName: "Adventure",
    },
    {
      name: ["Art", "Theatre"],
      displayName: "Art/Theatre",
    },
    {
      // name: ["Corporate", "Brand Events"],
      name: ["Corporate Brand Events"],
      displayName: "Corporates and Brands",
    },
    {
      name: ["Films", "TV", "Ads"],
      displayName: "Films/TV/Ads",
    },
    {
      name: ["Award shows"],
      displayName: "Award shows",
    },

    {
      name: ["Natura Promotional events"],
      displayName: "Promotional Events",
    },

    {
      name: ["Rigging Workshop"],
      displayName: "Rigging Workshop",
    },
  ];

  const setCate = (name, index) => {
    seteventcategory(name);
    seteventcategoryindex(index);
  };

  return (
    <div>
      <Header />
      {/* <HeaderNew/> */}

      <section className="events_banner d-flex justify-content-center justify-content-lg-end align-items-center">
        <div className="col-lg-6 text-white text-center">
          <div className="text-start">
            <div className="upper_head_about">
              <h2>
                <b className="text-white" style={{ color: "#FEBD15" }}>
                  India's
                </b>
                <br />
                <b className="" style={{ color: "#484848" }}>
                  First and only
                </b>
                <br />
                <b className="text-white" style={{ color: "" }}>
                  Aerial Rigging
                </b>
                <br />
                <b style={{ color: "#FEBD15" }}>company</b>
              </h2>
            </div>
          </div>
        </div>
      </section>

      {/* top nevents-- */}
      {/* Our Services */}
      <section id="why-us" class="why-us events pb-5 colorSection">
        <div class="container">
          {/* <Breadcrumbs/> */}
          <div class="d-flex m-3">
            <div class="section-title text-center col-lg-12 text-muted m-2">
              <h2 className="text-dark">
                Our <span> Events</span>
              </h2>
              <p className="m-2">
                We have delivered many memorable and stunning events for our
                clients which struck the audience with awe.
              </p>
            </div>
          </div>
          <div className="d-flex flex-wrap d-lg-none d-block text-center  justify-content-center mb-5 ">
            {eventcategorydata &&
              eventcategorydata.map((p, i) => (
                <>
                  <button
                    className={
                      eventcategoryindex === i
                        ? "col-md-2 col-5  bg-warning text-white  m-1  p-0 rounded"
                        : "col-md-2  bg-dark text-white  col-5 m-1  p-lg-0 rounded"
                    }
                    style={{
                      fontSize: "10px",
                      // width:"20%"
                      height: "8vh",
                    }}
                    onClick={() => setCate(p.name, i)}
                  >
                    {p.displayName}
                    {/* {p.displayName.map((p, i) => (
                      <>
                      {p}

                      {i === 0 ? <>{p}</> : <> / {p}</>}

                      </>
                    ))} */}
                  </button>
                </>
              ))}
          </div>

          <div className="row">
            <div className="col-lg-2 d-none d-lg-block">
              <div
                className=" text-center "
               
              >
                <div
                  style={{
                    boxShadow: "7px -3px 10px 3px hsla(0,0%,79%,1)",
                    backgroundColor: "#bbbbbb3d",
                    padding: "1rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <h4 className="mt-2">Category</h4>
                  <div
                    className={
                      eventcategoryindex === 1000 ? "CategoryButton" : ""
                    }
                  >
                    <h6
                      className={
                        eventcategoryindex === 1000
                          ? "text-warning text-start  m-1  p-0 "
                          : "text-dark  text-start  m-1  p-lg-0 "
                      }
                      style={{
                        fontSize: "13px",
                        cursor: "pointer",
                        // width:"20%"
                        // height: "8vh",
                      }}
                      onClick={() => setCate("All", 1000)}
                    >
                      All
                    </h6>
                  </div>

                  {eventcategorydata &&
                    eventcategorydata.map((p, i) => (
                      <>
                        <div
                          className={
                            eventcategoryindex === i ? "CategoryButton" : ""
                          }
                        >
                          <h6
                            className={
                              eventcategoryindex === i
                                ? "   text-warning text-start  m-1  p-0 "
                                : "   text-dark  text-start  m-1  p-lg-0 "
                            }
                            style={{
                              fontSize: "13px",
                              cursor: "pointer",
                              // width:"20%"
                              // height: "8vh",
                            }}
                            onClick={() => setCate(p.name, i)}
                          >
                            {p.displayName}

                            {/* {p.displayName.map((p, i) => (
                              <>
                              {i === 0 ? <>{p}</> : <> / {p}</>}
                              {p}
                              </>
                            ))} */}
                          </h6>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-10 col-12">
              {loader ? (
                <BiLoader />
              ) : FilteredEvent && FilteredEvent.length === 0 ? (
                <>
                  <div className="row d-flex justify-content-center mt-5 ">
                    <div className="col-md-8 text-center">
                      <h3 style={{ fontSize: "20px", fontWeight: "900" }}>
                        To be Updated on Website
                      </h3>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Card event={FilteredEvent} />
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
