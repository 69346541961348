import React from "react";
import "./Home.css";
import { Header } from "./Header";
import { Link, useLocation } from "react-router-dom";
import { Footer } from "./Footer";
import NumberScroller from "react-number-scroller";
import HeaderNew from "./HeaderNew";

export const About = () => {
  return (
    <div className="colorSection">
      <Header />
      {/* <HeaderNew/> */}

      <section
        className="about_video_banner d-flex justify-content-center  align-items-center py-5 mt-5 container-fluid"
        style={{ backgroundColor: "#191919", height: "80vh" }}
      >
        <div className="container">
          <div className="row  justify-content-center align-items-center">
            <div className="col-lg-6 text-dark">
              <div>
                <img
                  src={require("../images/natura/hang.png")}
                  style={{ marginTop: "-45px" }}
                  className=" swing"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 text-white ">
              <div className="text-start">
                <div className="upper_head_about">
                  <h2>
                    <b className="text-white" style={{ color: "#FEBD15" }}>
                      INDIA'S
                    </b>
                    <br />
                    <b className="" style={{ color: "#484848" }}>
                      FIRST & ONLY
                    </b>
                    <br />
                    <b className="text-white" style={{ color: "" }}>
                      AERIAL RIGGING
                    </b>
                    <br />
                    <b style={{ color: "#FEBD15" }}>COMPANY</b>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="client mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="text-start ps-3">
                <div class="section-title text-start col-lg-6 text-muted">
                  <h2 className="text-dark">
                    Our <span> Clientele</span>
                  </h2>
                </div>
                <p>
                  We are extremely proud of our collaborations with some of the
                  top production companies, choreographers, and event planners
                  in the nation, which motivates us to strive for excellence in
                  every act and performance that we design and create
                </p>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
          <div className="container my-5 ">
            <div className="client_box p-5 rounded" style={{}}>
              <div className="row">
                <div className="col-md-2 col-4 p-3">
                  <img
                    className="img-fluid"
                    src={require("../images/natura/client11.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-2 col-4 p-3">
                  <img
                    className="img-fluid"
                    src={require("../images/natura/client7.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-2 col-4 p-3">
                  <img
                    className="img-fluid"
                    src={require("../images/natura/client1.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-2 col-4 p-3">
                  <img
                    className="img-fluid"
                    src={require("../images/natura/client2.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-2 col-4 p-3">
                  <img
                    className="img-fluid"
                    src={require("../images/natura/client9.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-2 col-4 p-3">
                  <img
                    className="img-fluid"
                    src={require("../images/natura/client4.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-2 col-4 p-3">
                  <img
                    className="img-fluid"
                    src={require("../images/natura/client9.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-2 col-4 p-3">
                  <img
                    className="img-fluid"
                    src={require("../images/natura/client6.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-2 col-4 p-3">
                  <img
                    className="img-fluid"
                    src={require("../images/natura/client7.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-2 col-4 p-3">
                  <img
                    className="img-fluid"
                    src={require("../images/natura/client8.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-2 col-4 p-3">
                  <img
                    className="img-fluid"
                    src={require("../images/natura/client5.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-2 col-4 p-3">
                  <img
                    className="img-fluid"
                    src={require("../images/natura/client10.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* achievements--- */}
      {/* Our Achivement------- */}
      <section id="counts" class="counts py-3 ">
        <div class="container">
          <div class="row align-items-center  counters">
            <div class="col-lg-6">
              <div>
                <h3 class="text-white">
                  <b>Our Achievements</b>
                </h3>
                <p>
                  Our big debut into aerial rigging happened when we made
                  Priyanka Chopra fly on stage at the Nokia New Year’s party in
                  2006. We ended up rigging many such similar programmes,
                  leading up to the first edition of Dance India Dance in 2009,
                  where we worked closely with Terence Lewis on his different
                  aerial segments – what began as an experiment in 2006 led us
                  to our most lucrative division both in terms of economics as
                  well as job satisfaction. Thus Natura became India’s first and
                  only professional rigging company!
                </p>
              </div>
            </div>
            <div class="col-lg-6 py-5">
              <div class="row py-0">
                <div class="col-lg-6 col-6 p-1 text-center ">
                  {/* <!-- <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="0" class="purecounter">232</span> --> */}
                  <div
                    class="p-3  rounded"
                    style={{ height: "210px", backgroundColor: "#23297A" }}
                  >
                    <p class="mb-0 " style={{ fontSize: "1rem" }}>
                      <i class="fas fa-pencil-ruler text-white rounded-circle  border  border-warning p-3"></i>
                    </p>
                    <span>
                      <NumberScroller from={100} to={1500} timeout={1000} />
                    </span>
                    &nbsp;<span>+</span>
                    {/* <span class="numscroller roller-title-number-1 scrollzip isShown" data-min="1" data-max="400" data-delay="5" data-increment="10" data-slno="1">400</span>&nbsp;<span>+</span> */}
                    <p className="text-white">Clients</p>
                  </div>
                </div>
                <div class="col-lg-6 col-6 p-1 text-center">
                  {/* <!-- <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="0" class="purecounter">232</span> --> */}
                  <div
                    class="p-3  rounded "
                    style={{ height: "210px", backgroundColor: "#23297A" }}
                  >
                    <p class="mb-0 " style={{ fontSize: "1rem" }}>
                      <i class="fas fa-project-diagram text-white rounded-circle  border border-warning p-3"></i>
                    </p>
                    <span>
                      <NumberScroller from={0} to={15} timeout={1000} />
                    </span>
                    &nbsp;<span>+</span>
                    {/* <span class="numscroller roller-title-number-2 scrollzip isShown" data-min="1" data-max="34" data-delay="5" data-increment="10" data-slno="2">34</span>&nbsp;<span>+</span> */}
                    <p className="text-white">Years Of Experience</p>
                  </div>
                </div>
                <div class="col-lg-6 col-6 p-1 text-center">
                  {/* <!-- <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="0" class="purecounter">232</span> --> */}
                  <div
                    class="p-3  rounded"
                    style={{ height: "210px", backgroundColor: "#23297A" }}
                  >
                    <p class="mb-0 " style={{ fontSize: "1rem" }}>
                      <i class="fas fa-smile text-white rounded-circle  border border-warning p-3"></i>
                    </p>
                    <span>
                      <NumberScroller
                        from={0}
                        to={0}
                        decimalPlaces={2}
                        timeout={1000}
                      />
                    </span>
                    &nbsp;<span></span>
                    {/* <span class="numscroller roller-title-number-3 scrollzip isShown" data-min="1" data-max="99.9" data-delay="5" data-increment="10" data-slno="3">99</span>&nbsp;<span>%</span> */}
                    <p className="text-white">Zero Mishaps</p>
                  </div>
                </div>
                <div class="col-lg-6 col-6 p-1 text-center">
                  {/* <!-- <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="0" class="purecounter">232</span> --> */}
                  <div
                    class="p-3  rounded"
                    style={{ height: "210px", backgroundColor: "#23297A" }}
                  >
                    <p class="mb-0 " style={{ fontSize: "1rem" }}>
                      <i class="fas fa-box text-white rounded-circle  border  border-warning p-3"></i>
                    </p>
                    <span>
                      <NumberScroller from={0} to={750} timeout={1000} />
                    </span>
                    &nbsp;<span>+</span>
                    {/* <span class="numscroller roller-title-number-4 scrollzip isShown" data-min="1" data-max="15" data-delay="5" data-increment="10" data-slno="4">15</span>&nbsp;<span>+</span> */}
                    <p className="text-white">Products </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our Achivement------- */}
      {/* achievements--- */}

      {/* <Faqsection /> */}

      {/* FAQ Section */}

      {/* Our Team */}
      <section className="team  py-5" style={{ backgroundColor: "#414142" }}>
        <div className="container">
          <div
            className="row justify-content-between align-items-center py-2"
            style={{ overflow: "hidden" }}
          >
            <div className="col-lg-6 col-md-6 col-6">
              <div className="text-start">
                <h2 style={{ color: "#FEBD15" }}>
                  <b>PEOPLE WHO MAKE IT POSSIBLE</b>
                </h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <div className="text-end">
                <Link to="/">
                  {" "}
                  <img
                    className="heade_logo px-5"
                    height="60px"
                    src={require("../images/client/logohori.png")}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
          <hr style={{ backgroundColor: "#e6e6dc", height: "10px" }} />
          <div className="row">
            <div
              className="col-lg-12 col-md-12 p-3 rounded colorSection"
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            >
              <img
                src={require("../images/team/aviskar.jpg")}
                className="img-fluid float-start rounded"
                alt=""
                style={{
                  marginRight: "30px",
                  marginBottom: "10px",
                  width: "25%",
                }}
              />

              <div className="" style={{ textAlign: "justify" }}>
                <h3>
                  <b>Avishkar Tendle – Managing Director</b>
                </h3>
                <p>
                  A high-altitude Himalayan trek at the age of 10 was Avishkar’s
                  first step into a life of adventure. Today, he is a
                  well-respected Outdoor Educator and trained professional with
                  more than 20 years of experience in Outdoor Sports and
                  Adventure Travel, and an intuitive grasp of how outdoor and
                  adventure activities can engage with and affect the human
                  psyche.
                  <br />
                  Forgoing a traditional education, Avishkar instead ensured
                  that his passions turned into usable skill sets. He now holds
                  qualifications in Himalayan Glacier Mountaineering from the
                  National Outdoor Leadership School, USA; Mountaineering from
                  the Directorate of Mountaineering and Adventure Sports,
                  Manali; multiple specialized courses such as Rock Climbing,
                  Solo Paragliding, and Ornithology; and is a certified
                  Wilderness First Responder from Wilderness Medicine
                  Insititute, USA. He has also taught at the prestigious
                  Voyageur Outward Bound School in Montana, USA – an
                  international organization for Outdoor & Experiential
                  Education.
                  <br />
                  At Natura, Avishkar has taken his expertise with
                  mountaineering equipment and techniques and adapted them to
                  meet the needs of the live events and entertainment industry,
                  among others. As a consequence of his professional training
                  and expertise, Avishkar has a deep understanding of the need
                  for safety-inherent procedures and is a strong proponent of
                  stronger safety standards in the industry.
                  <br />
                </p>
                <p>
                  As Managing Director at Natura, he is the driving force behind
                  our approach to all things aerial and a respected voice
                  calling for greater safety in a still unorganized industry.
                </p>
              </div>
            </div>
          </div>

          <div className="row my-5">
            <div
              className="col-lg-12 col-md-12 p-3 rounded colorSection"
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            >
              <img
                src={require("../images/team/maria.jpg")}
                className="img-fluid float-start rounded"
                alt=""
                style={{
                  marginRight: "30px",
                  marginBottom: "10px",
                  width: "25%",
                }}
              />

              <div className="" style={{ textAlign: "justify" }}>
                <h3>
                  <b>Maria Julia- Director</b>
                </h3>
                <p>
                  As a Director, besides streamlining its business operations
                  and facilitating strong business relations with an eye to
                  growth, Maria oversees Natura’s performance and its adherence
                  to quality standards – in a word, she is Natura’s conscience!
                  <br />
                  Already playing the role of Head of Business Development at
                  the Terence Lewis Contemporary Dance Company and having
                  effortlessly turned something as ethereal as dance into a
                  well-oiled business and creative brand, the esoteric pursuit
                  of turning aerial rigging into a thriving business is
                  completely Maria’s forte. Her knowledge of the challenges and
                  processes in an unorganized industry, and her rapport with the
                  entertainment and events world are just some of the things
                  that make Maria an immense asset to Natura.
                  <br />
                </p>
              </div>
            </div>
          </div>

          <div className="row my-5">
            <div
              className="col-lg-12 col-md-12 p-3 rounded colorSection"
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            >
              <img
                src={require("../images/team/rohit.jpg")}
                className="img-fluid float-start rounded"
                alt=""
                style={{
                  marginRight: "30px",
                  marginBottom: "10px",
                  width: "25%",
                }}
              />

              <div className="" style={{ textAlign: "justify" }}>
                <h3>
                  <b>Rohit Pote- Project Manager</b>
                </h3>
                <p>
                  With close to a decade in the field of Adventure and Outdoor
                  Education, Rohit has managed to create for himself a strong
                  niche as flawless adventure activity support for outbound
                  training programmes, thanks to an enviable reputation among
                  senior facilitators and trainers for managing and executing
                  the operations they design. As Project Manager at Natura, he
                  is responsible for managing all aspects of Natura’s rigging
                  projects <br />
                </p>
                <p>
                  Also, a Wilderness Advanced First Aider, certified by the
                  Wilderness Medicine Institute, USA. Rohit is a pure
                  outdoorsman at heart and has rock-climbed and trekked
                  extensively throughout the Sahyadris. <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* top nevents-- */}
      {/* <section id="why-us" class="why-us events py-5" >
        <div class="container">

          <div class="d-flex ">
            <div class="section-title text-start col-lg-6 text-muted">
              <h2 className="text-dark">Our Top<span> Events</span></h2>
              <p className='text-white'>We have been a part of numerous amazing events on Earth and enhanced their entertainment value and excitement for our clients.</p>
            </div>
          </div>

          <div class="row">

            <div class="col-md-4 mt-4 mt-lg-0">
              <div class="card-wrapper">
                <figure class="card border-0" style={{ backgroundColor: '#FEBD15' }}>
                  <img src={require('../images/natura/events1.jpg')} className="img-fluid w-100" />
                  <figcaption><a href="#" className="text-white">Flying Superstars by Natura at IIFA Utsavam →</a> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-md-4 mt-4 mt-lg-0">
              <div class="card-wrapper">
                <figure class="card border-0" style={{ backgroundColor: '#FEBD15' }}>
                  <img src={require('../images/natura/events2.jpg')} className="img-fluid w-100" />
                  <figcaption><a href="#" className="text-white">Natura delights with Aerial Trophy at the 3rd Pro Kabaddi OC →</a> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-md-4 mt-4 mt-lg-0">
              <div class="card-wrapper">
                <figure class="card border-0" style={{ backgroundColor: '#FEBD15' }}>
                  <img src={require('../images/natura/events3.jpg')} className="img-fluid w-100" />
                  <figcaption><a href="#" className="text-white">Flying FX Rigging at South Asian Games 2016 Opening Ceremony →</a> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-md-4 mt-4 mt-lg-0">
              <div class="card-wrapper">
                <figure class="card border-0" style={{ backgroundColor: '#FEBD15' }}>
                  <img src={require('../images/natura/events4.jpg')} className="img-fluid w-100" />
                  <figcaption><a href="#" className="text-white">Flying FX Rigging for Shraddha Kapoor at Lakme Event in Mumbai →</a> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-md-4 mt-4 mt-lg-0">
              <div class="card-wrapper">
                <figure class="card border-0" style={{ backgroundColor: '#FEBD15' }}>
                  <img src={require('../images/natura/events5.jpg')} className="img-fluid w-100" />
                  <figcaption><a href="#" className="text-white">Aerial Act ‘Wonders on Silk’ for Reliance Jio Launch →</a> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-md-4 mt-4 mt-lg-0">
              <div class="card-wrapper">
                <figure class="card border-0" style={{ backgroundColor: '#FEBD15' }}>
                  <img src={require('../images/natura/events6.jpg')} className="img-fluid w-100" />
                  <figcaption><a href="#" className="text-white">Outbound Training Programme with V.I.P. Leadership Team →</a> </figcaption>
                </figure>
              </div>
            </div>


          </div>

        </div>
      </section> */}
      {/* top nevents-- */}

      <Footer />
    </div>
  );
};
