import React from "react";
import { Link } from "react-router-dom";

const SafetyCardEvent = ({ event }) => {
  // const localurl = "https://natura.co.in"
  const localurl = "https://natura.co.in";
  return (
    <>
      <Link to={`/singleevent/${event._id}`}>
        <section>
          <div class="blog-card text-start">
            <div class="meta">
              <div
                class="photo"
                style={{
                  backgroundImage: `url(${localurl}/images/events/${event.EventBanner})`,
                }}
              ></div>
            </div>
            <div class="description">
              <h1 className='text-black'>{event.EventName}</h1>
              <h2>Event</h2>
              <p className='text-black'> {event.EventShortDescription}</p>
              <p class="read-more" >
                <Link to={`/singleevent/${event._id}`}>Read More</Link>
              </p>
            </div>
          </div>
        </section>
      </Link>
    </>
  );
};

export default SafetyCardEvent;
