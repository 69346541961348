import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";

const ServiceSection = () => {
  const [category, setcategory] = useState();
  const [del, setdel] = useState(false);

  useEffect(() => {
    getcategory();
  }, [!del]);

  useEffect(() => {});

  const getcategory = async () => {
    try {
      const { data } = await axiosInstance.get("/api/category");
      setcategory(data);
    } catch (error) {}
  };

  return (
    <>
      {/* Our Services */}
      <section
        id="why-us"
        class="why-us py-4"
        style={{ backgroundColor: "#414142" }}
      >
        <div class="container">
          <div class="d-flex">
            <div class="section-title text-start col-lg-6 text-muted py-1">
              <h2>
                <p style={{ color: "#fff", display: "inline-block" }}>Our</p>{" "}
                <span> Services</span>
              </h2>
              <p className="text-white">
                Natura is India’s first and only professional aerial rigging
                company, a pioneer in taking aerial acts and performances into
                mainstream entertainment.
              </p>
            </div>
          </div>

          {category && category.length !== 0 && (
            <div class="row justify-content-center">
              {category.map((p, index) => (
                <>
                  <ServiceCard p={p} key={index} />
                </>
              ))}
            </div>
          )}
        </div>
      </section>
      {/* Our Services */}
    </>
  );
};

const ServiceCard = ({ p }) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const styles = {
    newbox: {
      // backgroundImage:!isHover ? `url(https://natura.co.in/images/product/${p.CategoryImage})` : `linear-gradient(rgba(255, 17, 17, 0.6), rgba(255, 17, 17, 0.7)), url(https://natura.co.in/images/product/${p.CategoryImage})`,
      backgroundImage: !isHover
        ? `linear-gradient(rgba(254, 189, 21, 0.1), rgba(254, 189, 21, 0.2)), url(${
            process.env.REACT_APP_IMGURL
          }/images/product/${
            p?.CategoryCardImage ? p?.CategoryCardImage : p?.CategoryImage
          })`
        : `linear-gradient(rgba(254, 189, 21, 0.6), rgba(254, 189, 21, 0.6)), url(${
            process.env.REACT_APP_IMGURL
          }/images/product/${
            p?.CategoryCardImage ? p?.CategoryCardImage : p?.CategoryImage
          })`,
    },
  };

  return (
    <>
      <div class="col-lg-4 mt-4 mt-lg-0 py-lg-4">
        <div
          class="box mybox4 text-start"
          style={styles.newbox}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <h4>{p?.categoryName} </h4>
          {p.shortDistractions.length > 150 ? (
            <p className="pb-4 mb-4">{p?.shortDistractions.slice(0, 150)}...</p>
          ) : (
            <>
              <p className="pb-4 mb-4">{p?.shortDistractions}</p>
            </>
          )}

          <Link
            to={`/service/${
              p?.categoryName === "Rig Tech" ? "rig_tech" : p?.categoryName}`}
            className="btn text-white px-4 py-2"
            style={{
              backgroundColor: "#FEBD15",
              position: "absolute",
              bottom: "50px",
            }}
          >
            View More
          </Link>
        </div>
      </div>
    </>
  );
};

export default ServiceSection;
