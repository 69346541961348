import "./testimonial.css";
import MainTestimonial from "../maintestMonial/MainTestimonial";
import ImageTestimonial from "../maintestMonial/ImageTestimonial";

const ProductCarousal = ({ Data }) => {
  return (
    <>
      <MainTestimonial
        testimonial={Data}
        Component={ImageTestimonial}
        phoneitems={3}
      />
    </>
  );
};

export default ProductCarousal;
