// import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Home.css";
import "animate.css";
import { Header } from "./Header";
import Helmet from "react-helmet";
// import flyingfxixon from "../images/natura/flyingfxicon.svg";
import NumberScroller from "react-number-scroller";
import { Footer } from "./Footer";
import { axiosInstance } from "../config";
import { Link, useNavigate } from "react-router-dom";
import ServiceSection from "../components/serviesection/ServiceSection";
import Card from "../components/cardcomponent/Card";
import TestimoniaCarousal from "../components/testimonalcarousal/TestimoniaCarousal";
import Eventbanner from "../components/Eventbanner/Eventbanner";
import HeaderNew from "./HeaderNew";
// import video from "../video/naturavideo.mp4"


export const Landing = () => {

  const [showmenu, setshowmenu] = useState(false);
  const navigate = useNavigate();


  const [service, setservice] = useState();
  const [Events, setEvent] = useState();

  useEffect(() => {
    getservice();
    getEvent();
    return () => {
      setEvent("");
    };
  }, []);

  const getservice = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/service/`);
      setservice(data);
    } catch (error) {
      // alert(error.response.data)
    }
  };

  const getEvent = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/event/`);
      setEvent(data.filter((e) => e.FeaturedEvent === true).slice(0, 3));
    } catch (error) {
      // alert(error.response.data)
    }
  };

  return (
    <>
      <div style={{ position: "relative", overflow: "hidden" }}>
        <Helmet>
          <title>Natura</title>
          <meta name="keywords" content="Natura" />
          <meta name="description" content="Natura" />
        </Helmet>

        <div className="main">
          <div
            className={showmenu ? "main-container" : ""}
            onClick={() => setshowmenu(!showmenu)}
          />
          <div className="bg" style={{ backgroundImage: "rgba(0,0,0,0.8)" }}>
            {/* <!-- <Header Start============ --> */}
            {/* <Header /> */}
            <Header />


            {/* <!-- <Header End============= --> */}

            <section
              class="block oneB offticker"
              style={{ marginTop: "60px", position: "relative" }}
            >
              <video class="dVideo" autoPlay loop muted id="bgvid">
                <source
                  src="https://natura.co.in/video/naturavideo.mp4"
                  // src={video}
                  type="video/mp4"
                />
              </video>
            </section>
          </div>

          {/* Counter----- */}
          <section id="counts" className="countsold  py-lg-4 py-1">
            <div className="container">
              <div className="row counters">
                <div className="col-lg-3 col-6 text-center">
                  <span>
                    <NumberScroller from={0} to={16} timeout={3000} />
                  </span>
                  &nbsp;<span> yrs</span>
                  <p>Experience</p>
                </div>

                <div className="col-lg-3 col-6 text-center">
                  <span>
                    <NumberScroller from={400} to={800} timeout={2000} />
                  </span>
                  &nbsp;<span>+</span>
                  <p>Projects</p>
                </div>

                <div className="col-lg-3 col-6 text-center">
                  <span>
                    <NumberScroller from={1000} to={1500} timeout={2000} />
                  </span>
                  &nbsp;<span>+</span>
                  <p>Clients</p>
                </div>


                <div className="col-lg-3 col-6 text-center">
                  <p className="animate__animated animate__pulse animate__infinite	infinite Header_zero">
                    ZERO
                  </p>
                  <p style={{ color: "#000" }}>MISHAPS</p>
                </div>
                {/* <div className="col-lg-3 col-6 text-center text-dark  ">
                  <span style={{ color: "#000" }}>
                    <p
                      style={{ color: "#000", fontSize: "3rem" }}
                      className="animate__animated animate__pulse animate__infinite	infinite"
                    >
                      ZERO
                    </p>
                  </span>
                  
                  <p style={{ color: "#000" }}>MISHAPS</p>
                </div> */}
              </div>
            </div>
          </section>
          {/* Counter----- */}

          <ServiceSection />
          <Eventbanner />

          {/* top nevents-- */}
          {/* Our Services */}
          <section id="why-us" class="why-us py-4">
            <div
              class="container"
              style={{
                paddingBottom: "18px",
              }}
            >
              <div class="d-flex ">
                <div class="section-title text-start col-lg-6 text-muted">
                  <h2>
                    <p style={{ color: "#fff", display: "inline-block" }}>
                      {" "}
                      Featured{" "}
                    </p>{" "}
                    <span> Events</span>
                  </h2>
                  <p style={{ color: "white" }}>
                    We have been a part of numerous amazing events on Earth and
                    enhanced their entertainment value and excitement for our
                    clients.
                  </p>
                </div>
              </div>

              <div class="row">{Events && <Card event={Events} />}</div>

              {
                Events && <>
                  <div className="d-flex justify-content-center pt-3" >
                    <button className="cardbtn " onClick={() => navigate("/Featured/event")} style={{ width: "25%" }}>See All →</button>
                  </div>

                </>
              }



            </div>
          </section>
          {/* Our Services */}
          {/* top nevents-- */}

          {/* <div className="section3 d-flex justify-content-center align-items-center">
                <div className="text-center">
                    <h2>

                        INDIA'S #1

                    </h2>
                    <h2>

                        SPORTS
                        ENTERTAINMENT


                    </h2>
                    <h2>

                        AND
                        LIFESTYLE
                        COMPANY

                    </h2>
                    <h2>

                        INDIA'S #1

                    </h2>
                </div>
            </div> */}

          {/* about_video_banner */}
          <section className="fillstudio home_safety_banner py-5 ">
            <div className="container-fluid">
              <div className="row justify-content-center align-items-center  pt-5">
                <div className="col-md-4 ">
                  {/* <img src={homeBanner} class=" swing3" alt="" style={{marginTop: "-45px"}}/> */}
                  {/* <img src="/static/media/hang.ab7b4b41a941f4afd1e3.png" class=" swing3" alt="" style={{marginTop: "-45px"}}/> */}
                </div>
                <div className="col-md-8">
                  <div className="upper_head">
                    <h2>
                      <b className="text-white">WHY</b>{" "}
                      <b style={{ color: "#FEBD15" }}>NATURA</b>
                    </h2>
                  </div>
                  <div className="lower_head">
                    <div className="row justify-content-center align-items-start">
                      <div className="col-md-4">
                        <div
                          className=" text-start blacktext1"
                          style={{ position: "relative" }}
                        >
                          <h4 className="text-white">Safety</h4>
                          <p style={{ color: "rgb(254,189,21,0.6)" }}>
                            We operate under stringent standard operating
                            procedures (SOPs), in accordance with the guidelines
                            of International safety standards, the latest
                            equipment, and a constantly evolving risk analysis
                            process.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className=" text-start">
                          <h4 className="text-white">We Are Certified</h4>
                          <p style={{ color: "rgb(254,189,21,0.6)" }}>
                            <ul className="px-0">
                              <li>
                                {" "}
                                Certification from National Outdoor Leadership School (NOLS USA)
                              </li>
                              <li>
                                {" "}
                                Certification from Industrial Rope Access Trade Association (IRATA)
                              </li>
                              <li>
                                {" "}
                                Rigging Masterclass Certification by Event Equipment & Service Association (EESA)
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className=" text-start">
                          <h4 className="text-white">0 Mishaps</h4>
                          <p style={{ color: "rgb(254,189,21,0.6)" }}>
                            With more than 15 Years in the Industry, we have
                            witnessed Zero Mishaps to date which shows the
                            dedication of the Team and the safety measures that
                            we follow.
                          </p>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row justify-content-center align-items-start">
                      <Link to="/about-natura" className="col-md-12 ">
                        <button
                          className="cardbtn "
                          style={{
                            width: "100%",
                          }}
                        >
                          Read more →
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          </section>
        </div>
      </div>
      <section className="colorSection py-4">
        <div className="text-center pb-4 ">
          <div class="d-flex ">
            <div class="section-title text-center col-lg-12 col-12 text-muted">
              <h2 className="text-dark ">
                Client<span> Testimonials</span>
              </h2>
            </div>
          </div>
        </div>

        <TestimoniaCarousal />
      </section>

      <Footer />
    </>
  );
};
