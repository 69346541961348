import React from 'react'

const AdminLoader = () => {
    return (
        <>
            {/* <div className='row d-flex justify-content-center position-relative mt-5 ' style={{ height: "70vh" }}>
                <div className="col-md-8 text center position-absolute top-50 ">
                    <div class="spinner-border text-dark " role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div> */}
            <center>
            <div class="spinner-border text-dark " role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
            </center>

        </>
    )
}

export default AdminLoader