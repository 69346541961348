import React from 'react'
import parse from "html-react-parser";
const FaqComponents = ({ allquestion, isParse }) => {
    return (
        <>
            <section style={{ backgroundColor: "rgb(204, 204, 204)" }}>
                {/* <Faqsection /> */}

                <section className='py-5'  >
                    <div className="container">

                        <div className="row pt-2">
                            <div className="col-md-12 faq ">
                                <div class="accordion accordion-flush " id="faqlist" >
                                    {
                                        allquestion.map((p, index) => (
                                            <>
                                                <div class="accordion-item"><h3 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#faq-content-${p.id}`} aria-expanded="false">
                                                        <span class="num">{index + 1}.</span> {p.question}
                                                    </button>
                                                </h3>
                                                    <div id={`faq-content-${p.id}`} class="accordion-collapse collapse" data-bs-parent="#faqlist" >
                                                        <div class="accordion-body"> {isParse ? parse(p.answer) : p.answer}</div>
                                                        {/* <div class="accordion-body"> { p.answer}</div> */}
                                                    </div>
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>



                        </div>
                    </div>
                </section>



            </section>
        </>
    )
}

export default FaqComponents