export const RIG_TECH_DATA = [
  {
    productName: "Broadweigh 2.4 ghz Wireless Load Monitoring",
    url: "broadweigh-2-4-ghz-wireless-load-monitoring",
    description: `<p> Broadweigh’s 2.4 GHz wireless system is quick to deploy and expandable. Delivers data to a choice of receivers and works in conjunction with intelligent software to log and view up to 100 channels simultaneously. Add in sensors or receivers at any time – handheld displays, base station, warning beacons.</p> <p> High integrity, error free transmission | 800 m (1/2 mile) range <br> Up to 200 samples per second | 100+ channels | Industry trusted system</p>`,
    cart_description: ` Broadweigh’s 2.4 GHz wireless system is quick to deploy and expandable. Delivers data to a choice of receivers and works in conjunction with intelligent software to log and view up to 100 channels simultaneously. Add in sensors or receivers at any time – handheld displays, base station, warning beacons...`,
    button_name: "KNOW MORE",
    button_link:
      "https://api.whatsapp.com/send?phone=918097259195&text=Hello%2C%20I%20would%20like%20to%20know%20more%20about%20the%20Broadweigh%202.4%20ghz%20Wireless%20Load%20Monitoring%20system%20you%20offer",
    banner_image:
      "https://natura.co.in/images/gallery/1711300563528_BW%20Wireless.png",
    product_Catalog_images: [
      // "https://natura.co.in/images/gallery/1711300563528_BW%20Wireless.png",
      "https://natura.co.in/images/gallery/1711300621186_Wireless Load Shackles.jpg",
      // "https://natura.co.in/images/gallery/1711300172927_BB-S325-DSC_4966-1-978x1024.jpg",
      "https://natura.co.in/images/gallery/1711300608282_Twistlink%20Load%20Shackle.jpg",
      "https://natura.co.in/images/gallery/1711300551305_Broadweigh-Wind-Speed-Sensor.jpg",
      "https://natura.co.in/images/gallery/1711300509228_Broadweigh-Dongle-Base-Station.jpg",
      "https://natura.co.in/images/gallery/1711300519163_Broadweigh-USB-Base-Station.jpg",
    ],
    isMulti_product: false,
  },
  {
    productName: "Broadweigh Bluetooth Wireless Load Monitoring",
    url: "broadweigh-bluetooth-wireless-load-monitoring",
    description: `<p>Our Bluetooth® system sends data straight to your iOS or Android device, bringing an
    affordable wireless load monitoring to smaller scale rigging applications. Compared to the original Broadweigh® it delivers:</p> <p> Simpler System: Sending (multi-point real-time) data straight to your smart device Shorter Range: Transmits up to 90m (compared to 800m) </p> <p>Fewer Connections: A maximum of 12 load shackles (compared to 100) </p> <p>Crucially, with no loss of accuracy, build quality or battery life</p>`,
    cart_description: `Our Bluetooth® system sends data straight to your iOS or Android device, bringing an
    affordable wireless load monitoring to smaller scale rigging applications. Compared to the original Broadweigh® it delivers...`,
    button_name: "KNOW MORE",
    button_link:
      "https://api.whatsapp.com/send?phone=918097259195&text=Hello%2C%20I%20would%20like%20to%20know%20more%20about%20the%20Broadweigh%20Bluetooth%20Wireless%20Load%20Monitoring%20system%20you%20offer",
    banner_image:
      "https://natura.co.in/images/gallery/1711300202408_DSC_5287-1-1120x1536.jpg",
    product_Catalog_images: [
      // "https://natura.co.in/images/gallery/1711300202408_DSC_5287-1-1120x1536.jpg",
      "https://natura.co.in/images/gallery/1711300172927_BB-S325-DSC_4966-1-978x1024.jpg",
      "https://natura.co.in/images/gallery/1711300209187_iphone_app_1000.jpg",
      "https://natura.co.in/images/gallery/1711300187008_BB-S325-MICRO-DSC_7834-1-scaled.jpg",
    ],
    isMulti_product: false,
  },
  {
    productName: "Eventions Stage Automation Equipment",
    url: "eventions-stage-automation-equipment",
    description: `Eventions Products BV is constantly innovating and investing in the best equipment and
    technology available. Our 24-7 support network is at your service. We have experienced
    personnel, qualified to manage your project as required and where required. We assure you of
    our complete commitment to the highest standards to help make your production the most
    successful and enjoyable event possible.`,
    cart_description: `Eventions Products BV is constantly innovating and investing in the best equipment and technology available. Our 24-7 support network is at your service. We have experienced personnel, qualified to manage your project as required and where required....`,
    button_name: "KNOW MORE",
    banner_image:
      "https://natura.co.in/images/gallery/1711300663676_project_1.jpg",
    product_Catalog_images: [],
    isMulti_product: true,
    products: [
      {
        name: `E-Gear Winch 125/2`,
        description: `<p>The Winch 125/2, developed by Eventions Products BV, is a lightweight
      cable winch and a member of the series motion family. The winch is
      mounted within a standard 40x40 truss section, delivers 1x 40 meters or
      2x 20 meters of line travel at a speed of 2 meters per second, with a
      capacity up to 125 KG. The winch is suitable for large variety in- and
      outdoor events and easy to install and operate. It’s perfectly designed for
      touring productions, entertainment, corporate events, presentations and
      theatre.</p>`,

        image:
          "https://natura.co.in/images/gallery/1711300670499_project_3.jpg",
        button_name: "KNOW MORE",
        button_link:
          "https://api.whatsapp.com/send?phone=918097259195&text=Hello%2C%20I%20would%20like%20to%20know%20more%20about%20the%20Eventions%20Stage%20Automation%20-%20E-Gear%20Winch%20125%2F2%20you%20offer",
      },
      {
        name: `E-Gear Trolley 10/2.5`,
        description: `<p>The HD Trolley 10/2.5 is a heavy duty track system and a member of the series motion family.
         The track can be mounted to various type of trusses. The trolley is controlled by our unique e-motion software and is positionable in 0.1 mm. 
         The trolley has a SWL of 1000 kg and runs with a maximum speed of 2.5 m/s. 
         The track system is suitable for large variety in- and outdoor events and easy to install and operate.
          It’s perfectly designed for touring productions, entertainment, corporate events, presentations and theatre.  </p>
          <p> HD Trolley is an easy to setup, reliable, fast and economic solution.</p>
      `,
        image:
          "https://natura.co.in/images/gallery/1711300678354_project_5.jpg",
        button_name: "KNOW MORE",
        button_link:
          "https://api.whatsapp.com/send?phone=918097259195&text=Hello%2C%20I%20would%20like%20to%20know%20more%20about%20the%20Eventions%20Stage%20Automation%20-%20E-Gear%20Trolley%2010%2F2.5%20you%",
      },
      {
        name: `E-Gear Rotator 1000/30`,
        description: `<p>The Rotator 1000/30, developed by Eventions Products BV, is a member
        of the series motion family. The rotator is controlled by our unique SIL-3
        variable speed ethercat driver.
        The rotator holds 1000 KG and rotates with a maximum speed of 30 rpm.
        The rotator is suitable for large variety in- and outdoor events and easy to
        install and operate.</p>
        <p> It’s perfectly designed for touring productions, entertainment, corporate
        events, presentations and theatre.</p>`,
        image:
          "https://natura.co.in/images/gallery/1711300692678_project_11.jpg",
        button_name: "KNOW MORE",
        button_link:
          "https://api.whatsapp.com/send?phone=918097259195&text=Hello%2C%20I%20would%20like%20to%20know%20more%20about%20the%20Eventions%20Stage%20Automation%20-%20E-Gear%20Rotator%201000%2F30%0A%20you%20offer",
      },
      {
        name: `E-Gear Hoist 500/30`,
        description: `<p>The Hoist 500/30, developed by Eventions Products BV, is the fastest
        BGV-C1 hoist in the industry and is a member of the series motion family.
        The hoist iscontrolled by our unique SIL-3 variable speed ethercat driver
        and lifts 500 KG with a maximum speed of 30 meters per minute. The
        hoist is suitable for large variety in- and outdoor events and easy to
        install and operate. It’s perfectly designed for touring productions,
        entertainment, corporate events, presentations and theatre.</p>
        `,
        image:
          "https://natura.co.in/images/gallery/1711300663676_project_1.jpg",
        button_name: "KNOW MORE",
        button_link:
          "https://api.whatsapp.com/send?phone=918097259195&text=Hello%2C%20I%20would%20like%20to%20know%20more%20about%20the%20Eventions%20Stage%20Automation%20-%20E-Gear%20Hoist%20500%2F30%20you%20offer",
      },
      {
        name: `E-Gear Desk 24`,
        description: ``,
        image:
          "https://natura.co.in/images/gallery/1711300685619_project_9.jpg",
        button_name: "KNOW MORE",
        button_link:
          "https://api.whatsapp.com/send?phone=918097259195&text=Hello%2C%20I%20would%20like%20to%20know%20more%20about%20the%20Eventions%20Stage%20Automation%20-%20E-Gear%20Desk%2024%20you%20offer",
      },
    ],
  },
];
