import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../config";
import Sidebar from "../common/sidebar/Sidebar";
import { Link } from "react-router-dom";
import {
  AiFillDelete,
  AiFillEdit,
  AiOutlineFileAdd,
  AiTwotoneDelete,
} from "react-icons/ai";
import AdminLoader from "../components/AdminLoader";

const AdminEvent = () => {
  const [event, setevent] = useState();
  const [del, setdel] = useState(false);
  const [modal, setmodal] = useState(false);
  const [modalImage, setmodalImage] = useState(false);

  const [imgurl, setimgurl] = useState([]);
  const [allimg, setallimg] = useState([]);
  const [eventimg, seteventimg] = useState();
  const [loader, setloader] = useState(false);

  //
  const [selectedFile, setSelectedFile] = useState();
  const [openCardImgmodel, setOpenCardImgmodel] = useState(false);
  const [SelectedCardImgData, setSelectedCardImgData] = useState();

  useEffect(() => {
    getevent();
  }, [!del]);

  const getevent = async () => {
    try {
      const { data } = await axiosInstance.get("/api/event");

      setevent(data);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const togglehandle = (e) => {
    e.persist();
    // var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };

  const deleteevent = async (id) => {
    var answer = window.confirm("Are you sure To delete?");
    if (answer) {
      const { data } = await axiosInstance.delete(`/api/event/${id}`, {
        headers: {
          token: localStorage.getItem("token"),
        },
      });

      if (data.success) {
        alert("deleted");

        setTimeout(() => {
          setdel(!del);
        }, 1000);
      }
    }
  };

  const [id, setid] = useState();
  const [linkpost, setlinkpost] = useState([]);
  const [linkname, setlinkname] = useState("");
  // const [dellink, setdellink] = useState(false)

  const Modalopen = (id, embded) => {
    // e.PreventDefault();
    // alert(`hello, ${id}`);
    setid(id);
    setlinkpost(embded);
    let modal = document.querySelector(".modal");
    modal.classList.add("show");
    modal.style.transition = "all 0.5s ease";
    modal.style.display = "block";
  };

  const closePopup = () => {
    let modal = document.querySelector(".modal");
    modal.classList.remove("show");
    modal.style.transition = "all 0.5s ease";
    modal.style.display = "none";
  };

  // Linj upload---
  const handleUserdataSubmit = async () => {
    try {
      const { data } = await axiosInstance.post(`/api/event/addlink/${id}`, {
        Url: linkname,
      });

      if (data.success) {
        alert(data.message);
        setdel(!del);
        setlinkname("");
        closePopup();
      }
      // setmodal(false)
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  // Linj upload---
  const deleteLink = async (links) => {
    var answer = window.confirm("Are you sure To delete?");
    if (answer) {
      await axiosInstance.post(`/api/event/removelink/${id}`, {
        Url: links,
      });
      setdel(!del);
      alert("deleted Successfully");
    }
    closePopup();
  };

  const handleModal = (item) => {
    setid(item._id);
    setmodal(true);
    setimgurl(item.EventEmbedimgUrl);
  };

  const handleImageModal = (item) => {
    setid(item._id);
    setmodalImage(true);
    setallimg(item.EventImageGallery);
  };

  // const handleProduct = (item) =>{
  //   setid(item._id)
  //   setmodalImage(true);
  //   setallimg(item.EventImageGallery)
  // }

  const handleimgUrlSubmit = async () => {
    try {
      const { data } = await axiosInstance.post(`/api/event/addimglink/${id}`, {
        Url: linkname,
      });
      if (data.success) {
        alert(data.message);
        setdel(!del);
        setlinkname("");

        setmodal(false);
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  // Linj upload---
  const deleteimgUrl = async (imgurl) => {
    var answer = window.confirm("Are you sure To delete?");
    if (answer) {
      const { data } = await axiosInstance.put(
        `/api/event/removeimglink/${id}`,
        {
          Url: imgurl,
        }
      );

      if (data.success) {
        alert(data.message);
        setdel(!del);
        setlinkname("");
        setmodal(false);
      }
    }
  };

  const handleimgSubmit = async (e) => {
    try {
      e.preventDefault();
      try {
        if (!eventimg) {
          return alert("Please Select Image ");
        } else {
          if (eventimg.size >= 1000000) {
            return alert("Image Size should be less Than 1MB");
          }
        }
        setloader(true);
        const productdata = new FormData();
        productdata.append("eventimg", eventimg);
        const { data } = await axiosInstance.post(
          `/api/event/addimg/${id}`,
          productdata,
          {
            headers: {
              token: localStorage.getItem("token"),
              "Content-Type": "multipart/form-data",
            },
            // headers: {
            //   "Content-Type": "multipart/form-data"
            // }
          }
        );
        //
        alert("Image Added Successfully");
        setloader(false);
        seteventimg();
        setmodalImage(false);
        setdel(!del);
      } catch (error) {
        alert("Something went wrong");

        setloader(false);
      }
    } catch (error) {}
  };

  const deleteimage = async (img) => {
    try {
      const answer = window.confirm("Are You Sure !");
      if (answer) {
        const { data } = await axiosInstance.put(
          `/api/event/removeimg/${id}/`,
          {
            name: img,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );
        setmodalImage(false);
        alert("deleted permanently");
      }
      setdel(!del);
    } catch (error) {}
    // alert(imgs)
    //
  };
  const deleteCardImage = async (id) => {
    var answer = window.confirm("Are you sure To delete?");
    if (answer) {
      await axiosInstance.post(`/api/event/removeCardImg/${id}`, {
        headers: {
          token: localStorage.getItem("token"),
        },
      });

      alert("deleted");

      setTimeout(() => {
        setdel(!del);
      }, 100);
    }
  };
  const OpenCardDataModal = (data) => {
    setSelectedCardImgData(data);
    setOpenCardImgmodel(true);
  };
  const CarateCardImage = async (e) => {
    e.preventDefault();

    try {
      if (selectedFile.size >= 1000000) {
        alert("File Size Should Less Than 1 Mb");

        return;
      }
      setloader(true);

      const formdata = new FormData();
      formdata.append("eventimg", selectedFile);

      const { data } = await axiosInstance.post(
        `/api/event/addCardImg/${SelectedCardImgData}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setloader(false);

      if (data.success) {
        setloader(false);
        setdel(!del);
        setSelectedCardImgData("");
        setOpenCardImgmodel(true);
        alert("Added Successfully");
      }
    } catch (error) {
      console.log("working this error", error);
      setloader(false);
      alert(error.response.data.message);
    }
  };

  return (
    <>
      {/*  */}
      {openCardImgmodel && (
        <>
          <div
            class="modal d-block"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ opacity: "1" }}
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Image (375 X 235 px) (1 mb size)
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => setOpenCardImgmodel(false)}
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div className="row">
                    <input
                      type="file"
                      class="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter file"
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                      required
                    />
                  </div>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                  <div></div>
                  <div>
                    {loader ? (
                      <>
                        <button type="button" class="btn btn-primary" disabled>
                          Loading
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={CarateCardImage}
                        >
                          Upload Image
                        </button>
                      </>
                    )}
                    {/* <button type="button" class="btn btn-primary" onClick={handleimgUrlSubmit} >upload link</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/*  */}

      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ opacity: "1" }}
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Social Media Url
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                onClick={closePopup}
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="row">
                {linkpost &&
                  linkpost.map((linkdata, i) => (
                    <>
                      <div className="col-lg-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder={linkdata}
                            disabled
                            readonly
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span
                            class="input-group-text btb btn-danger"
                            id="basic-addon2"
                            onClick={() => deleteLink(linkdata)}
                          >
                            <i class="fa-solid fa-trash text-white"></i>
                          </span>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-between">
              <div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="upload link"
                  value={linkname}
                  onChange={(e) => setlinkname(e.target.value)}
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={handleUserdataSubmit}
                >
                  upload link
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Image Url */}

      {modal && (
        <>
          <div
            class="modal d-block"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ opacity: "1" }}
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Image Url
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => setmodal(false)}
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div className="row">
                    {imgurl &&
                      imgurl.map((linkdata, i) => (
                        <>
                          <div className="col-lg-6">
                            <div class="input-group mb-3">
                              <input
                                type="text"
                                class="form-control"
                                placeholder={linkdata}
                                disabled
                                readonly
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <span
                                class="input-group-text btb btn-danger"
                                id="basic-addon2"
                                onClick={() => deleteimgUrl(linkdata)}
                              >
                                <i class="fa-solid fa-trash text-white"></i>
                              </span>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="upload link"
                      value={linkname}
                      onChange={(e) => setlinkname(e.target.value)}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={handleimgUrlSubmit}
                    >
                      upload link
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {modalImage && (
        <>
          <div
            class="modal d-block"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ opacity: "1" }}
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Image (1600 X 700 px) (1 mb size)
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => setmodalImage(false)}
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div className="row">
                    {allimg &&
                      allimg.map((img, i) => (
                        <>
                          <div className="col-lg-3">
                            <div class="input-group mb-3">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={`${process.env.REACT_APP_IMGURL}/images/events/${img}`}
                                  alt=""
                                  height={100}
                                  width={100}
                                />
                                <AiTwotoneDelete
                                  size={30}
                                  onClick={() => deleteimage(img)}
                                />
                              </div>

                              {/* <input type="text" class="form-control" placeholder={linkdata} disabled readonly aria-label="Recipient's username" aria-describedby="basic-addon2" />
                              <span class="input-group-text btb btn-danger" id="basic-addon2" onClick={() => deleteimgUrl(linkdata)} ><i class="fa-solid fa-trash text-white" ></i></span> */}
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                  <div className="row">
                    <div className="col-md-8">
                      <input
                        type="file"
                        class="form-control"
                        placeholder="upload Image"
                        onChange={(e) => seteventimg(e.target.files[0])}
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                    </div>
                    <div className="col-md-4">
                      {loader ? (
                        <button type="submit" class="btn btn-primary btn-sm">
                          Uploading Please Wait...
                        </button>
                      ) : (
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={handleimgSubmit}
                        >
                          Add Img
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Sidebar />
      <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
        <nav className="navbar top-navbar navbar-light bg-light px-5">
          <a className="btn border-0" id="menu-btn" onClick={togglehandle}>
            <i className="bx bx-menu"></i>
          </a>
        </nav>
        {/* <!--End Top Nav --> */}
        <h3 className="text-dark p-3">ADMIN EVENT DASHBOARD</h3>
        <div
          className="container rounded p-3"
          style={{ boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
        >
          <div className="row text-start mb-3">
            {/* <div className="col-md-9"></div> */}
            <div className=" ">
              <Link
                to="/admin/addevent"
                className="text-white btn btn-success rounded  px-5"
              >
                Add New Event
              </Link>
            </div>
          </div>
          <div className="row  mb-3 text-start">
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-2  text-center">
                  <b>Event Name</b>
                </div>
                <div className="col-md-2 text-center">
                  <b>Featured Event</b>
                </div>
                <div className="col-md-3 text-center">
                  <b>Event Image</b>
                </div>
                <div className="col-md-3 text-center">
                  <b>Event card Image</b>
                </div>
                <div className="col-md-2 text-center">
                  <b>Action</b>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="row text-center">
                {/* <div className="col-md-4 text-center"><b>Video Url</b></div>
                <div className="col-md-4 text-center"><b>Image Url</b></div> */}
                <div className="   text-center">
                  <b>Add Image</b>
                </div>
                {/* <div className="col-md-3   text-center"><b>Add Product</b></div> */}
              </div>
            </div>
          </div>

          <hr />
          {!event ? (
            <AdminLoader />
          ) : event.length === 0 ? (
            <>
              <div className="row d-flex justify-content-center mt-5">
                <div className="col-md-8 text-center">
                  <h4>No event Available</h4>
                </div>
              </div>
            </>
          ) : (
            event &&
            event.map((event) => (
              <>
                <div className="row text-start ">
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-2">{event.EventName}</div>
                      <div className="col-md-2  text-center">
                        {event.FeaturedEvent == true ? <b>Yes</b> : <b>No</b>}
                      </div>
                      <div className="col-md-3 text-center ">
                        <img
                          src={`${process.env.REACT_APP_IMGURL}/images/events/${event.EventBanner}`}
                          alt=""
                          height={80}
                          width={80}
                        />
                      </div>
                      <div className="col-md-3 text-center ">
                        {event.cardImage ? (
                          <>
                            <img
                              src={`${process.env.REACT_APP_IMGURL}/images/events/${event.cardImage}`}
                              alt=""
                              height={80}
                              width={80}
                            />
                            <AiTwotoneDelete
                              size={30}
                              onClick={() => deleteCardImage(event._id)}
                            />
                          </>
                        ) : (
                          <>
                            {" "}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignitems: "center",
                              }}
                            >
                              <AiOutlineFileAdd
                                className="text-center"
                                size={30}
                                onClick={() => OpenCardDataModal(event._id)}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-md-2 text-center justify-content-center d-flex">
                        <div className="row text-center justify-content-center d-flex">
                          {/* <div className="col-md-4">
                              <AiFillEye color='red' size={25} onClick={() => showdetails(event)} />
                            </div> */}
                          <div
                            className="col-md-4 text-center "
                            onClick={() => deleteevent(event._id)}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <AiFillDelete color="black" size={25} />
                          </div>
                          <div className="col-md-4 ">
                            <Link
                              className="col-md-1"
                              to={`/admin/editevent/${event._id}`}
                            >
                              <AiFillEdit size={25} color="blue" />{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 text-center">
                    <div className="row">
                      {/* <div className="col-md-4 text-center">
                          <button className="btn btn-success btn-sm" onClick={() => Modalopen(event._id, event.EventEmbedLink)}  >Add Url</button>
                        </div>
                        <div className="col-md-4 ">
                          <button className="btn btn-success btn-sm" onClick={() => handleModal(event)}  >Add Img Url</button>
                        </div> */}
                      <div className="justify-content-center d-flex ">
                        <button
                          className="btn btn-success btn-sm"
                          onClick={() => handleImageModal(event)}
                        >
                          Add Gallery Image
                        </button>
                      </div>
                      {/* <div className="col-md-3 text-center">
                          <button className="btn btn-success btn-sm" onClick={() => handleProduct(event)}  >Add Product </button>
                        </div> */}
                    </div>
                  </div>
                </div>
                <hr />
              </>
            ))
          )}
        </div>{" "}
      </div>
    </>
  );
};

export default AdminEvent;
