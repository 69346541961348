import React, { useState } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Link, useParams } from "react-router-dom";
import { axiosInstance } from "../config";
import { useEffect } from "react";
// import VideoContent from "../components/videocontentsection/VideoContent";
// import Card from "../components/cardcomponent/Card";
// import NewEvent from "../components/videocontentsection/NewEvent";
import { BsFillPersonFill } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import { GoCalendar } from "react-icons/go";
import { IoGameControllerOutline } from "react-icons/io5";
import { BiCategory } from "react-icons/bi";
import { AiTwotoneTag } from "react-icons/ai";
import parse from "html-react-parser";
import "./singleevent.css";
import MainLoader from "../components/mainloader/MainLoader";

const SingleEventNew = () => {
  // const [MyRelativeEvents, setMyRelativeEvents] = useState([]);
  const [event, setevent] = useState();
  const [loader, setloader] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    getevent();
    // getservice2();
  }, [id]);

  const getevent = async () => {
    try {
      setloader(true);
      const { data } = await axiosInstance.get(`/api/event/${id}`);

      setevent(data);

      setloader(false);
      // setBreadcrumbs(data.EventName);
    } catch (error) {
      setloader(false);

      // alert(error.response.data)
    }
  };

  console.log(`${process.env.REACT_APP_IMGURL}/images/events/${event?.EventBanner}`,"${process.env.REACT_APP_IMGURL}/images/events/${event?.EventBanner}")

  return (
    <>
      <Header />

      {loader ? (
        <MainLoader />
      ) : (
        <>
          {/* Starting New Design */}

          <section
            className="container-fluid"
            style={{
              height: "80vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${process.env.REACT_APP_IMGURL}/images/events/${event?.EventBanner})`,
              // backgroundImage: `url(http://natura.rankarts.in/images/events/1683618515877_DSC06808_300.jpg)`,
            }}
          >
            <div className="row align-items-center h-100 position-relative">
              <div
                className="col-md-5 col-lg-5  col-12   py-4 text-start position-absolute"
                style={{
                  fontSize: "3rem",
                  backgroundColor: "rgba(255, 193, 7, 0.7)",
                  bottom: "30px",
                }}
              >
                <h1 style={{ fontSize: "2rem", fontWeight: "500" }}>
                  <b className="text-white">{event?.EventName}</b>
                </h1>
              </div>
            </div>
          </section>

          {/* Section Two */}

          <section style={{ backgroundColor: "#212529" }}>
            <div className="container py-5 text-white text-start">
              <h5>{event?.EventShortDescription}</h5>
              <div className="row pt-3">
                <div className="col-md-6 col-12 align-self-center">
                  <p className="name-text">
                    <BsFillPersonFill color="#Fff" size={25} />{" "}
                    {event?.ClientName} | {event?.EndClientName}
                  </p>
                  <p className="name-text">
                    <GoCalendar color="#Fff" size={25} /> &nbsp;
                    {event?.DOE.split("-").reverse().join("/")} -{" "}
                    {event?.EndDOE.split("-").reverse().join("/")}
                  </p>
                  <p className="name-text">
                    <BiCategory color="#Fff" size={25} />
                    {event?.EventCategory.map((e, i) => (
                      <>
                        &nbsp;{" "}
                        <Link key={i} to={`/allevent/${e}`}>
                          <span style={{ fontSize: "14px", color: "#FCBC16" }}>
                            {" "}
                            {e}
                          </span>
                        </Link>{" "}
                        {i === event?.EventCategory.length - 1 ? "" : "|"}
                      </>
                    ))}
                  </p>
                  <p className="name-text">
                    <AiTwotoneTag color="#Fff" size={25} />
                    {event?.EventType.map((e, i) => (
                      <>
                        &nbsp;{" "}
                        <Link key={i} to={`/singleproject/${e._id}`}>
                          <span style={{ fontSize: "14px", color: "#FCBC16" }}>
                            {" "}
                            {e?.ProductTitle}
                          </span>
                        </Link>{" "}
                        {i === event?.EventType.length - 1 ? "" : "|"}
                      </>
                    ))}
                  </p>
                </div>
                <div className="col-md-6 col-12 text-lg-end align-self-center">
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-8">
                      <p className="name-text text-start">
                        <MdLocationPin color="#fff" size={25} />{" "}
                        {event?.VenuName} | {event?.City}
                      </p>
                      <p className="name-text text-start">
                        <IoGameControllerOutline color="#fff" size={25} />{" "}
                        &nbsp;
                        {event?.VenueType === "both" ? (
                          <>
                            <Link to={`/venue/Indoor`}>
                              <span
                                style={{ fontSize: "14px", color: "#FCBC16" }}
                              >
                                Indoor /
                              </span>
                            </Link> 
                            &nbsp;
                            <Link to={`/venue/Outdoor`}>
                              <span
                                style={{ fontSize: "14px", color: "#FCBC16" }}
                              >
                                Outdoor
                              </span>
                            </Link>
                          </>
                        ) : (
                          <Link to={`/venue/${event?.VenueType}`}>
                            <span
                              style={{ fontSize: "14px", color: "#FCBC16" }}
                            >
                              {event?.VenueType}{" "}
                            </span>
                          </Link>
                        )}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Blog Content */}

          <section className="pt-5" id="maincontent">
            <div className="container">
              {loader ? (
                <>
                  <div
                    className="row d-flex justify-content-center align-items-center"
                    style={{ height: "30vh" }}
                  >
                    <div className="col-md-8 text center  ">
                      <div class="spinner-border text-dark " role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row text-dark ">
                    <div className="col-md-12 text-dark blogs">
                      {parse(`${event?.content}`)}
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>

          {/* Gallery Section */}
          <section style={{ backgroundColor: "#212529" }} className="pb-5 pt-2">
            <div className="container">
              {event?.EventImageGallery?.length > 0 && (
                <>
                  <EventHeading heading={"Event Images"} />
                  <section class="gallery">
                    {event?.EventImageGallery?.map((e, i) => (
                      <EventImage key={i} imgurl={e} />
                    ))}
                  </section>
                  {/* <div className="row justify-content-between">
                    {event?.EventImageGallery?.map((e, i) => (
                      <EventImage key={i} imgurl={e} />
                    ))}
                  </div> */}
                </>
              )}

              {/* <EventHeading heading="Credits" /> */}
              <h4 style={{ color: "#fff", fontWeight: "900" }} className="pt-5">
                Credits
              </h4>
              <hr style={{ height: "3px", color: "#fff" }} />

              <div className="row">
                {event?.PhotoGrapherTeam && (
                  <CreditBox
                    heading="Natura Team"
                    content={event?.NaturaTeam}
                  />
                )}
                {event?.PhotoGrapherTeam && (
                  <CreditBox
                    heading="Photography Team"
                    content={event?.PhotoGrapherTeam}
                  />
                )}

                {event?.InfraTeam && (
                  <CreditBox
                    heading="Videography Team"
                    content={event?.InfraTeam}
                  />
                )}
                {event?.VideoGrapherTeam && (
                  <CreditBox
                    heading="Videography Team"
                    content={event?.VideoGrapherTeam}
                  />
                )}

                {/* {event?.ClientCredit && (
                  <CreditBox
                    heading="Client Credits"
                    content={event?.ClientCredit}
                  />
                )} */}
                {event?.OtherCredits && (
                  <CreditBox
                    heading="Other Credits"
                    content={event?.OtherCredits}
                  />
                )}
              </div>
              {/* 
          <EventHeading heading="Social Shares" />

          <div class="d-flex justify-content-between pt-2">
            <div ><img src="https://cdn.icon-icons.com/icons2/2429/PNG/512/youtube_logo_icon_147199.png" alt="" height={40} width={40} /></div>
            <div ><img src="https://cdn.worldvectorlogo.com/logos/linkedin-icon-2.svg" alt="" height={40} width={40} /></div>
            <div ><img src="https://cdn.worldvectorlogo.com/logos/linkedin-icon-2.svg" alt="" height={40} width={40} /></div>
            <div ><img src="https://cdn.icon-icons.com/icons2/2429/PNG/512/youtube_logo_icon_147199.png" alt="" height={40} width={40} /></div>
          </div> */}
            </div>
          </section>
        </>
      )}

      <Footer />
    </>
  );
};

// Components
const CreditBox = ({ heading, content }) => (
  <>
    <div className="col-lg-4 col-md-4 col-12 p-3 text-start d-flex align-items-stretch">
      <div className="creditbox p-3">
        <h6 className="info-text" style={{ fontSize: "17px" }}>
          {heading}
        </h6>
        <p className="name-text">{content}</p>
      </div>
    </div>
  </>
);

const EventHeading = ({ heading }) => (
  <>
    <h3 style={{ color: "#fff", fontWeight: "900" }} className="pt-5">
      {heading}
    </h3>
    <hr style={{ height: "3px", color: "#fff" }} />
  </>
);

// const EventImage = ({ imgurl }) => (
//   <a
//     href={`${process.env.REACT_APP_IMGURL}/images/events/${imgurl}`}
//     data-lightbox="homePortfolio"
//   >
//     <img
//       src={`${process.env.REACT_APP_IMGURL}/images/events/${imgurl}`}
//       // className="img-fluid"
//       style={
//         {
//           // width: "100%",
//           // height: "100%",
//         }
//       }
//       alt={imgurl}
//     />
//   </a>
// );

const EventImage = ({ imgurl }) => (
  <div className="single-image">
    <a
      href={`${process.env.REACT_APP_IMGURL}/images/events/${imgurl}`}
      data-lightbox="homePortfolio"
    >
      <img
        src={`${process.env.REACT_APP_IMGURL}/images/events/${imgurl}`}
        // className="img-fluid"
        style={
          {
            // width: "100%",
            // height: "100%",
          }
        }
        alt={imgurl}
      />
    </a>
  </div>
);

export default SingleEventNew;
