import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../config";
import Sidebar from "../common/sidebar/Sidebar";
import { Link } from "react-router-dom";
import {
  AiFillEye,
  AiOutlineFileAdd,
  AiFillDelete,
  AiFillEdit,
  AiTwotoneDelete,
} from "react-icons/ai";

import AdminLoader from "../components/AdminLoader";

const Category = () => {
  const [category, setcategory] = useState();
  const [SelectedCategoryId, setSelectedCategoryId] = useState({
    // id:"",
    // UploadTo
    // size
  });
  const [selectedFile, setSelectedFile] = useState();
  const [del, setdel] = useState(false);
  const [loader, setloader] = useState(false);
  const [modal, setmodal] = useState(false);

  useEffect(() => {
    getcategory();
  }, [!del]);

  const showdetails = () => {};

  const getcategory = async () => {
    try {
      const { data } = await axiosInstance.get("/api/category");
      setcategory(data);
    } catch (error) {}
  };

  const togglehandle = (e) => {
    e.persist();
    var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };

  const deletecategory = async (id) => {
    var answer = window.confirm("Are you sure To delete?");
    if (answer) {
      await axiosInstance.delete(`/api/category/${id}`, {
        headers: {
          token: localStorage.getItem("token"),
        },
      });

      alert("deleted");

      setTimeout(() => {
        setdel(!del);
      }, 100);
    }
  };
  const openmodel = (data) => {
    setSelectedCategoryId(data);
    setmodal(true);
  };

  const handleVideo = async (e) => {
    e.preventDefault();

    if (!SelectedCategoryId?.UploadTo || !SelectedCategoryId?.id) {
      return alert("Please select");
    }
    try {
      if (selectedFile.size >= 1000000) {
        alert("File Size Should Less Than 1 Mb");

        return;
      }
      setloader(true);

      const formdata = new FormData();
      formdata.append("productimage", selectedFile);
      formdata.append("UploadTo", SelectedCategoryId?.UploadTo);

      const { data } = await axiosInstance.put(
        `/api/category/imageUploader/${SelectedCategoryId.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setloader(false);

      if (data.success) {
        setloader(false);
        setdel(!del);
        setmodal(false);
        alert("Added Successfully");
      }
    } catch (error) {
      setloader(false);
      alert(error.response.data.message);
    }
  };
  const deleteImage = async (id,UploadTo) => {
    var answer = window.confirm("Are you sure To delete?");
    if (answer) {
      await axiosInstance.post(
        `/api/category/delete/image/${id}`,
        {
          UploadTo:UploadTo
        },
        {
          headers: {
            token: localStorage.getItem("token"),
          },
        }
      );

      alert("deleted");

      setTimeout(() => {
        setdel(!del);
      }, 100);
    }
  };

  const handleDeleteVideo = async (id, name) => {
    try {
      let success = window.confirm("Are you sure you want to delete");

      if (success) {
        const { data } = await axiosInstance.put(
          `/api/category/delete/video/${id}`,
          {
            VideoType: name,
          }
        );

        if (data.success) {
          alert("Video deleted successfully");
        }
        setTimeout(() => {
          setdel(!del);
        }, 100);
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  return (
    <>
      <Sidebar />
      {modal && (
        <>
          <div
            class="modal d-block"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ opacity: "1" }}
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {/* Image (1600 X 700 px) (1 mb size) */}
                    {SelectedCategoryId?.size ?? "Image Upload"}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => setmodal(false)}
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div className="row">
                    <input
                      type="file"
                      class="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter file"
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                      required
                    />
                  </div>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                  <div></div>
                  <div>
                    {loader ? (
                      <>
                        <button type="button" class="btn btn-primary" disabled>
                          Loading
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={handleVideo}
                        >
                          Upload Image
                        </button>
                      </>
                    )}
                    {/* <button type="button" class="btn btn-primary" onClick={handleimgUrlSubmit} >upload link</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
        <nav className="navbar top-navbar navbar-light bg-light px-5">
          <a className="btn border-0" id="menu-btn" onClick={togglehandle}>
            <i className="bx bx-menu"></i>
          </a>
        </nav>
        {/* <!--End Top Nav --> */}
        <h3 className="text-dark p-3">ADMIN SERVICE DASHBOARD</h3>

        <div
          className="p-3"
          style={{
            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
            zoom: "70%",
          }}
        >
          <div className="row text-start">
            <div className="">
              <Link
                to="/admin/addservice"
                className="text-white btn btn-success rounded px-5"
              >
                Add New Sevice
              </Link>
            </div>
          </div>
          <div className="row mb-4 border-bottom text-center">
            <div className="col-md-1 ">
              <b>Service Name</b>
            </div>
            <div className="col-md-1 ">
              <b>Service Short Description</b>
            </div>
            <div className="col-md-1 ">
              <b>Service long Description</b>
            </div>
            <div className="col-md-2 ">
              <b>Banner Image</b>
            </div>
            <div className="col-md-2 ">
              <b>Home Card</b>
            </div>
            <div className="col-md-2 ">
              <b>NavBar DropDown Image</b>
            </div>
            <div className="col-md-2 ">
              <b>Service Video</b>
            </div>

            <div className="col-md-1 ">
              <b>EXTRA OPTIONS</b>
            </div>
          </div>

          {!category ? (
            <AdminLoader />
          ) : category.length === 0 ? (
            <>
              <div className="row d-flex justify-content-center mt-5">
                <div className="col-md-8 text-center">
                  <h4>No category Available</h4>
                </div>
              </div>
            </>
          ) : (
            category &&
            category.map((category) => (
              <>
                <div className="row text-start pb-2" key={category._id}>
                  <div className="col-md-1 ">{category?.categoryName}</div>
                  <div className="col-md-1 ">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea4"
                      rows="4"
                      col="5"
                      value={category?.shortDistractions}
                    ></textarea>
                  </div>

                  <div className="col-md-1 ">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea4"
                      rows="4"
                      col="5"
                      value={category?.CategoryDescription}
                    ></textarea>
                  </div>
                  <div className="col-md-2 ">
                    {category.CategoryImage ? (
                      <div className="d-flex justify-content-center flex-column align-items-center">
                        <img
                          src={`${process.env.REACT_APP_IMGURL}/images/product/${category.CategoryImage}`}
                          alt={category?.categoryName}
                          height={100}
                          width={100}
                        />

                        <AiTwotoneDelete
                          size={30}
                          onClick={() => deleteImage(category._id,"CategoryImage")}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignitems: "center",
                        }}
                      >
                        <AiOutlineFileAdd
                          className="text-center"
                          size={30}
                          onClick={() =>
                            openmodel({
                              id: category._id,
                              UploadTo: "CategoryImage",
                              size: "Category Image (1350 x 470)",
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-2 ">
                    {category.CategoryCardImage ? (
                      <div className="d-flex justify-content-center flex-column align-items-center">
                        <img
                          src={`${process.env.REACT_APP_IMGURL}/images/product/${category.CategoryCardImage}`}
                          alt={category?.CategoryCardImage}
                          height={100}
                          width={100}
                        />

                        <AiTwotoneDelete
                          size={30}
                          onClick={() => deleteImage(category._id,"CategoryCardImage")}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignitems: "center",
                        }}
                      >
                        <AiOutlineFileAdd
                          className="text-center"
                          size={30}
                          onClick={() =>
                            openmodel({
                              id: category._id,
                              UploadTo: "CategoryCardImage",
                              size: "Category Card Image (398 x 378)",
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-2 ">
                    {category.CategoryHeaderImage ? (
                      <div className="d-flex justify-content-center flex-column align-items-center">
                        <img
                          src={`${process.env.REACT_APP_IMGURL}/images/product/${category.CategoryHeaderImage}`}
                          alt={category?.CategoryHeaderImage}
                          height={100}
                          width={100}
                        />

                        <AiTwotoneDelete
                          size={30}
                          onClick={() => deleteImage(category._id,"CategoryHeaderImage")}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignitems: "center",
                        }}
                      >
                        <AiOutlineFileAdd
                          className="text-center"
                          size={30}
                          onClick={() =>
                            openmodel({
                              id: category._id,
                              UploadTo: "CategoryHeaderImage",
                              size: "Category Header Image (290x495)",
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-2 ">
                    {category?.YoutubeUrl && (
                      <>
                        <iframe
                          iframe
                          width="150"
                          height="100"
                          src={`${category?.YoutubeUrl}`}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>

                        <div className="d-flex justify-content-center">
                          <AiTwotoneDelete
                            size={20}
                            color="red"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleDeleteVideo(category._id, "YoutubeUrl")
                            }
                          />
                        </div>
                      </>
                    )}
                    {category?.CategoryVideo && (
                      <>
                        <video video width="150" height="100" controls>
                          <source
                            src={`${process.env.REACT_APP_IMGURL}/video/${category.CategoryVideo}`}
                            type="video/mp4"
                          />
                        </video>

                        <div className="d-flex justify-content-center">
                          <AiTwotoneDelete
                            size={20}
                            color="red"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleDeleteVideo(category._id, "VideoDelete")
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-md-1 p">
                    <div className="row">
                      <div className="col-md-4">
                        <AiFillDelete
                          color="black"
                          size={30}
                          onClick={() => deletecategory(category._id)}
                        />
                      </div>
                      <div className="col-md-4">
                        <Link
                          className="col-md-1"
                          to={`/admin/editservice/${category._id}`}
                        >
                          <AiFillEdit size={25} color="blue" />{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Category;
