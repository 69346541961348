import React from "react";
import "./card.css";
import { Link, useNavigate } from "react-router-dom";

const Card = ({ event }) => {
  const navigate = useNavigate();

  const handlenavigate = (id) => {
    navigate(`/singleevent/${id}`);
  };

  return (
    <div class="" id="cards_landscape_wrap-2">
      <div class=" row justify-content-center ">
       
        {event &&
          event.map((e) => (
            <div
              class="col-xs-12 col-sm-6 col-md-4 col-lg-4 d-flex align-items-stretch "
              key={e._id}
            >
              <div className="mb-4">
                <div class="card-flyer ">
                  <Link to={`/singleevent/${e._id}`}>
                    <div class="text-box">
                      <div class="image-box">
                        {e.cardImage ? (
                          <img
                            src={`${process.env.REACT_APP_IMGURL}/images/events/${e.cardImage}`}
                            alt=""
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_IMGURL}/images/events/${e.EventBanner}`}
                            alt=""
                          />
                        )}
                      </div>
                      <div
                        class="text-container text-start "
                        style={{
                          height: "23vh",
                        }}
                      >
                        {e.EventName.length < 40 ? (
                          <h6
                            style={{
                              height: "6vh",
                            }}
                          >
                            {e.EventName}
                          </h6>
                        ) : (
                          <h6
                            style={{
                              height: "6vh",
                            }}
                          >
                            {e.EventName.slice(0, 40)}...
                          </h6>
                        )}
                        {/* <h6>{e.EventName}</h6> */}

                        {String(e.EventShortDescription).length < 90 ? (
                          <p>{e.EventShortDescription} </p>
                        ) : (
                          <p>
                            {e.EventShortDescription.slice(0, 90)}
                            ...{" "}
                          </p>
                        )}
                        {/* <div className="row">
                          <div className="col-md-6">
                            <div class="info-item d-flex">
                              {" "}
                              <i class="bi bi-geo-alt flex-shrink-0"></i>{" "}
                              <strong>{e.City}</strong>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div class="info-item d-flex">
                              {" "}
                              <i class="bi bi-calendar-date flex-shrink-0"></i>{" "}
                              <strong>{e.DOE} <br/> {e.EndDOE &&  e.EndDOE}</strong>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="row justify-content-center py-3">
                        <div className="col-md-8 ">
                          <button className="cardbtn ">Know More →</button>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Card;
