import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Home.css";
import bg from "../images/logo.png";
import bgfooter from "../images/logo.png";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Topbanner from "../components/topbanner/Topbanner";
import HeaderNew from "./HeaderNew";
import { axiosInstance } from "../config";
import {
  ToastError,
  ToastInfo,
  ToastSusses,
  ToastWarn,
} from "../util/Tost.js.js";

export const Contact = () => {
  let location = useLocation();
  const [lastname, setLastName] = useState();
  const [name, setName] = useState();
  const [phone, setphone] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let emailValidated = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (!emailValidated.test(email)) {
        return ToastWarn("Please enter a valid email");
      }

      const { data } = await axiosInstance.post(`/api/ContactUs`, {
        Firstname: name,
        Lastname: lastname,
        Number: phone,
        Usermial: email,
        UserMessage: message,

        // subject: "Contact Us Page Query",
        // email: "enquiry@natura.net.in",
      });
      // console.log("datadatadatadatadata", data);

      ToastSusses("Our Team will connect and assist you soon!");
      setName("");
      setLastName("");
      setphone("");
      setEmail("");
      setMessage("");
      navigate("/thank-you");
    } catch (error) {
      ToastError();
      console.log(error, "check your settings");
    }
  };

  return (
    <div className="colorSection">
      <Header />
      {/* <HeaderNew/> */}

      <Topbanner heading="Contact Us" height="45vh" />

      <section
        class="contact my-5  d-flex justify-content-center align-items-center"
        style={{ overflow: "hidden" }}
      >
        <div class="container mx-auto">
          <div class="row px-lg-5 px-2 p-5 g-5">
            <div
              class="col-lg-6 col-md-6  mx-auto rounded bg-white"
              style={{
                boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
                padding: "20px",
              }}
            >
              {/* <h2 class="display-5 pt-2">Contact Us</h2>s */}
              {/* <small>To make your Client’s Event Memorable, Fill out the Form Below.</small> */}
              <form class="py-2" method="post" onSubmit={handleSubmit}>
                <div class="row">
                  <div class="col-lg-6 my-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First Name"
                      aria-label="First name"
                      id="firstname"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      name="firstname"
                      required
                    />
                  </div>
                  <div class="col-lg-6 my-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Last Name"
                      aria-label="Last name"
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                      id="lastname"
                      name="lastname"
                    />
                  </div>
                </div>
                <div class="form-row my-2">
                  <div class="col">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      aria-label="Email"
                      id="email"
                      name="email"
                      required
                    />
                  </div>
                </div>
                <div class="form-row my-2">
                  <div class="col">
                    <input
                      type="Number"
                      min="1000000000"
                      max="9999999999"
                      class="form-control input-Number"
                      placeholder="Phone Number"
                      value={phone}
                      onChange={(e) => setphone(e.target.value)}
                      aria-label="Phone Number"
                      id="number"
                      name="number"
                    />
                  </div>
                </div>
                <div class="form-floating my-3">
                  <textarea
                    class="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={{ height: "100px" }}
                    name="comment"
                  ></textarea>
                  <label for="floatingTextarea2">Message</label>
                </div>

                <input
                  class="btn  rounded col-lg-12 col-12 px-5 py-2"
                  style={{ backgroundColor: "#FEBD15", color: "#fff" }}
                  type="submit"
                  value="Submit"
                  id="submit"
                />
              </form>

              {/* <!-- <a href="#" class="btn col-lg-12 " style=" background-color:#ED1E22 "><b class="text-white">Submit</b></a> --> */}
            </div>
            <div
              class="col-lg-6 col-md-6  mx-auto rounded d-flex justify-content-center align-items-center "
              style={{
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                backgroundColor: "#191919",
              }}
            >
              {/* <img src={require('../images/natura/hang.png')} className="swing" alt="" /> */}
              <div className="upper_head_about">
                <h2>
                  <b className="text-white" style={{ color: "#FEBD15" }}>
                    INDIA'S
                  </b>
                  <br />
                  <b className="" style={{ color: "#484848" }}>
                    FIRST & ONLY
                  </b>
                  <br />
                  <b className="text-white" style={{ color: "" }}>
                    AERIAL RIGGING
                  </b>
                  <br />
                  <b style={{ color: "#FEBD15" }}>COMPANY</b>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
