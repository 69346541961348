import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { About } from "./Home/About";
import { Landing } from "./Home/Landing";
// import {Home2} from './Home/Home2';
import { Contact } from "./Home/Contact";
import { Service } from "./Home/Service";
// import { Document } from './Home/Document';
import { Team } from "./Home/Team";
import ThankYou from "./Home/Thankyou/ThankYou";

// ---Admin---Route
// Admin Route
// import User from "./components/admin/user/User";
// import Adduser from "./components/admin/user/Adduser";
// import Edituser from "./components/admin/user/Edituser";
// import Serviceadmin from "./admin/service/Serviceadmin";
// import Addservice from "./admin/service/Addservice";
// import Editservice from "./admin/service/Editservice";
import Category from "./admin/servicecategory/Category";
import Addcategory from "./admin/servicecategory/Addcategory";
import Editcategory from "./admin/servicecategory/Editcategory";
import { Singleservice } from "./Home/Singleservice";
import { Events } from "./Home/Events";
import { Login } from "./Home/Login";
import jwt_decode from "jwt-decode";
import { useState } from "react";
import { useEffect } from "react";
import Product from "./admin/product/Product";
import AddProduct from "./admin/product/AddProduct";
import EditProduct from "./admin/product/EditProduct";
import AdminEvent from "./admin/event/AdminEvent";
import AddEvent from "./admin/event/AddEvent";
import EditEvent from "./admin/event/EditEvent";
// import SingleEvent from './Home/SingleEvent';
import FaqPage from "./pages/faq/FaqPage";
import SafetyPage from "./pages/safetypage/SafetyPage";
import AllEventPage from "./pages/allevent/AllEventPage";
import AboutNatura from "./pages/aboutnatura/AboutNatura";

import AdminFaq from "./admin/faq/AdminFaq";
import AdminAddFAq from "./admin/faq/AdminAddFAq";
import AdminEditFaq from "./admin/faq/AdminEditFaq";

import FaqCategory from "./admin/faqcategory/FaqCategory";
import AdminAddFaqCategory from "./admin/faqcategory/AdminAddFaqCategory";
import EditFaqCategory from "./admin/faqcategory/EditFaqCategory";
import Search from "./pages/search/Search";
import FeaturedEvent from "./pages/FeaturedEvent/FeaturedEvent";
import SingleEventNew from "./Home/SingleEventNew";

import Testimonial from "./admin/testimonial/Testimonial";
import AddTestimonial from "./admin/testimonial/AddTestimonial";
import EditTestimonial from "./admin/testimonial/EditTestimonial";
import ClientTestimonial from "./pages/testimonial/ClientTestimonial";
import Venue from "./pages/venue/Venue";
import GalleryDashboard from "./admin/gallery/GalleryDashboard";
import AddGallery from "./admin/gallery/AddGallery";
// import Blog from "./Home/Blog";
import NewBlogList from "./components/blog/NewBlogList";
import AddBlog from "./admin/blog/AddBlog";
import AdminBlog from "./admin/blog/AdminBlog";
import EditBlog from "./admin/blog/EditBlog";
import Blog from "./components/blog/Blog";
import AdminTeam from "./admin/Team/AdminTeam";
import AddTeam from "./admin/Team/AddTeam";
import EditTeam from "./admin/Team/EditTeam";
import AdminContactUs from "./admin/ContactUs/AdminContactUs";
import { Rig_Tech_Services } from "./Home/Rig_Tech_Services";
import { Rig_Tech_Services_Product } from "./Home/Rig_Tech_Services_Product";

function App() {
  const [isadmin, setIsAdmin] = useState(true);


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          {/* <Route path="/" element={<Home/>} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/about-natura" element={<AboutNatura />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/our-events" element={<Events />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/safety" element={<SafetyPage />} />
          <Route path="/Featured/event" element={<FeaturedEvent />} />
          {/* <Route path="/Blog" element={<Blog />} /> */}
          <Route path="/Blog" element={<Blog />} />
          <Route path="/:url" element={<NewBlogList />} />
          <Route path="/service/:name" element={<Service />} />
          <Route path="/service/rig_tech/:name" element={<Rig_Tech_Services_Product />} />
          <Route path="/service/rig_tech" element={<Rig_Tech_Services />} />
          <Route path="/team" element={<Team />} />
          <Route path="/login" element={<Login />} />
          <Route path="/singleproject/:id" element={<Singleservice />} />
          <Route path="/singleevent/:id" element={<SingleEventNew />} />
          <Route path="/allevent/:eventtype" element={<AllEventPage />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/search" element={<Search />} />
          <Route path="/testimonial" element={<ClientTestimonial />} />
          <Route path="/venue/:venuetype" element={<Venue />} />

          {isadmin && (
            <>
              <Route path="/admin/service" element={<Category />} />
              <Route path="/admin/addservice" element={<Addcategory />} />
              <Route path="/admin/editservice/:id" element={<Editcategory />} />

              <Route path="/admin/product" element={<Product />} />
              <Route path="/admin/addproduct" element={<AddProduct />} />
              <Route path="/admin/editproduct/:id" element={<EditProduct />} />

              {/* Event Section */}

              <Route path="/admin/event" element={<AdminEvent />} />
              <Route path="/admin/addevent" element={<AddEvent />} />
              <Route path="/admin/editevent/:id" element={<EditEvent />} />

              {/* Faq  Option */}
              <Route path="/admin/faq" element={<AdminFaq />} />
              <Route path="/admin/addfaq" element={<AdminAddFAq />} />
              <Route path="/admin/editfaq/:id" element={<AdminEditFaq />} />

              {/* Faq Category Section */}
              <Route path="/admin/faqcategory" element={<FaqCategory />} />
              <Route
                path="/admin/addfaqcategory"
                element={<AdminAddFaqCategory />}
              />
              <Route
                path="/admin/editfaqcategory/:id"
                element={<EditFaqCategory />}
              />

              {/* Testimonial section React */}
              <Route path="/admin/testimonial" element={<Testimonial />} />
              <Route
                path="/admin/addtestimonial"
                element={<AddTestimonial />}
              />
              <Route
                path="/admin/GalleryDashboard"
                element={<GalleryDashboard />}
              />
              <Route path="/admin/AddGallery" element={<AddGallery />} />
              <Route
                path="/admin/addtestimonial"
                element={<AddTestimonial />}
              />
              <Route
                path="/admin/edittestimonial/:id"
                element={<EditTestimonial />}
              />

              {/* blog */}
              <Route path="/admin/blog" element={<AdminBlog />} />
              <Route path="/admin/add-blog" element={<AddBlog />} />
              <Route path="/admin/edit-blog/:id" element={<EditBlog />} />
              {/* Team */}
              <Route path="/admin/Team" element={<AdminTeam />} />
              <Route path="/admin/add-Team" element={<AddTeam />} />
              <Route path="/admin/edit-Team/:id" element={<EditTeam />} />

              {/* contect us */}

              <Route path="/admin/ContactUs" element={<AdminContactUs />} />
            </>
          )}
          <Route path="*" element={<Navigate to="/" />} />

          {/* Admin Rootes--- */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
