import React, { useEffect, useState } from "react";
import {
  AiFillDelete,
  AiFillEdit,
  AiOutlineFileAdd,
  AiTwotoneDelete,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import Sidebar from "../common/sidebar/Sidebar";
import AdminLoader from "../components/AdminLoader";

const Product = () => {
  const [product, setproduct] = useState();
  const [del, setdel] = useState(false);
  const [modal, setmodal] = useState(false);
  const [modalone, setmodalone] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [loader, setloader] = useState(false);
  const [productid, setproductid] = useState();
  const [serviceType, setServiceType] = useState([]);
  const [cate, setcate] = useState();
  const [productType, setproductType] = useState();
  const [IsYoutubeVideo, setIsYoutubeVideo] = useState(false);
  const [YoutubeUrl, setYoutubeUrl] = useState();

  useEffect(() => {
    getproduct();
  }, [!del]);

  const getcategory = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/category`);
      setcate(data);
    } catch (error) {
      alert(error.response.data);
    }
  };

  const getproduct = async () => {
    try {
      const { data } = await axiosInstance.get("/api/product");
      setproduct(data);
    } catch (error) {
      alert(error.response.data);
    }
  };

  const togglehandle = (e) => {
    e.persist();
    var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };

  const deleteproduct = async (id) => {
    var answer = window.confirm("Are you sure To delete?");
    if (answer) {
      const { data } = await axiosInstance.delete(`/api/product/${id}`, {
        headers: {
          token: localStorage.getItem("token"),
        },
      });

      if (data.success) {
        alert("deleted");
        getproduct();
      }
    }
  };

  const deletevideo = async (id, name) => {
    try {
      var answer = window.confirm("Are you sure To delete?");
      if (answer) {
        const { data } = await axiosInstance.post(
          `/api/product/removevideo/${id}`,
          {
            VideoType: name,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );

        if (data.sucess) {
          alert(data.message);
          setdel(del);
        }

        setTimeout(() => {
          setdel(!del);
        }, 100);
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const openmodel = (data) => {
    setproductid(data._id);
    setmodal(true);
  };

  const handleVideo = async (e) => {
    e.preventDefault();
    try {
      if (IsYoutubeVideo) {
        const formdata = new FormData();
        formdata.append("YoutubeUrl", YoutubeUrl);

        const { data } = await axiosInstance.post(
          `/api/product/addvideo/${productid}`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (data.success) {
          setloader(false);
          setdel(!del);
          setmodal(false);
          alert("Added Successfully");
        }
      } else {
        if (selectedFile.size >= 5000000) {
          alert("File Size Should Less Than 5 Mb");
          return;
        }
        const formdata = new FormData();
        setloader(true);

        formdata.append("productVideo", selectedFile);
        const { data } = await axiosInstance.post(
          `/api/product/addvideo/${productid}`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (data.success) {
          setloader(false);
          setdel(!del);
          setmodal(false);
          alert("Added Successfully");
        }
      }
    } catch (error) {
      setloader(false);
      alert(error.response.data.message);
    }
  };

  const handleModal = (id, productType) => {
    setmodalone(true);
    setproductid(id);
    setServiceType(productType);
  };

  const deleteserviceType = async (service) => {
    try {
      var answer = window.confirm("Are you sure To delete?");
      if (answer) {
        const { data } = await axiosInstance.delete(
          `/api/product/removeservice/${productid}/${service._id}`
        );

        if (data.success) {
          alert("Removed Successfully");
          setdel(!del);
          setmodalone(false);
        }
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const handleservice = async () => {
    try {
      const { data } = await axiosInstance.post(
        `/api/product/addservice/${productid}`,
        {
          serviceId: productType,
        }
      );

      if (data.success) {
        alert("Added Sucessfully");
        setdel(!del);
        setmodalone(false);
      }
    } catch (error) {
      alert("Alert Added Successfully");
    }
  };

  return (
    <>
      <Sidebar />

      {modal && (
        <>
          <div
            class="modal d-block"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ opacity: "1" }}
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Video
                  </h5>
                  <br />
                  <div class="form-switch col-md-6 align-items-start mx-3 ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked={IsYoutubeVideo}
                      onClick={(e) => setIsYoutubeVideo(!IsYoutubeVideo)}
                    />{" "}
                    &nbsp;
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckDefault"
                    >
                      Select video type
                    </label>
                  </div>

                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => setmodal(false)}
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div className="row">
                    {!IsYoutubeVideo ? (
                      <>
                        <input
                          type="file"
                          class="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter file"
                          onChange={(e) => setSelectedFile(e.target.files[0])}
                          required
                        />
                      </>
                    ) : (
                      <>
                        <div className="col-md-6 align-items-start">
                          <input
                            type="text"
                            class="form-control"
                            aria-describedby="emailHelp"
                            placeholder="Enter Youtube Link "
                            value={YoutubeUrl}
                            onChange={(e) => setYoutubeUrl(e.target.value)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                  <div></div>
                  <div>
                    {loader ? (
                      <>
                        <button type="button" class="btn btn-primary" disabled>
                          Loading
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={handleVideo}
                        >
                          upload Video
                        </button>
                      </>
                    )}
                    {/* <button type="button" class="btn btn-primary" onClick={handleimgUrlSubmit} >upload link</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* 
            {
                modalone && <>
                    <div class="modal d-block" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ opacity: '1' }}>
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Add Service</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" onClick={() => setmodalone(false)} aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div className="row">
                                        {
                                            serviceType && serviceType.map((service, i) => (
                                                <>
                                                    <div className="col-lg-6">
                                                        <div class="input-group mb-3">
                                                            <input type="text" class="form-control" placeholder={service?.categoryName} disabled readonly aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                            <span class="input-group-text btb btn-danger" id="basic-addon2" onClick={() => deleteserviceType(service)} ><i class="fa-solid fa-trash text-white"></i></span>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }

                                    </div>
                                </div>
                                <div class="modal-footer d-flex justify-content-between">


                                    <div>
                                        <select class="form-select form-select-sm" aria-label=".form-select-sm example" onChange={(e) => setproductType(e.target.value)}>
                                            <option value="">Select Category</option>
                                            {
                                                cate && cate.map((category) => (
                                                    <>
                                                        <option value={category._id}>{category.categoryName}</option>
                                                    </>
                                                ))
                                            }

                                        </select>
                                    </div>


                                    <div>
                                        {
                                            loader ? <>
                                                <button type="button" class="btn btn-primary" disabled>Loading</button>

                                            </> : <>
                                                <button type="button" class="btn btn-primary" onClick={handleservice}>Add Service</button>

                                            </>
                                        }
                                 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            } */}

      <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
        <nav className="navbar top-navbar navbar-light bg-light px-5">
          <a className="btn border-0" id="menu-btn" onClick={togglehandle}>
            <i className="bx bx-menu"></i>
          </a>
        </nav>
        {/* <!--End Top Nav --> */}
        <h3 className="text-dark p-3">ADMIN PRODUCT DASHBOARD 💻 📱</h3>

        <div
          className="container p-3"
          style={{ boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
        >
          <div className="row text-start">
            <div className="">
              <Link
                to="/admin/addproduct"
                className="text-white btn btn-success rounded px-5"
              >
                Add New Product
              </Link>
            </div>
          </div>
          <div className="row mb-4 border-bottom text-start">
            <div className="col-md-1 ">
              <b>Product Title</b>
            </div>
            <div className="col-md-2 ">
              <b>Product short Description</b>
            </div>
            <div className="col-md-2 ">
              <b>Product Description</b>
            </div>

            <div className="col-md-1 ">
              <b>Product Type</b>
            </div>
            <div className="col-md-2 ">
              <b>Product Image</b>
            </div>
            <div className="col-md-2 ">
              <b>Product Video</b>
            </div>
            <div className="col-md-2 ">
              <b>EXTRA OPTIONS</b>
            </div>
          </div>

          {!product ? (
            <AdminLoader />
          ) : product.length === 0 ? (
            <>
              <div className="row d-flex justify-content-center mt-5">
                <div className="col-md-8 text-center">
                  <h4>No Product Available</h4>
                </div>
              </div>
            </>
          ) : (
            product &&
            product.map((p) => (
              <>
                <div className="row text-start pb-2">
                  <div className="col-md-1 ">{p?.ProductTitle}</div>
                  <div className="col-md-2 ">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea4"
                      rows="3"
                      value={p?.ProductShortDescription}
                    ></textarea>
                  </div>
                  <div className="col-md-2 ">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea4"
                      rows="3"
                      value={p?.ProductDescription}
                    ></textarea>
                  </div>
                  <div className="col-md-1 ">
                    {p?.productType?.categoryName}
                    {/* <button className="btn btn-success btn-sm" onClick={() => handleModal(p._id, p.productType)}  >Add Services</button> */}
                  </div>
                  <div className="col-md-2 ">
                    <img
                      src={`${process.env.REACT_APP_IMGURL}/images/product/${p.ProductImage}`}
                      alt={"N/A"}
                      height={100}
                      width={100}
                    />
                  </div>

                  <div className="col-md-2 ">
                    {p?.YoutubeUrl && (
                      <>
                        <iframe
                          iframe
                          width="150"
                          height="100"
                          src={`${p?.YoutubeUrl}`}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                        <div className="d-flex justify-content-center">
                          <AiTwotoneDelete
                            size={20}
                            color="red"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => deletevideo(p._id, "YoutubeUrl")}
                          />
                        </div>
                      </>
                    )}
                    {p?.ProductVideo && (
                      <div className="d-flex justify-content-center flex-column align-items-center">
                        <video width="170" height="100" controls>
                          <source
                            src={`${process.env.REACT_APP_IMGURL}/videos/product/${p.ProductVideo}`}
                            type="video/mp4"
                          />
                        </video>

                        <AiTwotoneDelete
                          size={30}
                          onClick={() => deletevideo(p._id, "VideoDelete")}
                        />
                      </div>
                    )}
                    {(!p?.ProductVideo && !p?.YoutubeUrl) && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignitems: "center",
                        }}
                      >
                        <AiOutlineFileAdd
                          className="text-center"
                          size={30}
                          onClick={() => openmodel(p)}
                        />
                      </div>
                    )}
                  </div>

                  <div className="col-md-2 ">
                    <div className="row">
                      {/* <div className="col-md-4">
                                                    <AiFillEye color='red' size={25} onClick={() => showdetails(category)} />
                                                </div> */}
                      <div className="col-md-6">
                        <AiFillDelete
                          color="black"
                          size={30}
                          onClick={() => deleteproduct(p._id)}
                        />
                      </div>
                      <div className="col-md-6">
                        <Link
                          className="col-md-1"
                          to={`/admin/editproduct/${p._id}`}
                        >
                          <AiFillEdit size={25} color="blue" />{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Product;
