import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Card from '../../components/cardcomponent/Card'
import Topbanner from '../../components/topbanner/Topbanner'
import { axiosInstance } from '../../config'
import { Footer } from '../../Home/Footer'
import { Header } from '../../Home/Header'

const AllEventPage = () => {
    // let location = useLocation();
    const { eventtype } = useParams();
    console.log("eventtype",eventtype);
    console.log("eventtype",eventtype.split(","));
    const [allevent, setallevent] = useState([])
    const [IsFunctionCalled, setIsFunctionCalled] = useState(false);


    useEffect(() => {
        getfilterevent();
    }, [eventtype])

    const getfilterevent = async () => {
        try {

            const filterdata = []

            filterdata.push(eventtype)

            const { data } = await axiosInstance.post("/api/event/filtereventcategory", {
                "EventCategory": JSON.stringify(eventtype.split(","))
            })
            if(data.length === 0){
                setIsFunctionCalled(true)
            }else{
                setIsFunctionCalled(false)
            }
            
            setallevent(data)

        } catch (error) {

            


        }
    }
    return (
        <>
            <Header />
            <Topbanner heading="Our Events" height="45vh"  />

            <section className='pb-4 pt-5'>
                <div className="container">
                    <Card event={allevent} />
                   {
                    IsFunctionCalled && <h3 style={{
                        textAlign:"center"
                    }}>To be Updated on Website</h3>
                   }
                </div>
            </section>

            <Footer />
        </>
    )
}

export default AllEventPage