import React, { useState } from 'react';
import Sidebar from '../common/sidebar/Sidebar';
import { axiosInstance } from '../../config';
import { useNavigate } from 'react-router-dom';

const Addcategory = () => {
    const navigate = useNavigate();

    const [category, setcategory] = useState();
    const [description, setdescription] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [shortDescription, setShortDescription] = useState();
    const [YoutubeUrl, setYoutubeUrl] = useState();
    const [loading, setLoading] = useState(false);



    const togglehandle = (e) => {
        e.persist();
        var menu_btn = document.querySelector("#menu-btn");
        var sidebar = document.querySelector("#sidebar");
        var container = document.querySelector(".my-container");
        sidebar.classList.toggle("active-nav")
        container.classList.toggle("active-cont");
    }

    const handleUserdataSubmit = async (e) => {
        e.preventDefault();
        try {
            if (category == "" || category == undefined) {
                alert("Category Name Can't be empty")
                return
            }
            if (description.trim() == "" || !description) {
                alert("Description Can't be empty")
                return
            }
            if (selectedFile.size >= 5000000) {
                alert("File Size Should Less Than 5 Mb")
                return
            }

            setLoading(true)
            const formdata = new FormData();
            formdata.append("categoryName", category)
            formdata.append("CategoryDescription", description)
            formdata.append("shortDistractions", shortDescription)
            formdata.append("file", selectedFile)

            const { data } = await axiosInstance.post(`/api/category`, formdata, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            setLoading(false)
            alert(data.message)
            navigate("/admin/category")
        } catch (error) {
            alert(error.response.data.message)
            setLoading(false)
        }
    }

    return (
        <>
            <Sidebar />

            <div className="p-1 my-container active-cont">
                {/* <!-- Top Nav --> */}
                <nav className="navbar top-navbar navbar-light bg-light px-5">
                    <a className="btn border-0" id="menu-btn" onClick={togglehandle}><i className="bx bx-menu"></i></a>
                </nav>
                {/* <!--End Top Nav --> */}
                {/* <h3 className="text-dark p-3">ADMIN ADD USER 💻 📱
                </h3> */}

                <div className="row mx-2 " style={{ backgroundColor: "#FDF8DB" }}>

                    <div className="toogler"><h3>Add Service</h3></div>

                    <form onSubmit={handleUserdataSubmit}>
                        <div className="col-md-6 align-items-center">
                            <label>Service Name</label>
                            <input className="form-control " type="text" value={category} onChange={e => setcategory(e.target.value)} placeholder="FirstName" required/>
                        </div>
                        <div className="col-md-6 align-items-center">
                            <label>Service short Description</label>
                            <textarea className="form-control " type="text" value={shortDescription} onChange={e => setShortDescription(e.target.value)} placeholder="Service short Description    " />
                        </div>
                        <div className="col-md-6 align-items-center">
                            <label>Category Long Description</label>
                            <textarea className="form-control " type="text" value={description} onChange={e => setdescription(e.target.value)} placeholder="Description" required/>
                        </div>
                        <div className="col-md-6 align-items-center">
                            <label>Video</label>
                            <input type="file" class="form-control" aria-describedby="emailHelp" placeholder="Enter file" onChange={e => setSelectedFile(e.target.files[0])} required/>
                        </div>

                        <div className="col-md-6" style={{ padding: "5px" }}>
                            <div className="d-flex flex-row">
                                {
                                    loading ? <>
                                        <button className="btn btn-success" disabled>Loading</button>
                                    </> : <>
                                        <button className="btn btn-success" >Submit</button>
                                    </>
                                }
                            </div>
                        </div>
                    </form>

                </div>

            </div>


        </>
    )
}

export default Addcategory