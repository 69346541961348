import React from 'react'
import parse from 'html-react-parser';

const VideoTestimonial = ({ p }) => {
    return (
        <div class="m-2 p-1" style={{

        }}  >
            {parse(`${p.TestimonialContent}`)}
            <div className='px-2'>
                <h5 className='mt-2'><b>{p.AuthorName}</b></h5>


                <h6><b>{p.Designation}</b> </h6>
                <h6> <b>{p.companyName}</b></h6>

            </div>

            {/* <h5 className="py-1"><b>{p.AuthorName} <span>, {p.Designation}</span> <span>, {p.companyName}</span></b></h5> */}
        </div>

    )
}

export default VideoTestimonial