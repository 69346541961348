import React from "react";
import { BiLoader } from "react-icons/bi";
import Carousel from "react-multi-carousel";
import "./maintestimonial.css";
import "react-multi-carousel/lib/styles.css";

const MainTestimonial = ({ testimonial, Component, phoneitems }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: phoneitems || 1,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };

  return (
    <>
      {!testimonial ? (
        <center>
          <BiLoader />
        </center>
      ) : testimonial.length === 0 ? (
        <>
          <div className="row d-flex justify-content-center mt-5">
            <div className="col-md-8 text-center">
              <h3 style={{ fontSize: "20px", fontWeight: "900" }}>
                No any Testimonial Available ! 
              </h3>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container pb-2 px-0" id="testimonialdata" >
            <Carousel
              responsive={responsive}
              itemClass="p-0 m-0"
              infinite={true}
              autoPlaySpeed={1000}
            >
              {testimonial?.map((p, i) => (
                <>
                  <Component p={p} key={p?._id ?? i} />
                </>
              ))}
            </Carousel>
          </div>
        </>
      )}
    </>
  );
};

export default MainTestimonial;
