import React from "react";
import "./blogcard.css";

import { Link } from "react-router-dom";

const Blogcard = ({ blog }) => {
  return (
    <>
      <div class="col-12 col-sm-8 col-md-6 col-lg-4 my-3" key={blog._id}>
        <div class="card">
          <img
            class="card-img"
            src={`https://natura.co.in/images/Blog/${blog.CardImage}`}
            alt="Bologna"
            loading="lazy"
          />
          {/* <div class="card-img-overlay"> */}
          {/* <a href="#" class="btn btn-light btn-sm">Cooking</a> */}
          {/* </div> */}
          <div class="p-4">
            <h4 class="card-title">{blog?.heading}</h4>
            {/* <small class="text-muted cat">
                            <i class="far fa-clock text-primary"></i> 30 minutes
                            <i class="fas fa-users text-primary"></i> 4 portions
                        </small> */}
            {/* <p class="card-text">{parse(blog?.content.slice(0, 20))}</p> */}
            {/*  */}
            {/* <a href="#" class="">Read More</a> */}
            {/* <Link to={`/bl/${blog.url}`}  className="text-warning"  key={blog._id}>Read More</Link> */}
            <Link
              to={`/${blog.url}`}
              className="btn btn-primary"
              key={blog._id}
            >
              Read More
            </Link>
            {/* <button onClick={()=>{navigate(`/bl/${blog.url}`)}}>Read More</button> */}
          </div>
          <div class="card-footer text-muted d-flex justify-content-between bg-transparent border-top-0">
            <div class="views">{blog?.createdAt.split("T")[0]}</div>
            <div class="stats">
              {/* <i class="far fa-eye"></i> 1347
                            <i class="far fa-comment"></i> 12 */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogcard;
