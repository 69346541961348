import React from "react";
import "./Insurance";
const Insurance = () => {
  return (
    <section class="core-features">
      <div
        class="core-features-bg"
        style={{
          backgroundImage:
            "url(http://layerdrops.com/moniz/assets/images/backgrounds/core-features-bg.jpg)",
        }}
      ></div>
      <div class="container">
        <div class="row">
          <div class="col-xl-5 col-lg-5">
            <div class="core-features__left">
              <div class="section-title text-left">
                {/* <span class="section-title__tagline">Insurance</span> */}
                <h2 class="section-title__title text-white">
                  <b style={{ color: "#FEBD15" }}>Insurance</b>
                  <hr
                    style={{
                      marginTop: "0px",
                      height: "2px",
                    }}
                  />
                </h2>
              </div>
              <div class="core-features__left-bottom">
                <div class="core-features__left-bottom-img ">
                  {/* <img
                    src="http://layerdrops.com/moniz/assets/images/resources/core-features-left-bottom-img.jpg "
                    alt=""
                  /> */}
                </div>
                <div class="core-features__left-bottom-text text-white">
                  <p style={{
                    fontSize:"1.1rem"
                  }}>
                  Our entire staff is covered by insurance to guard against the potential risks connected with working
at heights. We are one of the few rigging companies to provide extensive insurance coverage to our
personnel in order to safeguard them to the greatest extent possible.

                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2"></div>
          <div class="col-xl-5 col-lg-5">
            <div
              class="core-features__promicess"
              style={{
                marginTop: "13px",
              }}
            >
              <ul class="list-unstyled core-features__promicess-list">
                <li>
                  <div class="text">
                    <p>Medical Insurance</p>
                  </div>
                  <div class="icon">
                    <span class="icon-tick">
                      <i
                        class="fa-solid fa-check"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      ></i>
                    </span>
                  </div>
                </li>
                <li>
                  <div class="text">
                    <p>Workman’s compensation</p>
                  </div>
                  <div class="icon">
                    <span class="icon-tick">
                      <i
                        style={{
                          fontSize: "1.4rem",
                        }}
                        class="fa-solid fa-check"
                      ></i>
                    </span>
                  </div>
                </li>
                <li>
                  <div class="text">
                    <p>Liability Insurance</p>
                  </div>
                  <div class="icon">
                    <span class="icon-tick">
                      {" "}
                      <i
                        class="fa-solid fa-check"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      ></i>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Insurance;
