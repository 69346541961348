import React from 'react'
import topbanner from "../../images/natura/tobanner.jpg"

const Topbanner = ({heading,height,img=null}) => {  
    return (
        <div
            class="about_banner1 d-flex justify-content-center align-items-center  "
            style={{
              backgroundImage:
                "linear-gradient(-70deg,rgb(254,189,20,0.8)55%, rgb(254,189,21,0.0)56%)," +
                `url(${img?img :topbanner})`,
                height:height?height:"80vh"
            }}
           
          >
            <div class="container ">
              <div class="main_title_3 py-5 col-md-6 text-md-start text-center d-lg-block d-none   ">

                <h2 class="text-white ">
                  <b>{heading}</b>
                </h2>
              
               
              </div>
              <div>

              <h2 class="text-white d-lg-none d-block text-center  ">
                  <b>{heading}</b>
                </h2>
              </div>
              
            </div>
          </div>


    )
}

export default Topbanner