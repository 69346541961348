import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Footer } from './Footer';
import { Header } from './Header'
import "./Home.css";

export const Team = () => {

  const fdata = "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FTataMotorsGroup%2Fvideos%2F443220890942339%2F&show_text=false&width=476&t=0"

  const videolink = [
    "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FTataMotorsGroup%2Fvideos%2F443220890942339%2F&show_text=false&width=476&t=0",
    "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FTataMotorsGroup%2Fvideos%2F1714200445616386%2F&show_text=false&width=476&t=0"

  ]

  // let location = useLocation();
  // const setBreadcrumbs = async (nameData) => {
  //   // let location = useLocation();
  //   const breadcrumbs = JSON.parse(localStorage.getItem("breadcrumbs"));
  //   
  //   const index = breadcrumbs.findIndex((e) => e.name === nameData);
  //   
  //   if (index !== -1) {
  //     breadcrumbs.splice(1, index);
  //   }
  //   if (breadcrumbs.length < 5) {
  //     breadcrumbs.push({ name: nameData, path: location.pathname });
  //     await localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
  //   } else {
  //     breadcrumbs.shift();
  //     await localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
  //     breadcrumbs.push({ name: nameData, path: location.pathname });
  //     await localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
  //   }
  // };
  // useEffect(() => {
  //   setBreadcrumbs("safety");
  // }, []);

  return (
    <div>


      <Header />

      <section className='career d-flex justify-content-center align-items-center' >
        <div className="container" >
          <div className="row  ">
            <div className="col-lg-12">
              <h1 className='text-white'><b>Where To Find Natura</b></h1>
            </div>
          </div>
        </div>




        {/* <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FTataMotorsGroup%2Fvideos%2F1714200445616386%2F&show_text=false&width=476&t=0" width="476" height="476" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe> */}







        {/* <div className='rgba'>

                <div className='text-start container text-white'>
                    <h3>Degrees Thats Matter. <span>@</span></h3> 
                    <div className="row">
                      <div className="col-lg-12">
                        
                        
                      <div className="row align-items-center">
                        <div className="col-lg-4">
                          <a class="btn btn-primary py-3 px-5">Find Yours</a>
                        </div>
                        <div className="col-lg-8">
                        <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis nesciunt molestias voluptatum cum minima, assumenda sequi sit? Soluta cumque, eius dolorum nobis at aspernatur, minima doloribus, vel esse eos debitis.
                      </p>
                        </div>
                      </div>

                      </div>  
                    </div>    
                </div>
    </div> */}
      </section>

      {/* Gallery-- */}

      {/* <div className="container py-5">
        {
          videolink.map((v) => (
            <>
              <iframe src={v} width="476" height="476" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
            </>
          ))
        }
      </div> */}
      {/* Gallery-- */}



      {/* <div class="container">
        <div class="row">
          <div class="col-12 col-lg-3 py-4 my-auto">
            <div class="boxnew shadow-sm p-4">
              <div class="image-wrapper mb-3">
                <img class="img-fluid" src="https://images.pexels.com/photos/555790/pexels-photo-555790.png" alt="..." />
              </div>
              <div class="box-desc">
                <h5>Shubham</h5>
                <p>Profession</p>
              </div>
              <ul class="social">
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-lg-3 py-4 my-auto">
            <div class="boxnew shadow-sm p-4">
              <div class="image-wrapper mb-3">
                <img class="img-fluid" src="https://images.pexels.com/photos/555790/pexels-photo-555790.png" alt="..." />
              </div>
              <div class="box-desc">
                <h5>Shubham</h5>
                <p>Profession</p>
              </div>
              <ul class="social">
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-lg-3 py-4 my-auto">
            <div class="boxnew shadow-sm p-4">
              <div class="image-wrapper mb-3">
                <img class="img-fluid" src="https://images.pexels.com/photos/555790/pexels-photo-555790.png" alt="..." />
              </div>
              <div class="box-desc">
                <h5>Shubham</h5>
                <p>Profession</p>
              </div>
              <ul class="social">
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-lg-3 py-4 my-auto">
            <div class="boxnew shadow-sm p-4">
              <div class="image-wrapper mb-3">
                <img class="img-fluid" src="https://images.pexels.com/photos/555790/pexels-photo-555790.png" alt="..." />
              </div>
              <div class="box-desc">
                <h5>Jon Doe</h5>
                <p>FrontEnd Developer</p>
              </div>
              <ul class="social">
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
              </ul>
            </div>
          </div>

        </div>
      </div> */}
      <section className="team  py-5" style={{ backgroundColor: '#414142' }}>

        <div className="container">
          <div className="row justify-content-between align-items-center py-2" style={{ overflow: 'hidden' }}>
            <div className="col-lg-6 col-md-6 col-6">
              <div className="text-start">
                <h2 style={{ color: '#FEBD15' }}><b>Lorem ipsum dolor </b></h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <div className="text-end">
                <Link to="/"> <img className='heade_logo px-5' height='60px' src={require('../images/client/logohori.png')} alt="" /></Link>
              </div>
            </div>
          </div>
          <hr style={{ backgroundColor: '#e6e6dc', height: '10px' }} />
          <div className="row">
            <div className="col-lg-12 col-md-12 p-3 rounded colorSection" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
              <img src={require('../images/team/aviskar.jpg')} className="img-fluid float-start rounded" alt="" style={{ marginRight: '30px', marginBottom: '10px', width: '25%' }} />

              <div className="" style={{ textAlign: 'justify' }}>
                <h3><b>Lorem, ipsum dolor.</b></h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea inventore non repudiandae corrupti suscipit dolorum ullam esse doloremque sunt corporis! Numquam temporibus et natus quibusdam quisquam cumque dolore ipsa voluptate quidem ipsum. Temporibus, eaque ut!<br />
                </p>
                {/* <p>
                  As Managing Director at Natura, he is the driving force behind our approach to all things aerial and a respected voice calling for greater safety in a still unorganized industry.

                </p> */}


              </div>
            </div>
          </div>

          {/* <div className="row my-5">
            <div className="col-lg-12 col-md-12 p-3 rounded colorSection" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
              <img src={require('../images/team/maria.jpg')} className="img-fluid float-start rounded" alt="" style={{ marginRight: '30px', marginBottom: '10px', width: '25%' }} />

              <div className="" style={{ textAlign: 'justify' }}>
                <h3><b>Maria Julia- Director</b></h3>
                <p>
                  As a Director, besides streamlining its business operations and facilitating strong business relations with an eye to growth, Maria oversees Natura’s performance and its adherence to quality standards – in a word, she is Natura’s conscience!<br />
                  Already playing the role of Head of Business Development at the Terence Lewis Contemporary Dance Company and having effortlessly turned something as ethereal as dance into a well-oiled business and creative brand, the esoteric pursuit of turning aerial rigging into a thriving business is completely Maria’s forte. Her knowledge of the challenges and processes in an unorganized industry, and her rapport with the entertainment and events world are just some of the things that make Maria an immense asset to Natura.<br />
                </p>



              </div>
            </div>
          </div> */}

          {/* <div className="row my-5">
            <div className="col-lg-12 col-md-12 p-3 rounded colorSection" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
              <img src={require('../images/team/rohit.jpg')} className="img-fluid float-start rounded" alt="" style={{ marginRight: '30px', marginBottom: '10px', width: '25%' }} />

              <div className="" style={{ textAlign: 'justify' }}>
                <h3><b>Rohit Pote- Project Manager</b></h3>
                <p>

                  With close to a decade in the field of Adventure and Outdoor Education, Rohit has managed to create for himself a strong niche as flawless adventure activity support for outbound training programmes, thanks to an enviable reputation among senior facilitators and trainers for managing and executing the operations they design. As Project Manager at Natura, he is responsible for managing all aspects of Natura’s rigging projects <br />
                </p>
                <p>
                  Also, a Wilderness Advanced First Aider, certified by the Wilderness Medicine Institute, USA. Rohit is a pure outdoorsman at heart and has rock-climbed and trekked extensively throughout the Sahyadris. <br />
                </p>


              </div>
            </div>
          </div> */}

        </div>
      </section>

      <Footer />
    </div>
  )
}
