import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../config";
import Sidebar from "../common/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { AiFillCopy, AiFillDelete } from "react-icons/ai";
import copy from "clipboard-copy";
const GalleryDashboard = () => {
  const [gallery, setgallery] = useState([]);
  const [del, setdel] = useState();

  useEffect(() => {
    getgallery();
  }, [!del]);

  const getgallery = async () => {
    try {
      const { data } = await axiosInstance.get("/api/gallery");

      setgallery(data ?? []);
    } catch (error) {}
  };

  const togglehandle = (e) => {
    e.persist();
    var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };

  const deleteimage = async (id) => {
    var answer = window.confirm("Are you sure To delete?");
    if (answer) {
      await axiosInstance.delete(`/api/gallery/${id}`, {
        headers: {
          token: localStorage.getItem("token"),
        },
      });

      alert("deleted");

      setTimeout(() => {
        setdel(!del);
      }, 100);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
        <nav className="navbar top-navbar navbar-light bg-light px-5">
          <a className="btn border-0" id="menu-btn" onClick={togglehandle}>
            <i className="bx bx-menu"></i>
          </a>
        </nav>
        {/* <!--End Top Nav --> */}
        <h3 className="text-dark p-3 ">ADMIN REVIEW DASHBOARD 💻 📱</h3>

        <div className="btn btn-secondary">
          <Link to="/admin/AddGallery" className="text-white nav-link">
            Add New Image
          </Link>
        </div>
        <div
        className="add d-flex"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            margin: "2rem",
          }}
        >
          {gallery.length > 0 &&
            gallery?.map((gallery) => (
              <>
                <div className="imageGallery">
                  <img
                    src={`${process.env.REACT_APP_IMGURL}/images/gallery/${gallery?.Image}`}
                    alt=""
                    height={150}
                    width={150}
                    loading="lazy"
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <AiFillDelete
                      color="red"
                      size={25}
                      onClick={() => deleteimage(gallery?._id)}
                    />
                    <AiFillCopy
                      color="red"
                      size={25}
                      onClick={() => {
                        copy(
                          `${process.env.REACT_APP_IMGURL}/images/gallery/${gallery?.Image}`
                        );
                        // navigator.clipboard.writeText(`${process.env.REACT_APP_IMGURL}/images/gallery/${gallery?.Image}`);
                        alert("Copied ");
                      }}
                      className="AiFillCopy"
                    />
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  );
};

export default GalleryDashboard;

const EventImage = ({ imgurl }) => (
  <div className="single-image">
    <a
      href={`${process.env.REACT_APP_IMGURL}/images/gallery/${imgurl}`}
      data-lightbox="homePortfolio"
    >
      <img
        src={`${process.env.REACT_APP_IMGURL}/images/gallery/${imgurl}`}
        // className="img-fluid"
        style={
          {
            // width: "100%",
            // height: "100%",
          }
        }
        alt={imgurl}
      />
    </a>
  </div>
);

const EventHeading = ({ heading }) => (
  <>
    <h3 style={{ color: "#fff", fontWeight: "900" }} className="pt-5">
      {heading}
    </h3>
    <hr style={{ height: "3px", color: "#fff" }} />
  </>
);
