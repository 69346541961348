import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
import Sidebar from "../common/sidebar/Sidebar";
import AdminLoader from "../components/AdminLoader";
import JoditEditor from "jodit-react";

const EditEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [eventname, seteventname] = useState();
  const [eventshortdesc, seteventshortdesc] = useState();
  const [eventlongdesc, seteventlongdesc] = useState();
  const [featuredevent, setfeaturedevent] = useState("");
  const [city, setcity] = useState();
  const [venuetype, setvenuetype] = useState();
  const [dateofevent, setdateofevent] = useState();
  const [dateofeventEnd, setdateofeventEnd] = useState();
  const [clientname, setclientname] = useState();
  // const [clienttype, setclienttype] = useState();
  const [naturateam, setnaturateam] = useState();
  const [videographerteam, setvideographerteam] = useState();
  const [photographerteam, setphotographerteam] = useState();
  const [infrateam, setinfrateam] = useState();
  const [othercredit, setothercredit] = useState();
  const [banner, setbanner] = useState();
  const [productcategory, setproductcategory] = useState([]);
  const [value, setvalue] = useState([]);
  const [catvalue, setcatvalue] = useState([]);
  const [loading, setloading] = useState(false);
  const [mainloader, setmainloader] = useState(false);
  const [endclientName, setendclientName] = useState();
  const [venueName, setvenueName] = useState();
  const [content, setContent] = useState("");
  const [Length, setLength] = useState(0);
  const [isFirst, setIsFirst] = useState(false);
  const [ClientCredit, setClientCredit] = useState();

  useEffect(() => {
    getproduct();
    getevent();
  }, []);

  const productcategoryoptions = [
    { label: "Launch", value: "Launch" },
    { label: "Unveiling Events", value: "Unveiling Events" },
    { label: "Opening Ceremonies", value: "Opening Ceremonies" },
    { label: "Weddings", value: "Weddings" },
    { label: "Celebrations", value: "Celebrations" },
    { label: "Adventure", value: "Adventure" },
    { label: "Art", value: "Art" },
    { label: "Theatre", value: "Theatre" },
    { label: "Corporate / Brand Events ", value: "Corporate Brand Events" },
    { label: "Films", value: "Films" },
    { label: "TV", value: "TV" },
    { label: "Award shows", value: "Award shows" },
    { label: "Natura Promotional events", value: "Natura Promotional events" },
    { label: "Rigging Workshop", value: "Rigging Workshop" },
  ];
  const getproduct = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/product`);
      const newproduct = data.map((r) => {
        return {
          label: r.ProductTitle,
          value: r._id,
        };
      });

      setproductcategory(newproduct);
      // setproductcategory(data)
    } catch (error) {
      alert(error.response.data);
    }
  };

  const togglehandle = (e) => {
    e.persist();
    // var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };

  const handleUserdataSubmit = async (e) => {
    e.preventDefault();
    const newvalue = value.map((ele) => ele.value);
    const categoryvalue = catvalue.map((ele) => ele.value);

    if (!eventname || eventname.trim() === "") {
      alert("Event name Required");
      return;
    }
    if (!eventshortdesc || eventshortdesc.trim() === "") {
      alert("Short Description Required");
      return;
    }

    if (!venuetype) {
      alert("Venue Type Required");
      return;
    }
    if (!newvalue || newvalue.length === 0) {
      alert("Event Product Type is Required");
      return;
    }
    if (featuredevent === "") {
      alert("Featured Event Required");
      return;
    }
    if (!dateofevent) {
      alert("Final Event Start Date Required");
      return;
    }

    try {
      const formdata = new FormData();

      if (banner) {
        if (banner.size >= 2000000) {
          alert("File Size Should Less Than 2 Mb");
          return;
        } else {
          formdata.append("eventimg", banner);
        }
      }

      formdata.append("EventName", eventname);
      formdata.append("EventShortDescription", eventshortdesc);
      formdata.append("EventLongDescription", eventlongdesc);
      formdata.append("EventType", JSON.stringify(newvalue));
      formdata.append("FeaturedEvent", featuredevent);
      formdata.append("City", city);
      formdata.append("VenueType", venuetype);
      formdata.append("DOE", dateofevent);
      if (dateofeventEnd) {
        formdata.append("EndDOE", dateofeventEnd);
      }
      formdata.append("Year", dateofevent.split("-")[0]);
      formdata.append("ClientName", clientname);
      // formdata.append("ClientType", clienttype)
      if (naturateam) {
        formdata.append("NaturaTeam", naturateam);
      }
      if (videographerteam) {
        formdata.append("VideoGrapherTeam", videographerteam);
      } else {
        formdata.append("VideoGrapherTeam", "");
      }
      if (photographerteam) {
        formdata.append("PhotoGrapherTeam", photographerteam);
      } else {
        formdata.append("PhotoGrapherTeam", "");
      }
      if (infrateam) {
        formdata.append("InfraTeam", infrateam);
      } else {
        formdata.append("InfraTeam", "");
      }
      if (othercredit) {
        formdata.append("OtherCredits", othercredit);
      } else {
        formdata.append("OtherCredits", "");
      }
      if (ClientCredit) {
        formdata.append("ClientCredit", ClientCredit);
      } else {
        formdata.append("ClientCredit", "");
      }

      console.log(
        {
          videographerteam,
          photographerteam,
          infrateam,
          othercredit,
          ClientCredit,
        },
        "hello world!"
      );

      formdata.append("EventCategory", JSON.stringify(categoryvalue));
      formdata.append("EndClientName", endclientName);
      formdata.append("VenuName", venueName);
      formdata.append("isFirst", isFirst);
      // formdata.append("content", content);

      setloading(true);

      console.log(formdata, "formdata formdata");
      const { data } = await axiosInstance.put(`/api/event/${id}`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      await axiosInstance.put(`/api/event/addContent/${id}`, {
        content,
      });

      setloading(false);

      alert(data.message);
      navigate("/admin/event");
    } catch (error) {
      setloading(false);

      console.log("eventerror", error);

      alert(error.response.data.message);
    }
  };

  const getevent = async () => {
    try {
      setmainloader(true);
      const { data } = await axiosInstance.get(`/api/event/${id}`);

      seteventname(data.EventName);
      seteventshortdesc(data.EventShortDescription);
      seteventlongdesc(data?.EventLongDescription);

      setfeaturedevent(data?.FeaturedEvent);
      setcity(data.City);
      setvenuetype(data.VenueType);
      setdateofevent(data.DOE);
      setdateofeventEnd(data?.EndDOE);
      setclientname(data.ClientName);
      setnaturateam(data.NaturaTeam);
      setvideographerteam(data.VideoGrapherTeam);
      setinfrateam(data.InfraTeam);
      setothercredit(data.OtherCredits);
      setphotographerteam(data.PhotoGrapherTeam);
      setvenueName(data.VenuName);
      setendclientName(data.EndClientName);
      setContent(data.content);
      setClientCredit(data.ClientCredit);
      setmainloader(false);
      setIsFirst(data?.isFirst);

      if (data?.EventCategory) {
        const BEArray = data.EventCategory;
        let myNewArr = [];
        productcategoryoptions.forEach((ele) => {
          if (BEArray.find((e) => e === ele.value)) {
            myNewArr.push(ele);
          }
        });

        setcatvalue(myNewArr);
      } else {
        setcatvalue([]);
      }

      if (data?.EventType) {
        const myProduct = [];
        data?.EventType.forEach((ele) => {
          myProduct.push({
            label: ele.ProductTitle,
            value: ele._id,
          });
        });
        setvalue([...myProduct]);
      } else {
        setvalue([]);
      }
    } catch (error) {
      setmainloader(false);
    }
  };

  const config = {
    uploader: {
      insertImageAsBase64URI: true,
    },
  };

  return (
    <>
      <Sidebar />

      <div className="p-1 my-container active-cont">
        {/* <!-- Top Nav --> */}
        <nav className="navbar top-navbar navbar-light bg-light px-5">
          <a className="btn border-0" id="menu-btn" onClick={togglehandle}>
            <i className="bx bx-menu"></i>
          </a>
        </nav>
        {/* <!--End Top Nav --> */}
        <h3 className="text-dark p-3">ADMIN EDIT EVENT 💻 📱</h3>

        {mainloader ? (
          <AdminLoader />
        ) : (
          <>
            <form onSubmit={handleUserdataSubmit}>
              <div
                className="row justify-content-center"
                style={{ backgroundColor: "#FDF8DB" }}
              >
                <div className="col-md-8 text-start py-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="text-start p-1">Event Name</label>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={isFirst}
                        onChange={(e) => {
                          setIsFirst(e.target.checked);
                        }}
                      />
                    </div>
                  </div>

                  <input
                    className="form-control "
                    type="text"
                    value={eventname}
                    onChange={(e) => seteventname(e.target.value)}
                    placeholder="Event Name"
                    required
                  />
                </div>
                <div className="col-md-8 text-start py-3">
                  <label>Client Name</label>
                  <input
                    className="form-control "
                    type="text"
                    value={clientname}
                    onChange={(e) => setclientname(e.target.value)}
                    placeholder="Client Name"
                    required
                  />
                </div>
                <div className="col-md-8 text-start py-3">
                  <label>End Client Name</label>
                  <input
                    className="form-control "
                    type="text"
                    value={endclientName}
                    onChange={(e) => setendclientName(e.target.value)}
                    placeholder="End Client Name"
                  />
                </div>
                <div className="col-md-8 text-start py-3">
                  <label>Product Type</label>
                  {/* <select class="form-select form-select-sm" aria-label=".form-select-sm example" onChange={(e) => setvalue(e.target.value)} required>
                                <option  >Select Event Type</option>
                                {
                                    productcategory && productcategory.map((category) => (
                                        <>
                                            <option value={category._id}>{category.ProductTitle}</option>
                                        </>
                                    ))
                                }
                            </select> */}

                  <MultiSelect
                    options={productcategory}
                    // value={value}
                    value={value}
                    onChange={(e) => {
                      setvalue(e);
                    }}
                    labelledBy="Select"
                  />
                </div>

                <div className="col-md-8 text-start py-3">
                  <label>Event Short Description</label>
                  <textarea
                    className="form-control "
                    type="text"
                    value={eventshortdesc}
                    onChange={(e) => seteventshortdesc(e.target.value)}
                    placeholder="Event Short Description"
                    required
                  />
                </div>
                {/* <div className="col-md-8 text-start py-3">
                  <label>Event Long Description</label>
                  <textarea
                    className="form-control "
                    type="text"
                    value={eventlongdesc}
                    onChange={(e) => seteventlongdesc(e.target.value)}
                    placeholder="Event Long Description"
                    
                  />
                </div> */}
                <div className="col-md-8 text-start py-3">
                  <label>Venue Name</label>
                  <input
                    className="form-control "
                    type="text"
                    value={venueName}
                    onChange={(e) => setvenueName(e.target.value)}
                    placeholder="Venue Name"
                    required
                  />
                </div>
                <div className="col-md-8 text-start py-3">
                  <label>City</label>
                  <input
                    className="form-control "
                    type="text"
                    value={city}
                    onChange={(e) => setcity(e.target.value)}
                    placeholder="Event City"
                    required
                  />
                </div>
                <div className="col-md-8 text-start py-3">
                  <label>VenueType</label>
                  <select
                    class="form-select"
                    value={venuetype}
                    onChange={(e) => setvenuetype(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option selected>Select venue Type</option>
                    <option value="both">both</option>
                    <option value="Indoor">Indoor</option>
                    <option value="Outdoor">Outdoor</option>
                  </select>
                  {/* <input className="form-control" type="text" value={featuredproject} onChange={e => setfeaturedproject(e.target.value)} placeholder="Service Description" /> */}
                </div>

                <div className="col-md-8 text-start py-3">
                  <label>Event Category</label>
                  {/* productcategoryoptions */}
                  <MultiSelect
                    options={productcategoryoptions}
                    value={catvalue}
                    onChange={setcatvalue}
                    labelledBy="Select"
                  />
                </div>

                <div className="col-md-8 text-start py-3">
                  <label>Final Event Start Date</label>
                  <input
                    className="form-control "
                    type="date"
                    value={dateofevent}
                    onChange={(e) => setdateofevent(e.target.value)}
                    placeholder="Date Of Event"
                    required
                  />
                </div>
                <div className="col-md-8 text-start py-3">
                  <label>Final Event End Date</label>
                  <input
                    className="form-control "
                    type="date"
                    value={dateofeventEnd}
                    onChange={(e) => setdateofeventEnd(e.target.value)}
                    placeholder="Date Of Event"
                  />
                </div>

                {/* <div className="col-md-8 text-start py-3">
                            <label>Year</label>
                            <input className="form-control " type="text" value={year} onChange={e => setyear(e.target.value)} placeholder="Event Year" required />
                        </div> */}
                {/* 
                        <div className="col-md-8 text-start py-3">
                            <label>ClientType</label>
                            <input className="form-control " type="text" value={clienttype} onChange={e => setclienttype(e.target.value)} placeholder="Client Type" required />
                        </div> */}

                <div className="col-md-8 text-start py-3">
                  <label>Natura Team</label>
                  <textarea
                    className="form-control "
                    type="text"
                    value={naturateam}
                    onChange={(e) => setnaturateam(e.target.value)}
                    placeholder="Natura Team"
                  />
                </div>
                <div className="col-md-8 text-start py-3">
                  <label>Photo Grapher Team</label>
                  <textarea
                    className="form-control "
                    type="text"
                    value={photographerteam}
                    onChange={(e) => setphotographerteam(e.target.value)}
                    placeholder="Photo Grapher Team"
                  />
                </div>

                <div className="col-md-8 text-start py-3">
                  <label>Video Grapher Team</label>
                  <textarea
                    className="form-control "
                    type="text"
                    value={videographerteam}
                    onChange={(e) => setvideographerteam(e.target.value)}
                    placeholder="Video Grapher Team"
                  />
                </div>

                <div className="col-md-8 text-start py-3">
                  <label>Infra Team</label>
                  <textarea
                    className="form-control "
                    type="text"
                    value={infrateam}
                    onChange={(e) => setinfrateam(e.target.value)}
                    placeholder="Infra Team"
                  />
                </div>
                <div className="col-md-8 text-start py-3">
                  <label>Client Credits </label>
                  <textarea
                    className="form-control "
                    type="text"
                    value={ClientCredit}
                    onChange={(e) => setClientCredit(e.target.value)}
                    placeholder="Other Credits"
                  />
                </div>

                <div className="col-md-8 text-start py-3">
                  <label>Other Credits </label>
                  <textarea
                    className="form-control "
                    type="text"
                    value={othercredit}
                    onChange={(e) => setothercredit(e.target.value)}
                    placeholder="Other Credits"
                  />
                </div>

                <div className="col-md-8 text-start py-3">
                  <label>Featured Event</label>
                  <select
                    class="form-select"
                    value={featuredevent}
                    onChange={(e) => setfeaturedevent(e.target.value)}
                    aria-label="Default select example"
                    required
                  >
                    <option selected>Select Featured Type</option>
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  {/* <input className="form-control" type="text" value={featuredproject} onChange={e => setfeaturedproject(e.target.value)} placeholder="Service Description" /> */}
                </div>

                <div className="col-md-8 text-start py-3">
                  <label>Image (1600 X 700 px) (2 mb size)</label>
                  <input
                    className="form-control"
                    type="file"
                    onChange={(e) => setbanner(e.target.files[0])}
                  />
                </div>
                <div className="col-md-8 text-start py-3">
                  <JoditEditor
                    // ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => {}}
                  />
                </div>

                <div
                  className="col-md-8 text-start py-3"
                  style={{ padding: "5px" }}
                >
                  <div className="d-flex flex-row">
                    {loading ? (
                      <>
                        <button className="btn btn-success" disabled>
                          Loading
                        </button>
                      </>
                    ) : (
                      <>
                        <button className="btn btn-success">Submit</button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
};

export default EditEvent;
