import React, { useEffect, useState } from "react";
import Card from "../../components/cardcomponent/Card";
import { axiosInstance } from "../../config";
import { Footer } from "../../Home/Footer";
import { Header } from "../../Home/Header";
import Topbanner from "../../components/topbanner/Topbanner";
import { BiLoader } from 'react-icons/bi'


const FeaturedEvent = () => {
  const [loader, setloader] = useState(false)
  const [event, setEvents] = useState([]);


  const getallevent = async () => {
    try {
      setloader(true)
      const { data } = await axiosInstance.get(`/api/event?FeaturedEvent=true`);
      setEvents(data)
      setloader(false)

      // setEvents(data.filter(event => event.FeaturedEvent === true));
      
      setloader(false)

    } catch (error) {
      setloader(false)
    }
  };

  useEffect(() => {
    getallevent();
  }, []);

  return (
    <>
      <>
        <Header />
        <Topbanner heading="Featured Events" height="45vh"/>

       
        <section>
          <div className="container pt-5 pb-4">
          {
          loader ? 
          <center>
            <BiLoader/>
          </center>
          
          :<>
           <Card event={event} />
          </>
        }

          </div>
        </section>
        <Footer />
      </>
    </>
  );
};

export default FeaturedEvent;
