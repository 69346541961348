import React from "react";
import { Link } from "react-router-dom";
import "./safetycarddesign.scss";

const SafetyCardService = ({ service }) => {
  return (
    <>
      <Link
        to={`/service/${service.categoryName}`}
        state={{ image: "flyingfx.jpg" }}
      >
        <div class="blog-card text-start d-flex align-items-stretch">
          <div class="meta">
            <div
              class="photo"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_IMGURL}/images/product/${service?.CategoryCardImage?service?.CategoryCardImage:service.CategoryImage})`,
              }}
            ></div>
          </div>
          <div class="description">
            <h1 className='text-black'>{service.categoryName}</h1>
            <h2>Service</h2>
            <p style={{ wordWrap: "break-word" }} className='text-black'>
              {" "}
              {service.CategoryDescription.slice(0, 95)}...
            </p>
            <p class="read-more">
              <Link
                to={`/service/${service.categoryName}`}
                state={{ image: "flyingfx.jpg" }}
              >
                Read More
              </Link>
            </p>
          </div>
        </div>
      </Link>
    </>
  );
};

export default SafetyCardService;
