import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
// import Sidebar from '../../components/sidebar/Sidebar';
import JoditEditor from "jodit-react";
import { AiFillCopy, AiTwotoneDelete } from "react-icons/ai";
import Sidebar from "../common/sidebar/Sidebar";

const AddBlog = () => {
  const navigate = useNavigate();
  const editor = useRef(null);

  const [content, setContent] = useState("");
  const [del, setdel] = useState(false);
  const [gallery, setgallery] = useState([]);
  const [productimg, setproductimg] = useState();

  const [apicall, setapicall] = useState({
    loading: false,
    error: false,
  });

  const [blog, setblog] = useState({
    heading: "",
    description: "",
    keywords: "",
    author: "",
    url: "",
  });

  useEffect(() => {
    getgallaryimg();
  }, [del]);

  const getgallaryimg = async () => {
    try {
      const { data } = await axiosInstance.get("/api/gallery");
      // console.log("data",data.gallery)

      if (data.success) {
        setgallery(data.gallery);
      }
    } catch (error) {
      console.log("err", error.response.data.message);
    }
  };

  const { heading, description, keywords, author, url } = blog;

  // console.log("blog",blog)

  const onChange = (e) => {
    setblog({ ...blog, [e.target.name]: e.target.value });
  };

  const config = {
    uploader: {
      insertImageAsBase64URI: true,
    },
  };

  const { loading } = apicall;

  const handleBlogSubmit = async (e) => {
    e.preventDefault();
    setapicall({ ...apicall, loading: true });
    try {
      e.preventDefault();

      const { data } = await axiosInstance.post("/api/blog", {
        heading,
        content,
        description,
        keywords,
        author,
        url,
      });

      if (data.success) {
        setapicall({ ...apicall, loading: false });
        alert(data.message);
      }
      // console.log("Userdata", data)
      navigate("/admin/blog");
    } catch (error) {
      setapicall({ ...apicall, loading: false });
      alert(error.response.data.message);
    }
  };

  const addimge = async (e) => {
    e.preventDefault();
    if (!productimg) {
      return alert("Please Select Image ");
    } else {
      if (productimg.size >= 2000000) {
        return alert("Image Size should be less Than 2MB");
      }
    }
    setapicall({ ...apicall, loading: true });
    try {
      const productdata = new FormData();
      productdata.append("blogimg", productimg);
      const { data } = await axiosInstance.post(`/api/gallery`, productdata, {
        headers: {
          token: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      });

      if (data.success) {
        setapicall({ ...apicall, loading: false });
        alert(data.message);
      }
      alert("Image Added Successfully");
      setdel(!del);
    } catch (error) {
      setapicall({ ...apicall, loading: false });
      console.log(error);
    }
  };

  const deleteimage = async (id) => {
    // console.log("imgs",imgs,id)
    try {
      const answer = window.confirm("Are You Sure !");
      if (answer) {
        await axiosInstance.delete(`/api/gallery/${id}`, {
          headers: {
            token: localStorage.getItem("token"),
          },
        });
        alert("deleted permanently");
      }
      setdel(!del);
    } catch (error) {
      console.log("error", error);
    }
  };

  const togglehandle = (e) => {
    e.persist();
    var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };

  return (
    <>
      <Sidebar />
      <div className="p-1 my-container active-cont">
        <nav className="navbar top-navbar navbar-light bg-light px-5">
          <a className="btn border-0" id="menu-btn" onClick={togglehandle}>
            <i className="bx bx-menu"></i>
          </a>
        </nav>

        <h3 className="text-dark p-3"> ADMIN BLOG</h3>
        <div className="row mx-2 " style={{ backgroundColor: "#FDF8DB" }}>
          <form onSubmit={handleBlogSubmit}>
            <div class="mb-1">
              <label for="exampleInputEmail1" class="form-label">
                Heading
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                name="heading"
                onChange={onChange}
                aria-describedby="emailHelp"
              />
            </div>
          
            <div class="mb-1">
              <label for="exampleInputEmail1" class="form-label">
                Description
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                name="description"
                onChange={onChange}
                aria-describedby="emailHelp"
              />
            </div>
            <div class="mb-1">
              <label for="exampleInputEmail1" class="form-label">
                Keywords
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                name="keywords"
                onChange={onChange}
                aria-describedby="emailHelp"
              />
            </div>
            <div class="mb-1">
              <label for="exampleInputEmail1" class="form-label">
                Author
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                name="author"
                onChange={onChange}
                aria-describedby="emailHelp"
              />
            </div>
            <div class="mb-1">
              <label for="exampleInputEmail1" class="form-label">
                Url
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                name="url"
                onChange={onChange}
                aria-describedby="emailHelp"
              />
            </div>
            <div class="mb-1">
              <label for="exampleInputEmail1" class="form-label">
                Content
              </label>
              <JoditEditor
                ref={editor}
                config={config}
                value={content}
                // config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => {}}
              />
            </div>


            {loading ? (
              <>
                <button
                  type="submit"
                  class="btn mybtn btn btn-success text-white mt-2 disabled"
                >
                  <div class="spinner-border" role="status">
                    {/* <span class="sr-only">Loading...</span> */}
                  </div>
                </button>
              </>
            ) : (
              <button type="submit" class="btn mybtn btn btn-success text-white mt-2">
                Submit
              </button>
            )}

            {/* <button type="submit" class="btn btn btn-success text-white">Submit</button> */}
          </form>
        </div>
      </div>
    </>
  );
};

export default AddBlog;
