import React, { useState, useEffect } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { axiosInstance } from "../config";
import { useParams, Link } from "react-router-dom";

import "./Home.css";
import MainLoader from "../components/mainloader/MainLoader";
// import Breadcrumbs from "../pages/Breadcrumbs/Breadcrumbs";

export const Service = () => {
  //
  //

  // let location = useLocation();

  const [service, setservice] = useState();
  const [product, setproduct] = useState();
  const [loader, setloader] = useState(false);
  const [isProduct, setIsProduct] = useState(false);

  const { name } = useParams();

  useEffect(() => {
    getservice();
  }, [name]);

  const getservice = async () => {
    try {
      setloader(true);
      const { data } = await axiosInstance.get(
        `/api/category/singleservice/${name}`
      );
      setservice(data);
      getproduct(data);
      setloader(false);
      // await setBreadcrumbs(data.categoryName);
    } catch (error) {
      setloader(false);

      // alert(error.response.data)
    }
  };

  const getproduct = async (produt) => {
    try {
      // setloader(true)
      const { data = [] } = await axiosInstance.get(
        `/api/product?productType=${produt._id}`
      );
      if (data.length === 0) {
        setIsProduct(true);
      } else {
        setIsProduct(false);
      }

      setproduct(data);
      // setloader(false)
    } catch (error) {
      // setloader(false)
    }
  };

  let productname;

  if (name === "Flying Fx") {
    productname = "Types of Flying Fx";
  } else if (name === "Aerial Entries") {
    productname = "Types of Aerial Entries";
  } else if (name === "Aerial Acts") {
    productname = "Signature Aerial Acts";
  } else if (name === "Adventure") {
    productname = "Adventure Offerings";
  } else {
    productname = "Products";
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="colorSection">
      <Header />

      {loader ? (
        <MainLoader />
      ) : (
        <>
          <div
            class="about_banner1 d-flex justify-content-center align-items-center d-lg-block d-none "
            style={{
              backgroundImage:
                "linear-gradient(-70deg,rgb(254,189,20,0.8)55%, rgb(254,189,21,0.0)56%)," +
                `url(${process.env.REACT_APP_IMGURL}/images/product/${service?.CategoryImage})`,
            }}
          >
            <div class="container ">
              <div class="main_title_3 py-5 col-md-6 text-md-start text-center  ">
                <h2 class="text-white ">
                  <b>{service?.categoryName}</b>
                </h2>
                {/* <span>
                  <em></em>
                </span> */}
                <p className="pt-3 text-dark pe-5">
                  {service?.CategoryDescription}
                </p>
                {/* <p class="pt-3">{content}</p> */}
              </div>
            </div>
          </div>
          <div
            class="about_banner1 d-flex justify-content-center align-items-center d-lg-none d-block"
            style={{
              backgroundImage:
                "linear-gradient(0deg,rgb(254,189,20,0.8)0%, rgb(254,189,21,0.0)56%)," +
                `url(${process.env.REACT_APP_IMGURL}/images/product/${service?.CategoryImage})`,
            }}
          >
            <div class="container ">
              <div class=" py-5 col-md-6 text-md-start text-center mobile_header_height">
                <h2 class="text-white ">
                  <b>{service?.categoryName}</b>
                </h2>
                <span>
                  <em> </em>
                </span>
                <p className="pt-3">{service?.CategoryDescription}</p>

                {/* <p class="pt-3">We wish to introduce ourselves as one of the contractors for carrying out the HVAC &amp; R projects on turnkey basis. The Company was founded in 1984 and has grown manifold during the years.</p> */}
              </div>
            </div>
          </div>
          {service && service?.CategoryVideo && (
            <div
              style={{ backgroundColor: "rgb(230, 230, 220)" }}
              className="py-5"
            >
              <section class="block oneB offticker ">
                <video
                  class="dVideo"
                  autoPlay
                  loop
                  muted
                  id="bgvid"
                  width="100%"
                  style={{ objectFit: "cover", maxHeight: "60vh" }}
                >
                  {/* <source src="https://digisidekick.com/naturavideo.mp4" type="video/mp4" /> */}
                  {/* https://natura.co.in/video/1676621074853-1.mp4 */}
                  <source
                    src={`${process.env.REACT_APP_IMGURL}/video/${service?.CategoryVideo}`}
                    type="video/mp4"
                  />
                </video>
              </section>
            </div>
          )}
          {service && service?.YoutubeUrl && (
            <div
              style={{ backgroundColor: "rgb(230, 230, 220)" }}
              className="py-5 "
            >
              <section class="block oneB offticker serviceyoutubevideo">
                {service?.YoutubeUrl && (
                  <iframe
                    iframe
                    width="100%"
                    height="100%"
                    // className="serviceyoutubevideo"
                    style={{
                      objectFit: "cover",
                      minHeight: "100%",
                      maxHeight: "100%",
                    }}
                    src={`${service?.YoutubeUrl}`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                )}
              </section>
            </div>
          )}

          <section id="why-us" class="why-us pb-5">
            <div className="container ">
              <div class="d-flex ">
                <div class="section-title text-start col-lg-6 text-muted pt-5">
                  <h2>
                    {/* <p style={{ color: "#fff", display: "inline-block" }}>
                      {" "}
                      Our{" "}
                    </p>{" "} */}

                    <span style={{ color: "#fff" }}> {productname}</span>
                  </h2>
                </div>
              </div>

              {/* <Breadcrumbs/> */}

              <div className="row " id="down">
                {product &&
                  product
                    .filter((f) => f.productType.categoryName === name)
                    .map((c, i) => (
                      <>
                        <div className="col-lg-4 col-md-6 col-12 py-4">
                          <Link to={`/singleproject/${c?._id}`}>
                            {/* <div className="quaitybg3" style={{  backgroundImage: "url(" + `https://natura.co.in/images/service/1659692826905_Block1.jpg` + ")"}}> */}
                            <div
                              className="quaitybg77 rounded"
                              style={{
                                backgroundImage: `url(${process.env.REACT_APP_IMGURL}/images/product/${c?.ProductImage})`,
                              }}
                            >
                              <div className="innerquality77 d-flex justify-content-center text-start flex-column p-5">
                                <div>
                                  <p className=" text-muted pe-1 h3 ">
                                    {c?.ProductTitle}
                                  </p>
                                  <h6 className="text-white  pe-1 ">
                                    {c?.ProductShortDescription}
                                  </h6>
                                  {/* <h2 className="text-white text-muted"><b>{c?.serviceType[0]?.categoryName}</b></h2> */}
                                  <a href="#">
                                    <i
                                      class="fas fa-long-arrow-alt-right text-white"
                                      style={{ fontSize: "2rem" }}
                                    ></i>
                                  </a>
                                </div>
                              </div>
                              <div
                                className="col-md-12 col-lg-12 col-12 py-4 bottom77"
                                style={{
                                  backgroundColor: "rgba(0,0,0,0.6)",
                                  position: "absolute",
                                  bottom: "0",
                                }}
                              >
                                <p className="text-white px-3 pt-1">
                                  <b>{c.ProductTitle}</b>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    ))}
              </div>
              {/* {
                isProduct && <h3 style={{
                  textAlign:"center",
                  color:"white"
              }}>To be Updated on Website</h3>
              } */}
            </div>
          </section>

          {/* top nevents-- */}
          {/* Our Services */}
          {/* <section id="why-us" class="why-us events py-5" >
        <div class="container">

          <div class="d-flex ">
            <div class="section-title text-start col-lg-6 text-muted">
              <h2 className="text-dark">Our <span> Work</span></h2>
              <p>We have been a part of numerous amazing events on Earth and enhanced their entertainment value and excitement for our clients.</p>
            </div>
          </div>

          <div class="row">
            {
              service && service.map((c, i) => (
                <>

                  <div class="col-md-4 mt-4 mt-lg-0">
                    <Link to={`/singleservice/${c.ServiceName}`}>
                      <div class="card-wrapper">
                        <figure class="card border-0" style={{ backgroundColor: '#FEBD15' }}>
                          <img src={`${process.env.REACT_APP_IMGURL}/images/service/${c?.ServiceImage}`} className="img-fluid w-100" />
                          <figcaption><a href="#" className="text-white">{c?.ServiceHeading} →</a> </figcaption>
                        </figure>
                      </div>
                    </Link>
                  </div>
                </>
              ))
            }

          </div>

        </div>
      </section> */}
          {/* Our Services */}
          {/* top nevents-- */}

          {/* Card */}
        </>
      )}

      <Footer />
    </div>
  );
};
