import React, { useEffect } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { useParams, Link } from "react-router-dom";
import "./Home.css";
import { RIG_TECH_DATA } from "../Rig Tech/RigTech";
export const Rig_Tech_Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="colorSection">
      <Header />

      <>
        <div
          class="about_banner1 d-flex justify-content-center align-items-center d-lg-block d-none "
          style={{
            backgroundImage:
              "linear-gradient(-70deg,rgb(254,189,20,0.8)55%, rgb(254,189,21,0.0)56%)," +
              `url(${process.env.REACT_APP_IMGURL}/images/product/1691390311189_1690261332862_rig.png)`,
          }}
        >
          <div class="container ">
            <div class="main_title_3 py-5 col-md-6 text-md-start text-center  ">
              <h2 class="text-white ">
                <b>Rig Tech</b>
              </h2>
              {/* <span>
                  <em></em>
                </span> */}
              <p className="pt-3 text-dark pe-5">
                Natura Rigtech is the exclusive provider for some of the world’s
                most advanced stage rigging and safety equipment in India. As
                India's first and only professional aerial rigging company, our
                emphasis on safety and adherence to global rigging standards has
                made us the ideal choice as Eventions Products's Exclusive
                Distributors. Natura is also Broadweigh's exclusive distributor
                partner in India.
              </p>
              {/* <p class="pt-3">{content}</p> */}
            </div>
          </div>
        </div>
        <div
          class="about_banner1 d-flex justify-content-center align-items-center d-lg-none d-block"
          style={{
            backgroundImage:
              "linear-gradient(0deg,rgb(254,189,20,0.8)0%, rgb(254,189,21,0.0)56%)," +
              `url(${process.env.REACT_APP_IMGURL}/images/product/1691390311189_1690261332862_rig.png)`,
          }}
        >
          <div class="container ">
            <div class=" py-5 col-md-6 text-md-start text-center mobile_header_height">
              <h2 class="text-white ">
                <b>Rig Tech</b>
              </h2>
              <span>
                <em> </em>
              </span>
              <p className="pt-3">
                Natura Rigtech is the exclusive provider for some of the world’s
                most advanced stage rigging and safety equipment in India. As
                India's first and only professional aerial rigging company, our
                emphasis on safety and adherence to global rigging standards has
                made us the ideal choice as Eventions Products's Exclusive
                Distributors. Natura is also Broadweigh's exclusive distributor
                partner in India.
              </p>

              {/* <p class="pt-3">We wish to introduce ourselves as one of the contractors for carrying out the HVAC &amp; R projects on turnkey basis. The Company was founded in 1984 and has grown manifold during the years.</p> */}
            </div>
          </div>
        </div>

        <section id="why-us" class="why-us pb-5">
          <div className="container ">
            <div class="d-flex ">
              <div class="section-title text-start col-lg-6 text-muted pt-5">
                <h2>
                  <span style={{ color: "#fff" }}>Products</span>
                </h2>
              </div>
            </div>

            {/* <Breadcrumbs/> */}

            <div className="row " id="down">
              {RIG_TECH_DATA &&
                RIG_TECH_DATA.map((c, i) => (
                  <>
                    <div className="col-lg-4 col-md-6 col-12 py-4">
                      <Link to={`/service/rig_tech/${c?.url}`}>
                        <div
                          className="quaitybg77 rounded"
                          style={{
                            backgroundImage: `url(${c?.banner_image})`,
                          }}
                        >
                          <div className="innerquality77 d-flex justify-content-center text-start flex-column p-5">
                            <div>
                              <p className=" text-muted pe-1 h3 ">
                                {c?.productName}
                              </p>
                              <h6 className="text-white  pe-1 ">
                                {c?.cart_description}
                              </h6>
                              {/* <h2 className="text-white text-muted"><b>{c?.serviceType[0]?.categoryName}</b></h2> */}
                              <a href="#">
                                <i
                                  class="fas fa-long-arrow-alt-right text-white"
                                  style={{ fontSize: "2rem" }}
                                ></i>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-md-12 col-lg-12 col-12 py-4 bottom77"
                            style={{
                              backgroundColor: "rgba(0,0,0,0.8)",
                              position: "absolute",
                              bottom: "0",
                            }}
                          >
                            <p className="text-white px-3 pt-1">
                              <b> {c?.productName}</b>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </section>
      </>

      <Footer />
    </div>
  );
};
