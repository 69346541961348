import "./testimonial.css";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../config";
import MainTestimonial from "../maintestMonial/MainTestimonial";
import VideoTestimonial from "../maintestMonial/VideoTestimonial";

const TestimoniaCarousal = () => {
  const [testimonial, settestimonial] = useState();

  useEffect(() => {
    gettestimonial();
  }, []);

  const gettestimonial = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/api/testimonial?FeaturedTestimonials=true&testimonialType=Video`
      );
      
      settestimonial(data);
    } catch (error) {
      
    }
  };

  return (
    <>
      <MainTestimonial
        testimonial={testimonial}
        Component={VideoTestimonial}
        phoneitems={3}
      />
    </>
  );
};

export default TestimoniaCarousal;
