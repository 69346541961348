import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { axiosInstance } from "../../../config";
// import Sidebar from "../../components/sidebar/Sidebar";
import { axiosInstance } from "../../config";
import Sidebar from "../common/sidebar/Sidebar";

const EditTeam = () => {
  const navigate = useNavigate();

  const [apicall, setapicall] = useState({
    loading: false,
    error: false,
  });

  const { loading, error } = apicall;

  const { id } = useParams();

  const [Team, setTeam] = useState({
    Name: "",
    Position: "",
    Linkedin: "",
    description: "",
    Phone: "",
    Email: "",
    sort: -1,
  });

  const onChange = (e) => {
    setTeam({ ...Team, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getTeam();
  }, []);

  const getTeam = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/Team/${id}`);
      if (data.success) {
        setTeam(data.Teamdetails);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const handleBlogSubmit = async (e) => {
    try {
      e.preventDefault();
      setapicall({ ...apicall, loading: true });

      const { data } = await axiosInstance.put(`/api/Team/${id}`, Team);

      if (data.success) {
        setapicall({ ...apicall, loading: false });
        navigate("/admin/Team");
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const togglehandle = (e) => {
    e.persist();
    var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };

  return (
    <>
      <Sidebar />
      <div className="p-1 my-container active-cont">
        {/* <!-- Top Nav --> */}
        <nav className="navbar top-navbar navbar-light bg-light px-5">
          <a className="btn border-0" id="menu-btn" onClick={togglehandle}>
            <i className="bx bx-menu"></i>
          </a>
        </nav>
        {/* <!--End Top Nav --> */}
        <h3 className="text-dark p-3">ADMIN EDIT BLOG 💻 📱</h3>

        <div className="row mx-2 " style={{ backgroundColor: "#FDF8DB" }}>
          <form onSubmit={handleBlogSubmit}>
            <div class="mb-1">
              <label for="exampleInputEmail1" class="form-label">
                Name
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                name="Name"
                value={Team.Name}
                onChange={onChange}
                aria-describedby="emailHelp"
              />
            </div>
            <div class="mb-1">
              <label for="exampleInputEmail1" class="form-label">
                Phone
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                name="Phone"
                onChange={onChange}
                value={Team.Phone}
                aria-describedby="emailHelp"
              />
            </div>
            <div class="mb-1">
              <label for="exampleInputEmail1" class="form-label">
                Email
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                name="Email"
                onChange={onChange}
                value={Team.Email}
                aria-describedby="emailHelp"
              />
            </div>
            <div class="mb-1">
              <label for="exampleInputEmail1" class="form-label">
                Linkedin
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                name="Linkedin"
                onChange={onChange}
                value={Team.Linkedin}
                aria-describedby="emailHelp"
              />
            </div>
            <div class="mb-1">
              <label for="exampleInputEmail1" class="form-label">
                Position
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                name="Position"
                onChange={onChange}
                value={Team.Position}
                aria-describedby="emailHelp"
              />
            </div>

            <div class="mb-1">
              <label for="exampleInputEmail1" class="form-label">
                Description
              </label>

              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                name="description"
                onChange={onChange}
                value={Team.description}
                aria-describedby="emailHelp"
              />
            </div>

            <div class="mb-1">
              <label for="exampleInputEmail1" class="form-label">
              sort
              </label>

              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                name="sort"
                onChange={onChange}
                value={Team.sort}
                aria-describedby="emailHelp"
              />
            </div>

            {loading ? (
              <>
                <button
                  type="submit"
                  class="btn mybtn btn btn-success text-white mt-2 disabled"
                >
                  <div class="spinner-border" role="status">
                    {/* <span class="sr-only">Loading...</span> */}
                  </div>
                </button>
              </>
            ) : (
              <button
                type="submit"
                class="btn mybtn  btn-success text-white mt-2"
              >
                Submit
              </button>
            )}

            {/* <button type="submit" class="btn btn btn-success text-white">Submit</button> */}
          </form>
          {/* <div className="toogler"><h3>Add Review</h3></div> */}
        </div>
      </div>
    </>
  );
};

export default EditTeam;
