// Single Project
import React, { useState } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../config";
import { useEffect } from "react";
import Card from "../components/cardcomponent/Card";
import Breadcrumbs from "../pages/Breadcrumbs/Breadcrumbs";

export const Singleservice = () => {
  const { id } = useParams();
  let location = useLocation();
  const [isProduct, setIsProduct] = useState(false);
  const [product, setproduct] = useState();
  const [event, setevent] = useState();
  const [filteredevent, setfilteredevent] = useState();
  const [eventcategory, seteventcategory] = useState();
  const [eventyear, seteventyear] = useState();
  const [eventype, seteventype] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    getproduct();
    getevent();
  }, [id]);

  useEffect(() => {
    if (
      eventcategory === undefined &&
      eventyear === undefined &&
      eventype === undefined
    ) {
      setfilteredevent(event);
    } else if (
      eventcategory !== undefined &&
      eventyear !== undefined &&
      eventype !== undefined
    ) {
      setfilteredevent(
        event?.filter(
          (f) =>
            f?.EventCategory.some((e) => e === eventcategory) &&
            f?.Year === eventyear &&
            f?.VenueType === eventype
        )
      );
    } else if (
      eventcategory !== undefined &&
      eventyear === undefined &&
      eventype === undefined
    ) {
      setfilteredevent(
        event?.filter((f) => f?.EventCategory?.some((e) => e === eventcategory))
      );
    } else if (
      eventcategory === undefined &&
      eventyear !== undefined &&
      eventype === undefined
    ) {
      setfilteredevent(event?.filter((f) => f?.Year === eventyear));
    } else if (
      eventcategory === undefined &&
      eventyear !== undefined &&
      eventype !== undefined
    ) {
      setfilteredevent(
        event?.filter((f) => f?.Year === eventyear && f?.VenueType === eventype)
      );
    } else if (
      eventcategory === undefined &&
      eventyear === undefined &&
      eventype !== undefined
    ) {
      setfilteredevent(event?.filter((f) => f?.VenueType === eventype));
    } else if (
      eventcategory !== undefined &&
      eventyear !== undefined &&
      eventype === undefined
    ) {
      setfilteredevent(
        event?.filter(
          (f) =>
            f?.Year === eventyear &&
            f?.EventCategory?.some((e) => e === eventcategory)
        )
      );
    } else if (
      eventype !== undefined &&
      eventcategory !== undefined &&
      eventyear === undefined
    ) {
      setfilteredevent(
        event?.filter(
          (f) =>
            f?.VenueType === eventype &&
            f?.EventCategory?.some((e) => e === eventcategory)
        )
      );
    } else {
      setfilteredevent(event);
    }
  }, [eventcategory, eventyear, eventype]);

  const resetfilter = () => {
    seteventcategory(undefined);
    seteventyear(undefined);
    seteventype(undefined);
    setfilteredevent(event);
  };

  const getproduct = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/product/${id}`);
      setproduct(data);

      // setBreadcrumbs(data.ProductTitle);
    } catch (error) {}
  };

  const getevent = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/event/filterevent/${id}`);
      setevent(data);
      setfilteredevent(data);
      if (data.length === 0) {
        setIsProduct(true);
      } else {
        setIsProduct(false);
      }
    } catch (error) {}
  };
  // const setBreadcrumbs = async (nameData) => {
  //   const breadcrumbs = JSON.parse(localStorage.getItem("breadcrumbs"));
  //

  //   const index = breadcrumbs.findIndex((e) => e.name === nameData);
  //
  //   if (index !== -1) {
  //     breadcrumbs.splice(1, index);
  //   }
  //   if (breadcrumbs.length < 5) {
  //
  //      breadcrumbs.push({ name:nameData, path: location.pathname })
  //

  //    await localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
  //   } else {
  //
  //    breadcrumbs.shift()
  //     await  localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
  //     breadcrumbs.push({ name:nameData, path: location.pathname });
  //     await localStorage.setItem(
  //       "breadcrumbs",
  //       JSON.stringify(breadcrumbs)
  //     );
  //   }
  // };

  return (
    <div>
      <Header />

      {/* <section className="product_banner d-flex justify-content-start align-items-center " style={{backgroundImage: `url(${require(`../../../api/uploadFiles/${product?.productImage}`)})`}}> */}
      <section
        className="product_banner d-flex justify-content-start align-items-md-center align-items-end"
        style={{
          height: "80vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage:
            "linear-gradient(-70deg, rgba(254, 189, 20, 0.8) 55%, rgba(254, 189, 21, 0) 56%)," +
            `url(${process.env.REACT_APP_IMGURL}/images/product/${product?.ProductImage})`,

          // backgroundImage:
          //   "url(" +
          //   `${process.env.REACT_APP_IMGURL}/images/product/${product?.ProductImage}` +
          //   ")",
        }}
      >
        {/* <div
          className="text-start text-white px-5 col-md-7 "
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <h1 style={{ fontSize: "3rem" }}>
            <b>{product?.ProductTitle}</b>
          </h1>
          <p>{product?.ProductDescription}</p>
        </div> */}
        <div class="container ">
          <div class="main_title_3 py-5 col-md-6 text-md-start text-center ">
            <h2 class="text-white ">
              <b>{product?.ProductTitle}</b>
            </h2>
            <span>
              <em></em>
            </span>
            <p className="pt-3 text-dark pe-5">{product?.ProductDescription}</p>
            {/* <p class="pt-3">{content}</p> */}
          </div>
        </div>
      </section>

      {product && product.ProductVideo && (
        <div style={{ backgroundColor: "rgb(230, 230, 220)" }} className="py-5">
          {/* <Videosection url={`${process.env.REACT_APP_IMGURL}/video/${service?.CategoryVideo}`} /> */}

          {/* <div class=" text-start col-lg-12 text-dark" >
            {
              service?.CategoryDescription
            }
           
          </div> */}

          <section class="block oneB offticker service_video_class">
            <video
              class="dVideo "
              autoPlay
              loop
              muted
              id="bgvid"
              width="100%"
              // style={{ objectFit: "cover", maxHeight: "60vh" }}
            >
              {/* <source src="https://digisidekick.com/naturavideo.mp4" type="video/mp4" /> */}
              {/* https://natura.co.in/video/1676621074853-1.mp4 */}
              <source
                src={`${process.env.REACT_APP_IMGURL}/videos/product/${product?.ProductVideo}`}
                type="video/mp4"
              />
            </video>
          </section>
        </div>
      )}
      {product && product?.YoutubeUrl && (
        <div style={{ backgroundColor: "rgb(230, 230, 220)" }} className="py-5">
          <section class=" oneB offticker ">
            {product?.YoutubeUrl && (
              <iframe
                iframe
                width="100%"
                // height="60%"
                style={{
                  objectFit: "cover",
                  minHeight: "70vh",
                  maxHeight: "90vh",
                }}
                src={`${product?.YoutubeUrl}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            )}
          </section>
        </div>
      )}

      {/* Service Long Desc */}
      {/* <section style={{ backgroundColor: "#E6E6DC" }}>
       
        <div className="container pb-5" id="longdec">
          <p className="text-start ">{product?.ProductDescription}</p>
        </div>
      </section> */}

      {/* Selectbox Design */}

      {/* <section className="py-5" style={{ backgroundColor: "rgb(204, 204, 204)" }}> */}
      {/* <section id="why-us" className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <p className="text-start text-white">
                  <b>Filter By : -</b>
                </p>
                <div className="col-md-4 p-2">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    value={eventype}
                    onChange={(e) => seteventype(e.target.value)}
                  >
                    <option>Venue Type</option>
                    <option value="Indoor">Indoor</option>
                    <option value="Outdoor">Outdoor</option>
                  </select>
                </div>
                <div className="col-md-4 p-2">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    value={eventcategory}
                    onChange={(e) => seteventcategory(e.target.value)}
                  >
                    <option>Event Category</option>
                    <option value="Launch">Launch</option>
                    <option value="Unveiling Events">Unveiling Events</option>
                    <option value="Grand Opening Ceremonies">
                      Grand Opening Ceremonies
                    </option>
                    <option value="Weddings">Weddings</option>
                    <option value="Celebrations">Celebrations</option>
                    <option value="Nature">Nature</option>
                    <option value="Adventure">Adventure</option>
                    <option value="Outdoors">Outdoors</option>
                    <option value="Arts">Arts</option>
                    <option value="Music">Music</option>
                    <option value="Dance">Dance</option>
                    <option value="Culture">Culture</option>
                    <option value="Corporate">Corporate</option>
                    <option value="Brand Events">Brand Events</option>
                    <option value="Films">Films</option>
                    <option value="T.V">T.V</option>
                    <option value="Award shows">Award shows</option>
                    <option value="Natura Promotional events">
                      Natura Promotional events
                    </option>
                    <option value="Natura Training">Natura Training</option>
                    
                  </select>
                </div>
                <div className="col-md-4 p-2  ">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => seteventyear(e.target.value)}
                  >
                    <option selected hidden value="">
                      Year
                    </option>
                    {event &&
                      [...new Set(event?.map((x) => x?.Year))].map((e) => (
                        <option value={e}>{e}</option>
                      ))}
                    
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-1 d-flex justify-content-center align-items-center">
              
            
            </div>
          </div>
          {!filteredevent ? (
            <p>
              <b>Loading...</b>
            </p>
          ) : filteredevent?.length === 0 ? (
            <p className="py-5">
              <b> To be Updated on Website </b>
            </p>
          ) : (
            <Card event={filteredevent} />
          )}
        </div>
      </section> */}

      <section style={{ backgroundColor: "rgb(204, 204, 204)" }}>
        <div className="container pb-4 pt-5">
          {!filteredevent ? (
            <h1>Loading</h1>
          ) : filteredevent?.length === 0 ? (
            <p className="text-center"> To be Updated on Website </p>
          ) : (
            <>
              <div class="section-title d-flex justify-content-center text-center mb-5">
                <h2 className="text-dark text-center ">
                  Our <span> Projects</span>
                </h2>
              </div>
              {isProduct && (
                <h3
                  style={{
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  To be Updated on Website
                </h3>
              )}

              <Card event={filteredevent} />
            </>
          )}
        </div>
      </section>

      <Footer />
    </div>
  );
};
