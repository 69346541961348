import React from "react";
import EquipmentImg from "../../images/safetypage/education.jpeg";

const Education = () => {
  return (
    <section
      class="reasons"
      id="safety"
      className="py-5"
      style={{ backgroundColor: "#616164" }}
    >
      <div class="container">
        <div class="row d-flex ">
          <div
            class="col-xl-6 col-lg-6 d-flex align-items-streatch "
            style={{ borderRadius: "20px" }}
          >
            <img
              className="d-none d-lg-block img-fluid"
              src={EquipmentImg}
              alt=""
              loading="lazy"
            />
          </div>
          {/* <div class="col-xl-1 col-lg-1"></div> */}
          <div class="col-xl-6 col-lg-6">
            <div class="reasons__right ProcessBar p-3 ml-3">
              <div class="section-title text-left">
                {/* <span class="section-title__tagline">Reasons why we are best</span> */}
                <h2 class="section-title__title text-white">
                  <b style={{ color: "#FEBD15" }}>Education</b> And{" "}
                  <b style={{ color: "#FEBD15" }}>Consulting</b>
                  <hr
                    style={{
                      marginTop: "0px",
                      height: "2px",
                    }}
                  />
                </h2>
              </div>
              <p
                class="reasons__text text-white"
                style={{
                  textAlign: "left",
                  // lineHeight: "2rem",
                }}
              >
                As a 'Rigging Safety Consultant' we ensure the safety of the
                audience, performers, and crew. We work with the agency, the
                onsite team, and the vendors to identify and mitigate all
                potential risks. We ensure that proper infrastructure and
                materials are used, highest personal safety practises are
                followed while working, and secondary back-ups secure all of our
                rigging setups, whether they are aerial performances or heavy
                props suspended in the air. Set the standard, monitor execution,
                double-check systems and backups, and cover every possible
                risk... Absolute safety is essential!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Education;
