import React from "react";
import "./maintestimonial.css";

const ImageTestimonial = ({ p }) => {
  return (
    <>
      <div className="px-4 ">
      
      <img src={p} alt="name" className="img-fluid" />
      </div>
    </>
  );
};

export default ImageTestimonial;
