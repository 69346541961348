import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../common/sidebar/Sidebar";
import { axiosInstance } from "../../config";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import JoditEditor from "jodit-react";

const AddEvent = () => {
  const navigate = useNavigate();
  const editor = useRef(null);
  const [eventname, seteventname] = useState();
  const [content, setContent] = useState("");
  const [eventshortdesc, seteventshortdesc] = useState();
  const [eventlongdesc, seteventlongdesc] = useState();
  const [featuredevent, setfeaturedevent] = useState();
  const [isFirst, setIsFirst] = useState(false);
  const [city, setcity] = useState();
  const [venuetype, setvenuetype] = useState();
  const [dateofevent, setdateofevent] = useState();
  const [dateofeventEnd, setdateofeventEnd] = useState();
  const [clientname, setclientname] = useState();
  const [naturateam, setnaturateam] = useState();
  const [videographerteam, setvideographerteam] = useState();
  const [photographerteam, setphotographerteam] = useState();
  const [infrateam, setinfrateam] = useState();
  const [othercredit, setothercredit] = useState();
  const [banner, setbanner] = useState();
  const [productcategory, setproductcategory] = useState([]);
  const [value, setvalue] = useState([]);
  const [catvalue, setcatvalue] = useState([]);
  const [loading, setloading] = useState(false);
  const [endclientName, setendclientName] = useState();
  const [venueName, setvenueName] = useState();
  const [ClientCredit, setClientCredit] = useState();


  //
  //

  //

  // const newvalue = [...new Set(value?.map=>)]

  //

  // const productcategoryoptions = [
  //   { label: "Launch", value: "Launch" },
  //   { label: "Unveiling Events", value: "Unveiling Events" },
  //   { label: "Opening Ceremonies", value: "Opening Ceremonies" },
  //   { label: "Weddings", value: "Weddings" },
  //   { label: "Celebrations", value: "Celebrations" },
  //   { label: "Nature", value: "Nature" },
  //   { label: "Adventure", value: "Adventure" },
  //   { label: "Outdoors", value: "Outdoors" },
  //   { label: "Arts", value: "Arts" },
  //   { label: "Music", value: "Music" },
  //   { label: "Dance", value: "Dance" },
  //   { label: "Culture", value: "Culture" },
  //   { label: "Corporate", value: "Corporate" },
  //   { label: "Brand Events", value: "Brand Events" },
  //   { label: "Films", value: "Films" },
  //   { label: "T.V", value: "T.V" },
  //   { label: "Award shows", value: "Award shows" },
  //   { label: "Natura Promotional events", value: "Natura Promotional events" },
  //   { label: "Natura Training", value: "Natura Training" },
  // ];
  const productcategoryoptions = [
    { label: "Launch", value: "Launch" },
    { label: "Unveiling ", value: "Unveiling Events" },
    { label: "Opening Ceremonies", value: "Opening Ceremonies" },
    { label: "Weddings", value: "Weddings" },
    { label: "Celebrations", value: "Celebrations" },
    { label: "Adventure", value: "Adventure" },
    { label: "Art", value: "Art" },
    { label: "Theatre", value: "Theatre" },
    { label: "Corporate and Brand  ", value: "Corporate Brand Events" },
    { label: "Films", value: "Films" },
    { label: "TV", value: "TV" },
    { label: "Ads", value: "Ads" },
    { label: "Award shows", value: "Award shows" },
    { label: "Promotional Events", value: "Natura Promotional events" },
    { label: "Rigging Workshop", value: "Rigging Workshop" },
    
    
  ];

  useEffect(() => {
    getproduct();
  }, []);

  const config = {
    uploader: {
      insertImageAsBase64URI: true,
    },
  };

  const getproduct = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/product`);

      const newproduct = data.map((r) => {
        return {
          label: r.ProductTitle,
          value: r._id,
        };
      });

      setproductcategory(newproduct);
    } catch (error) {
      alert(error.response.data);
    }
  };

  const togglehandle = (e) => {
    e.persist();
    // var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };

  const handleUserdataSubmit = async (e) => {
    e.preventDefault();

    const newvalue = value.map((e) => e.value);
    const categoryvalue = catvalue.map((e) => e.value);

    //

    if (!eventname || eventname.trim() === "") {
      alert("Event name Required");
      return;
    }
    if (!eventshortdesc || eventshortdesc.trim() === "") {
      alert("Short Description Required");
      return;
    }

    if (!venuetype) {
      alert("Venue Type Required");
      return;
    }
    if (!newvalue || newvalue.length === 0) {
      alert("Event Product Type is Required");
      return;
    }
    if (!featuredevent) {
      alert("Featured Event Required");
      return;
    }
    // if (!endclientName) {
    //   alert("End Client Name Required");
    //   return;
    // }
    if (!dateofevent) {
      alert("Final Event Start Date Required");
    }
    if (!banner) {
      alert("Event Image Required");
      return;
    }
    if (banner && banner.size >= 2000000) {
      alert("Event Image size should be less than 2Mb");
      return;
    }

    try {
      setloading(true);
      const formdata = new FormData();
      formdata.append("EventName", eventname);
      formdata.append("EventShortDescription", eventshortdesc);
      formdata.append("EventLongDescription", eventlongdesc);
      formdata.append("EventType", JSON.stringify(newvalue));
      formdata.append("FeaturedEvent", featuredevent);
      formdata.append("City", city);
      formdata.append("VenueType", venuetype);
      formdata.append("DOE", dateofevent);
      formdata.append("EndDOE", dateofeventEnd);
      formdata.append("Year", dateofevent.split("-")[0]);
      formdata.append("ClientName", clientname);
      formdata.append("isFirst", isFirst);
      // formdata.append("content", content);
      // formdata.append("ClientType", clienttype)
      if (naturateam) {
        formdata.append("NaturaTeam", naturateam);
      }
      if (videographerteam) {
        formdata.append("VideoGrapherTeam", videographerteam);
      }
      if (photographerteam) {
        formdata.append("PhotoGrapherTeam", photographerteam);
      }
      if (infrateam) {
        formdata.append("InfraTeam", infrateam);
      }
      if (othercredit) {
        formdata.append("OtherCredits", othercredit);
      }
      if (ClientCredit) {
        formdata.append("ClientCredit", ClientCredit);
      }
      formdata.append("eventimg", banner);
      formdata.append("EventCategory", JSON.stringify(categoryvalue));
      formdata.append("EndClientName", endclientName);
      formdata.append("VenuName", venueName);

      const { data } = await axiosInstance.post("/api/event", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("data first", data.eventId);
      const { data: secondData } = await axiosInstance.put(
        `/api/event/addContent/${data.eventId}`,
        {
          content,
        }
      );
      console.log(secondData, "secondData");
      setloading(false);

      //
      setloading(false);

      alert(data.message);
      navigate("/admin/event");
    } catch (error) {
      console.log("check error", error);
      setloading(false);

      alert(error.response.data.message);
      //
    }
  };

  return (
    <>
      <Sidebar />

      <div
        className="p-1 my-container active-cont"
        style={{ backgroundColor: "#FDF8DB" }}
      >
        {/* <!-- Top Nav --> */}
        <nav className="navbar top-navbar navbar-light bg-light px-5">
          <a className="btn border-0" id="menu-btn" onClick={togglehandle}>
            <i className="bx bx-menu"></i>
          </a>
        </nav>
        {/* <!--End Top Nav --> */}
        <h3 className="text-dark p-3">ADMIN ADD EVENT 💻 📱</h3>
        <form onSubmit={handleUserdataSubmit}>
          <div className="row  justify-content-center">
            <div className="col-md-8 text-start py-3 ">
            <div className="d-flex justify-content-between align-items-center">
                    <label className="text-start p-1">Event Name</label>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={isFirst}
                        onChange={(e) => {
                        
                          setIsFirst(e.target.checked);
                        }}
                      />
                    </div>
                  </div>

              <input
                className="form-control "
                type="text"
                value={eventname}
                onChange={(e) => seteventname(e.target.value)}
                placeholder="Event Name"
                required
              />
            </div>
            <div className="col-md-8 text-start py-3">
              <label>Client Name</label>
              <input
                className="form-control "
                type="text"
                value={clientname}
                onChange={(e) => setclientname(e.target.value)}
                placeholder="Client Name"
                required
              />
            </div>
            <div className="col-md-8 text-start py-3">
              <label>End Client Name</label>
              <input
                className="form-control "
                type="text"
                value={endclientName}
                onChange={(e) => setendclientName(e.target.value)}
                placeholder="End Client Name"
                
              />
            </div>
            <div className="col-md-8 text-start py-3">
              <label>Product Type</label>
              {/* <select class="form-select form-select-sm" aria-label=".form-select-sm example" onChange={(e) => setvalue(e.target.value)} required>
                                <option  >Select Event Type</option>
                                {
                                    productcategory && productcategory.map((category) => (
                                        <>
                                            <option value={category._id}>{category.ProductTitle}</option>
                                        </>
                                    ))
                                }
                            </select> */}

              <MultiSelect
                options={productcategory}
                value={value}
                onChange={setvalue}
                labelledBy="Select"
              />
            </div>

            <div className="col-md-8 text-start py-3">
              <label>Event Short Description</label>
              <input
                className="form-control "
                type="text"
                value={eventshortdesc}
                onChange={(e) => seteventshortdesc(e.target.value)}
                placeholder="Event Short Description"
                required
              />
            </div>
            {/* <div className="col-md-8 text-start py-3">
              <label>Event Long Description</label>
              <input
                className="form-control "
                type="text"
                value={eventlongdesc}
                onChange={(e) => seteventlongdesc(e.target.value)}
                placeholder="Event Long Description"
                
              />
            </div> */}
            <div className="col-md-8 text-start py-3">
              <label>Venue Name</label>
              <input
                className="form-control "
                type="text"
                value={venueName}
                onChange={(e) => setvenueName(e.target.value)}
                placeholder="Venue Name"
                required
              />
            </div>
            <div className="col-md-8 text-start py-3">
              <label>City</label>
              <input
                className="form-control "
                type="text"
                value={city}
                onChange={(e) => setcity(e.target.value)}
                placeholder="Event City"
                required
              />
            </div>
            <div className="col-md-8 text-start py-3">
              <label>VenueType</label>
              <select
                class="form-select"
                value={venuetype}
                onChange={(e) => setvenuetype(e.target.value)}
                aria-label="Default select example"
              >
                <option selected>Select venue Type</option>
                <option value="both">both</option>
                <option value="Indoor">Indoor</option>
                <option value="Outdoor">Outdoor</option>
              </select>
              {/* <input className="form-control" type="text" value={featuredproject} onChange={e => setfeaturedproject(e.target.value)} placeholder="Service Description" /> */}
            </div>

            <div className="col-md-8 text-start py-3">
              <label>Event Category</label>
              {/* productcategoryoptions */}
              <MultiSelect
                options={productcategoryoptions}
                value={catvalue}
                onChange={setcatvalue}
                labelledBy="Select"
              />
            </div>

            <div className="col-md-8 text-start py-3">
              <label>Final Event Start Date</label>
              <input
                className="form-control "
                type="date"
                value={dateofevent}
                onChange={(e) => setdateofevent(e.target.value)}
                placeholder="Date Of Event"
                required
              />
            </div>
            <div className="col-md-8 text-start py-3">
              <label>Final Event End Date</label>
              <input
                className="form-control "
                type="date"
                value={dateofeventEnd}
                onChange={(e) => setdateofeventEnd(e.target.value)}
                placeholder="Date Of Event"
              />
            </div>

            {/* <div className="col-md-8 text-start py-3">
                            <label>Year</label>
                            <input className="form-control " type="text" value={year} onChange={e => setyear(e.target.value)} placeholder="Event Year" required />
                        </div> */}
            {/* 
                        <div className="col-md-8 text-start py-3">
                            <label>ClientType</label>
                            <input className="form-control " type="text" value={clienttype} onChange={e => setclienttype(e.target.value)} placeholder="Client Type" required />
                        </div> */}

            <div className="col-md-8 text-start py-3">
              <label>Natura Team</label>
              <textarea
                className="form-control "
                type="text"
                value={naturateam}
                onChange={(e) => setnaturateam(e.target.value)}
                placeholder="Natura Team"
              />
            </div>
            <div className="col-md-8 text-start py-3">
              <label>Photo Grapher Team</label>
              <textarea
                className="form-control "
                type="text"
                value={photographerteam}
                onChange={(e) => setphotographerteam(e.target.value)}
                placeholder="Photo Grapher Team"
              />
            </div>

            <div className="col-md-8 text-start py-3">
              <label>Video Grapher Team</label>
              <textarea
                className="form-control "
                type="text"
                value={videographerteam}
                onChange={(e) => setvideographerteam(e.target.value)}
                placeholder="Video Grapher Team"
              />
            </div>

            <div className="col-md-8 text-start py-3">
              <label>Infra Team</label>
              <textarea
                className="form-control "
                type="text"
                value={infrateam}
                onChange={(e) => setinfrateam(e.target.value)}
                placeholder="Infra Team"
              />
            </div>
            <div className="col-md-8 text-start py-3">
              <label>Client Credits </label>
              <textarea
                className="form-control "
                type="text"
                value={ClientCredit}
                onChange={(e) => setClientCredit(e.target.value)}
                placeholder="Other Credits"
              />
            </div>

            <div className="col-md-8 text-start py-3">
              <label>Other Credits </label>
              <textarea
                className="form-control "
                type="text"
                value={othercredit}
                onChange={(e) => setothercredit(e.target.value)}
                placeholder="Other Credits"
              />
            </div>

            <div className="col-md-8 text-start py-3">
              <label>Featured Event</label>
              <select
                class="form-select"
                value={featuredevent}
                onChange={(e) => setfeaturedevent(e.target.value)}
                aria-label="Default select example"
                required
              >
                <option selected>Select Featured Type</option>
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
              {/* <input className="form-control" type="text" value={featuredproject} onChange={e => setfeaturedproject(e.target.value)} placeholder="Service Description" /> */}
            </div>

            <div className="col-md-8 text-start py-3">
              <label>Banner Image (1600 X 700 px) (2 mb size)</label>
              <input
                className="form-control"
                type="file"
                onChange={(e) => setbanner(e.target.files[0])}
                required
              />
            </div>

            <div className="col-md-8 text-start py-3">
              <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => {
                  // setContent(newContent);
                }}
              />
            </div>

            <div
              className="col-md-8 text-start py-3"
              style={{ padding: "5px" }}
            >
              <div className="d-flex flex-row">
                {loading ? (
                  <>
                    <button className="btn btn-success" disabled>
                      Loading
                    </button>
                  </>
                ) : (
                  <>
                    <button className="btn btn-success">Submit</button>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEvent;
