import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../config';
import Sidebar from '../common/sidebar/Sidebar';
import JoditEditor from "jodit-react";



const AdminEditFaq = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const config = {
        uploader: {
            insertImageAsBase64URI: true,
        },
    };
    const editor = useRef(null);
    const [question, setquestion] = useState()
    const [answer, setanswer] = useState()
    const [questiontype, setquestiontype] = useState()
    const [faqcategory, setfaqcategory] = useState()
    const [loader, setloader] = useState(false)

    useEffect(() => {
        getfaq();
        getfaqcategory()
    }, [])

    const getfaqcategory = async () => {
        try {
            const { data } = await axiosInstance.get("/api/faqcategory/");
            setfaqcategory(data)

        } catch (error) {

        }
    }



    const handledataSubmit = async (e) => {
        e.preventDefault();

        if (question == undefined && answer == undefined && questiontype == undefined) {
            alert("Fill All the input field")
            return
        }
        setloader(true)


        try {
            const { data } = await axiosInstance.put(`/api/faq/${id}`, {
                "question": question,
                "answer": answer,
                "questionType": questiontype
            })
            alert(data.message)
            setloader(false)
            navigate("/admin/faq")
        } catch (error) {
            setloader(false)
            alert(error.response.data)
        }



        // navigate("/admin/user");
    }

    const getfaq = async () => {
        try {
            const { data } = await axiosInstance.get(`/api/faq/${id}`)
            setquestion(data.question)
            setanswer(data.answer)
            setquestiontype(data.questionType)

            // setreview(data.Review)

        } catch (error) {
            alert(error.response.data)
        }

    }

    const togglehandle = (e) => {
        e.persist();
        var menu_btn = document.querySelector("#menu-btn");
        var sidebar = document.querySelector("#sidebar");
        var container = document.querySelector(".my-container");
        sidebar.classList.toggle("active-nav")
        container.classList.toggle("active-cont");
    }

    return (
        <>
            <Sidebar />
            <div className="p-1 my-container active-cont">
                <nav className="navbar top-navbar navbar-light bg-light px-5">
                    <a className="btn border-0" id="menu-btn" onClick={togglehandle}><i className="bx bx-menu"></i></a>
                </nav>

                <h3 className="text-dark p-3"> ADMIN EDIT FAQ
                </h3>
                <div className="row mx-2 " style={{ backgroundColor: "#FDF8DB" }}>
                    <form onSubmit={handledataSubmit}>
                        <div className="col-md-12 align-items-center">
                            <label>Question</label>
                            <textarea className="form-control " type="text" value={question} onChange={e => setquestion(e.target.value)} placeholder="Enter Question" />
                        </div>
                        <div className="col-md-12">
                            <label>Answer</label>
                            <JoditEditor
                                ref={editor}
                                value={answer}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(newContent) => setanswer(newContent)} // preferred to use only this option to update the content for performance reasons
                                onChange={(newContent) => {
                                    // setContent(newContent);
                                }}
                            />
                            {/* <textarea className="form-control" type="text" value={answer} onChange={e => setanswer(e.target.value)} placeholder="Enter Answer" /> */}
                        </div>
                        <div className="col-md-12">
                            <label>Question Type</label>
                            <select class="form-select form-select-sm" aria-label=".form-select-sm example" value={questiontype} onChange={(e) => setquestiontype(e.target.value)} required>
                                <option  >Select Question Type</option>
                                {
                                    faqcategory && faqcategory.map((category) => (
                                        <>
                                            <option value={category._id}>{category.faqQuestionType}</option>
                                        </>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-md-6" style={{ padding: "5px" }}>
                            <div className="d-flex flex-row">
                                {
                                    loader ? <button className="btn btn-success" disabled>Updating Please Wait...</button> : <button className="btn btn-success" >Submit</button>
                                }

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}

export default AdminEditFaq