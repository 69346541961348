import React, { useState, useEffect } from "react";
// import Sidebar from '../../components/sidebar/Sidebar';
import { Link } from "react-router-dom";
import {
  AiFillDelete,
  AiOutlineFileAdd,
  AiTwotoneDelete,
} from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
// import { axiosInstance } from '../../../config';
import parse from "html-react-parser";
import { axiosInstance } from "../../config";
import Sidebar from "../common/sidebar/Sidebar";

const AdminTeam = () => {
  const [Team, setTeam] = useState([]);
  const [del, setdel] = useState(false);

  const [productimg, setproductimg] = useState();
  const [productid, setproductid] = useState();
  const [loader, setloader] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [SelectedCategoryId, setSelectedCategoryId] = useState({
    // id:"",
    // UploadTo
    // size
  });
  const [modal, setmodal] = useState(false);

  useEffect(() => {
    getTeam();
  }, [!del]);

  const getTeam = async () => {
    try {
      const { data } = await axiosInstance.get("/api/Team");
      if (data.success) {
        setTeam(data.Team);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const deleteTeam = async (id, e) => {
    e.preventDefault();
    try {
      const answer = window.confirm("Are You Sure !");
      if (answer) {
        const { data } = await axiosInstance.delete(`/api/Team/${id}`, {
          headers: {
            token: localStorage.getItem("token"),
          },
        });
        alert("deleted permanently");
      }
      setdel(!del);
    } catch (error) {
      console.log("error", error.response.data.message);
    }
  };

  const addimge = async (e) => {
    e.preventDefault();
    try {
      if (!productimg) {
        return alert("Please Select Image ");
      } else {
        if (productimg.size >= 2000000) {
          return alert("Image Size should be less Than 2MB");
        }
      }
      setloader(true);
      const productdata = new FormData();
      productdata.append("Teamimg", productimg);
      const { data } = await axiosInstance.post(
        `/api/Team/add-image/${productid}`,
        productdata,
        {
          headers: {
            token: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setloader(false);

      alert("Image Added Successfully");
      setdel(!del);
    } catch (error) {
      setloader(false);
      console.log(error);
    }
  };

  const openmodel = (data) => {
    setSelectedCategoryId(data);
    setmodal(true);
  };

  const togglehandle = (e) => {
    e.persist();
    var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };
  const deleteImage = async (id, UploadTo) => {
    var answer = window.confirm("Are you sure To delete?");
    if (answer) {
      await axiosInstance.delete(
        `/api/Team/remove-image/${id}/${UploadTo}`,

        {
          headers: {
            token: localStorage.getItem("token"),
          },
        }
      );

      alert("deleted");

      setTimeout(() => {
        setdel(!del);
      }, 100);
    }
  };

  const handlePhotoUpload = async (e) => {
    e.preventDefault();

    if (!SelectedCategoryId?.UploadTo || !SelectedCategoryId?.id) {
      return alert("Please select");
    }
    try {
      if (selectedFile.size >= 1000000) {
        alert("File Size Should Less Than 1 Mb");

        return;
      }
      setloader(true);

      const formdata = new FormData();
      formdata.append("TeamImage", selectedFile);
      formdata.append("UploadTo", SelectedCategoryId?.UploadTo);

      const { data } = await axiosInstance.put(
        `/api/Team/imageUploader/${SelectedCategoryId.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setloader(false);

      if (data.success) {
        setloader(false);
        setdel(!del);
        setmodal(false);
        alert("Added Successfully");
      }
    } catch (error) {
      setloader(false);
      alert(error.response.data.message);
    }
  };
  return (
    <>
      <Sidebar />
      {modal && (
        <>
          <div
            class="modal d-block"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ opacity: "1" }}
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {SelectedCategoryId?.size ?? "Image Upload"}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => setmodal(false)}
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div className="row">
                    <input
                      type="file"
                      class="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter file"
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                      required
                    />
                  </div>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                  <div></div>
                  <div>
                    {loader ? (
                      <>
                        <button type="button" class="btn btn-primary" disabled>
                          Loading
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={handlePhotoUpload}
                        >
                          Upload Image
                        </button>
                      </>
                    )}
                    {/* <button type="button" class="btn btn-primary" onClick={handleimgUrlSubmit} >upload link</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
        <nav className="navbar top-navbar navbar-light bg-light px-5">
          <a className="btn border-0" id="menu-btn" onClick={togglehandle}>
            <i className="bx bx-menu"></i>
          </a>
        </nav>
        {/* <!--End Top Nav --> */}
        <h3 className="text-dark p-3">ADMIN FAQ TYPES</h3>

        <div
          className="p-3"
          style={{ boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
        >
          <div className="row text-start">
            <div className="">
              <Link
                className="btn btn btn-success text-white "
                to="/admin/add-Team"
              >
                Add Team
              </Link>
            </div>
          </div>

          <table class="table">
            <thead>
              <tr>
                <th scope="col">Sr. No.</th>
                <th scope="col">Name</th>
                <th scope="col">sort</th>
                <th scope="col">Position</th>
                <th scope="col">Phone</th>
                <th scope="col">Email</th>
                <th scope="col">Linkedin</th>
                <th scope="col">Image</th>
                <th scope="col" colspan="3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {Team &&
                Team.map((u, no) => (
                  <>
                    <tr>
                      <th scope="row">{no + 1}</th>
                      <td>{u.Name}</td>
                      <td>{u.sort}</td>
                      <td>{u.Position}</td>
                      <td>{u.Phone}</td>
                      <td>{u.Email}</td>
                      <td>{u.Linkedin}</td>
                      <td>
                        {u?.TeamImage ? (
                          <div className="d-flex justify-content-center flex-column align-items-center">
                            <img
                              src={`${process.env.REACT_APP_IMGURL}/images/TeamImage/${u.TeamImage}`}
                              alt={u?.TeamImage}
                              height={100}
                              width={100}
                            />

                            <AiTwotoneDelete
                              size={30}
                              onClick={() => deleteImage(u._id, u?.TeamImage)}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignitems: "center",
                            }}
                          >
                            <AiOutlineFileAdd
                              className="text-center"
                              size={30}
                              onClick={() =>
                                openmodel({
                                  id: u._id,
                                  UploadTo: "TeamImage",
                                  size: "Team Image (1350 x 470)",
                                })
                              }
                            />
                          </div>
                        )}
                      </td>

                      <td scope="col" colspan="3">
                        <Link
                          className="btn btn btn-success text-white mx-2"
                          to={`/admin/edit-Team/${u._id}`}
                        >
                          {" "}
                          <FiEdit2 />{" "}
                        </Link>
                        <button
                          className="btn btn btn-success text-white"
                          onClick={(e) => deleteTeam(u._id, e)}
                        >
                          <AiFillDelete />
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AdminTeam;
