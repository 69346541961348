import React, { useEffect, useState } from "react";
// import Footer from '../footer/Footer';
import { useParams } from "react-router-dom";
// import { axiosInstance } from '../../../config';

// import NewHeader from '../../Header/NewHeader';
import parse from "html-react-parser";
// import img from "../../../assests/img/cta.jpg"
import Helmet from "react-helmet";
import "./newblog.css";
import { Footer } from "../../Home/Footer";
import { axiosInstance } from "../../config";
import { Header } from "../../Home/Header";
import Topbanner from "../topbanner/Topbanner";
// import PopUp from '../popup/PopUp';
// import TopBanner from './TopBanner';

const NewBlogList = () => {
  const { url } = useParams();
  const [blog, setblog] = useState();
  const [loader, setloader] = useState(false);
  const [name, setName] = useState();
  const [phone, setphone] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();

  const getblogdetails = async () => {
    try {
      setloader(true);
      const { data } = await axiosInstance.get(
        `/api/blog/fetchblogbyurl/${url}`
      );
      if (data.success) {
        setloader(false);
        setblog(data.blog);
      }
      console.log("ddd", blog);
    } catch (error) {
      console.log("error", error);
    }
  };

  // console.log("bb",blog)

  useEffect(() => {
    getblogdetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.post("/api/service", {
        Name: `${name} `,
        Email: email,
        EnquiryFor: "Contact Us",
        // "EquipmentName":"63468c5276049734e666774c",
        MobNumber: phone,
        Message: message,
      });
      if (data.success) {
        alert(data.message);
      }
    } catch (error) {
      alert(error.response.data.message);
    }
    // alert("form submitted Successfully")

    // axios.get(`http://api.digisidekick.com/api/Mail?Email=aarjav@digisidekick.com&Firstname=${name}&Lastname= ${lastname}&Number=${phone}&Usermial=${email}&UserMessage=${message}&other=null`,).then((res) => {
    //     console.log(res.data)
    // }).catch((error) => {
    //     console.log(error)
    // });

    // setName("");
    // setLastName("");
    // setphone('');
    // setEmail("")
    // setMessage("");
    // navigate("/thank-you")
  };
  return (
    <>
      <Helmet>
        <title>{blog?.heading}</title>
        <meta name="keywords" content={blog?.keywords} />
        <meta name="description" content={blog?.description} />
      </Helmet>
      <Header />
      <div
        class="about_banner1 d-flex justify-content-center align-items-center  "
        style={{
          backgroundImage:`url(https://natura.co.in/images/Blog/${blog?.BlogImage})`,
          height: "70vh",
        }}
      >
        {/* <div class="container ">
          <div class="main_title_3 py-5 col-md-6 text-md-start text-center d-lg-block d-none   ">
            <h2 class="text-white ">
              <b>{blog?.heading}</b>
            </h2>

           
          </div>
          <div>
            <h2 class="text-white d-lg-none d-block text-center  ">
              <b>{blog?.heading}</b>
            </h2>
          </div>
        </div> */}
      </div>

      <h1 className="text-center mt-5">{blog?.heading}</h1>
      <div className="container">
        {loader ? (
          <>
            <div
              className="row d-flex justify-content-center align-items-center position-relative top-50 mt-5 "
              style={{ height: "20vh" }}
            >
              <div className="col-md-8 text center position-absolute top-40 start-50">
                <div class="spinner-border text-dark " role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </>
        ) : (
          blog !== null && (
            <>
              <div className="row text-dark ">
                <div className="col-md-12 text-dark blogs">
                  {parse(`${blog?.content}`)}
                </div>
              </div>
            </>
          )
        )}
      </div>
   
      <Footer />
    </>
  );
};

export default NewBlogList;
