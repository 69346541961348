import React, { useEffect, useState } from "react";
// import { useLocation } from 'react-router-dom'
import parse from "html-react-parser";
// import Faqsection from '../../components/faqsection/Faqsection'
// import Topbanner from '../../components/topbanner/Topbanner'
import { axiosInstance } from "../../config";
import { Footer } from "../../Home/Footer";
import { Header } from "../../Home/Header";
// import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import Topbanner from "../../components/topbanner/Topbanner";
import { Link } from "react-router-dom";
import HeaderNew from "../../Home/HeaderNew";

const FaqPage = () => {
  const [faqQuestionType, newfaqQuestionType] = useState("All");
  const [allquestion, setallquestion] = useState([]);

  useEffect(() => {
    getfaq();
  }, []);

  const getfaq = async () => {
    try {
      const { data } = await axiosInstance.get("/api/faq");
      setallquestion(data);
    } catch (error) { }
  };

  // const faqdata = [{
  //     id: 1,
  //     question: "How do we ensure safety?",
  //     answer: "Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  //     questionType: "Safety & Education"
  // }, {
  //     id: 2,
  //     question: "Are aerial acts safe / rigging is safe?",
  //     answer: "Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  //     questionType: "Safety & Education"

  // }, {
  //     id: 3,
  //     question: "Is there a Height / Weight limit to our systems?",
  //     answer: "Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  //     questionType: "Understand our Systems"

  // }, {
  //     id: 4,
  //     question: "How high can we make our performers fly?",
  //     answer: "Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  //     questionType: "Understand our Systems"

  // }, {
  //     id: 5,
  //     question: "How high can we make our performers fly?",
  //     answer: "Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  //     questionType: "Terms used in nature"
  // }, {
  //     id: 6,
  //     question: "How high can we make our performers fly?",
  //     answer: "Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  //     questionType: "RiggingAwesome"
  // },
  // {
  //     id: 7,
  //     question: "How high can we make our performers fly?",
  //     answer: "Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  //     questionType: "Workwithus"

  // },
  // {
  //     id: 8,
  //     question: "How much do we charge for our Services?",
  //     answer: "Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  //     questionType: "Workwithus"

  // },
  // {
  //     id: 9,
  //     question: "What are the career opportunities at Natura?",
  //     answer: "Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.",
  //     questionType: "CareersNatura"
  // }
  // ]

  const allquestionType = [
    ...new Set(allquestion.map((p) => p.questionType.faqQuestionType)),
  ];

  return (
    <>
      <Header />
      {/* <HeaderNew/> */}

      <Topbanner heading="FAQs" height="45vh" />
      {/* <section className='career d-flex justify-content-center align-items-center' style={{
                height: "53vh",
                marginTop: "6.9%"
            }}>
                <div className="container" >
                    <div className="row  ">
                        <div className="col-lg-12">
                            <h1 className='text-white'><b>{"Frequently Asked Question"}</b></h1>
                        </div>
                    </div>
                </div>
            </section> */}
      <section style={{ backgroundColor: "#e3e3da" }}>
        {/* <Faqsection /> */}

        <section className="py-5">
          <div className="container">
            <div className="mt-3">{/* <Breadcrumbs /> */}</div>
            <div
              className="d-flex flex-nowrap  text-center  d-lg-none d-block"
              style={{ overflowX: "auto" }}
            >
              {allquestionType &&
                allquestionType.map((p) => (
                  <>

                    <button
                      className={
                        faqQuestionType === p
                          ? "col-md-2 col-5  bg-warning text-white m-lg-3 m-1  p-0 rounded"
                          : "col-md-2  bg-dark text-white m-lg-3 col-5 m-1  p-lg-0 rounded"
                      }
                      onClick={() => newfaqQuestionType(p)}
                    >
                      {p}
                    </button>


                  </>
                ))}
            </div>

            <div className="row pt-2">
              <div className="col-lg-2 d-none d-lg-block">
                <div
                  className=" text-center "
                // style={{
                //   display: "flex",
                //   justifyContent: "center",
                //   alignItems: "start",
                //   flexDirection: "column",
                //   gap: "10px",
                // }}
                >
                  <div
                    style={{
                      boxShadow: "7px -3px 10px 3px hsla(0,0%,79%,1)",
                      backgroundColor: "#bbbbbb3d",
                      padding: "1rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <h4 className="mt-2">Category</h4>
                    <div className={
                      faqQuestionType === "All" ? "CategoryButton" : ""
                    }>
                      <h4
                        className={
                          faqQuestionType === "All"
                            ? "   text-warning text-start  m-1  p-0 "
                            : "   text-dark  text-start  m-1  p-lg-0 "
                        }
                        onClick={() => newfaqQuestionType("All")}
                        style={{
                          fontSize: "13px",
                          cursor: "pointer",
                          // width:"20%"
                          // height: "8vh",
                        }}
                      >
                        All
                      </h4>
                    </div>
                    {allquestionType &&
                      allquestionType.map((p) => (
                        <>
                          <div className={
                            faqQuestionType === p ? "CategoryButton" : ""
                          }>
                            <h4
                              className={
                                faqQuestionType === p
                                  ? "   text-warning text-start  m-1  p-0 "
                                  : "   text-dark  text-start  m-1  p-lg-0 "
                              }
                              onClick={() => newfaqQuestionType(p)}
                              style={{
                                fontSize: "13px",
                                cursor: "pointer",
                                // width:"20%"
                                // height: "8vh",
                              }}
                            >
                              {p}
                            </h4>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-10 col-12 faq ">
                <div class="accordion accordion-flush " id="faqlist">
                  {allquestion
                    .filter((f) => {
                      if (faqQuestionType === "All") {
                        return true;
                      } else {
                        return (
                          f.questionType.faqQuestionType === faqQuestionType
                        );
                      }
                    })
                    .map((p, index) => (
                      <>
                        <Accrodianitem p={p} index={index} key={p._id} />
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
};

const Accrodianitem = ({ p, index }) => (
  <>
    <div class="accordion-item">
      <h3 class="accordion-header">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#faq-content-${index}`}
          aria-expanded="false"
        >
          <span class="num">{index + 1}. &nbsp; </span> {p.question}
        </button>
      </h3>
      <div
        id={`faq-content-${index}`}
        class="accordion-collapse collapse"
        data-bs-parent="#faqlist"
      >

        <div className="accordion-body linkstyle">   {parse(p.answer)}</div>
      </div>
    </div>
  </>
);

export default FaqPage;
