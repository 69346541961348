import React from "react";
import "./footericons.css";
import Tooltip from "../Tooltip/Tooltip";

const FooterIcons = () => {
  return (
    <>
     
      <div
        id="callus"
      >
        <Tooltip content="+91 80 9725 9195" direction="left">
        <a
          href="tel: +91 80 9725 9195"
          target="_blank"
        
          rel="noreferrer"
        >
          <i className="fas fa-phone text-white" />
        </a>
        </Tooltip>
      </div>
      
      <div id="whatsapp" className="whatsapp">
        <a
          href="https://api.whatsapp.com/send?phone=918097259195&amp;text=Hello, I want more info about the Aerial Acts and Rigging Services you offer"
          target="_blank"
          title="Whatsapp"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          rel="noreferrer"
        >
          <i
            className="fab fa-whatsapp text-white"
            style={{ fontSize: "33px" }}
          />
        </a>
      </div>
    </>
  );
};

export default FooterIcons;
