import React, { useState } from "react";
// import { useLocation } from 'react-router-dom'
// import Card from '../../components/cardcomponent/Card'
import FaqComponents from "../../components/faqcomponents/FaqComponents";
// import Productcard from '../../components/productcard/Productcard'
import SafetyCardEvent from "../../components/safetycarddesign/SafetyCardEvent";
import SafetyCardService from "../../components/safetycarddesign/SafetyCardService";
import SafetyProductdesign from "../../components/safetycarddesign/SafetyProductdesign";
// import ServiceCard from '../../components/servicecard/ServiceCard'
// import Topbanner from '../../components/topbanner/Topbanner'
import { axiosInstance } from "../../config";
import { Footer } from "../../Home/Footer";
import { Header } from "../../Home/Header";
import "./search.css";

const Search = () => {
  const [searchtext, setsearchtext] = useState();
  const [alldata, setalldata] = useState();
  const [loader, setloader] = useState(false);

  const handlesearch = async () => {
    if (!searchtext) {
      return alert("Please Enter Text...");
    }
    try {
      setloader(true);
      const { data } = await axiosInstance.post("/api/event/querforall", {
        querystr: searchtext,
      });

      setalldata(data.filteredresult);
      setloader(false);
    } catch (error) {
      setloader(false);
    }
  };
  return (
    <>
      <Header />

      <section style={{ marginTop: "80px" }} id="searchbox">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 py-5 " id="searchbutton">
              <h2 className="text-center">
                <b>Search</b>
              </h2>
              <input
                id="searchQueryInput"
                type="text"
                placeholder="Search.."
                name="search"
                value={searchtext}
                onChange={(e) => setsearchtext(e.target.value)}
              />
              <button
                id="searchQuerySubmit"
                type="submit"
                name="searchQuerySubmit"
                onClick={handlesearch}
              >
                <svg
                  style={{ width: "24px", height: "24px" }}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#666666"
                    d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>

      {loader ? (
        <div
          className=" d-flex justify-content-center pb-3"
          style={{ height: "100%" }}
        >
          {/* <div className="col-md-12 text center "> */}
          <div class="spinner-border text-dark " role="status">
            <span class="sr-only">Loading...</span>
          </div>
          {/* </div> */}
        </div>
      ) : alldata &&
        alldata[0].event.length === 0 &&
        alldata[1].product.length === 0 &&
        alldata[2].service.length === 0 &&
        alldata[3].faq.length === 0 ? (
        <>
          <h3
            style={{ fontSize: "20px", fontWeight: "900" }}
            className="pb-3 text-center"
          >
            {" "}
            Keywords not Matched! 
          </h3>
        </>
      ) : (
        alldata && (
          <>
            <section
              style={{
                marginBottom: "60px",
              }}
            >
              <div className="container">
                <div className="row">
                  {alldata[2].service.length > 0 && (
                    <>
                      {alldata[2].service.map((service) => (
                        <>
                          <div className="col-md-6  align-self-stretch">
                            <SafetyCardService
                              service={service}
                              key={service._id}
                            />
                          </div>
                        </>
                      ))}
                    </>
                  )}

                  {alldata[1].product.length > 0 && (
                    <>
                      {alldata[1].product.map((product) => (
                        <>
                          <div className="col-md-6  align-self-stretch">
                            <SafetyProductdesign
                              product={product}
                              key={product._id}
                            />
                          </div>
                        </>
                      ))}
                    </>
                  )}

                  {
                    // alldata[2].service.length > 0 && <ServiceCard service={alldata[2].service} />
                    alldata[0].event.length > 0 && (
                      <>
                        {alldata[0].event.map((event) => (
                          <>
                            <div className="col-md-6  align-self-stretch">
                              <SafetyCardEvent event={event} key={event._id} />
                            </div>
                          </>
                        ))}
                      </>
                    )
                  }
                </div>
              </div>
            </section>
            {alldata[3].faq.length > 0 && (
              <FaqComponents allquestion={alldata[3].faq} isParse={true} />
            )}
          </>
        )
      )}
      <div style={{ marginTop: "20px" }}>
        <Footer />
      </div>
    </>
  );
};

export default Search;
