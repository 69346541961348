import React, { useEffect, useState } from "react";
import bgfooter from "../images/logo.png";
import bg from "../images/client/logohori.png";
import { Link } from "react-router-dom";
import { axiosInstance } from "../config";
import { MdArrowRightAlt } from "react-icons/md";
import FooterIcons from "../components/footericons/FooterIcons";

export const Footer = () => {
  const [category, setcategory] = useState();

  useEffect(() => {
    getcategory();
  }, []);

  const getcategory = async () => {
    try {
      const { data } = await axiosInstance.get("/api/category");
      setcategory(data);
    } catch (error) {}
  };

  return (
    <div>
      {/* Footer=== */}
      {/* footer section */}
      <FooterIcons />

      <div className="footer">
        <div className="container mt-5 mb-5 text-center text-md-start text-white ps-0">
          <div className="row justify-content-start align-items-center gap-3">
            <div class="col-md-3 col-12 col-xl-4 mx-3 mb-4">
              <div className="logo py-3">
                {/* <h1>Innov<span style={{color:'gray',fontSize:'2.3rem',opacity:'0.3'}}>.8</span> </h1> */}
                <img className="foteer_img" src={bg} alt="" />
              </div>

              {/* <hr
                                class="mb-4 mt-0 d-inline-block mx-auto"
                                style={{ width: "60px", background: "#ec661f", height: "2px" }}
                            /> */}
              <p>
                {/* Natura as a Pvt. Ltd. was incorporated in 2008 with the vision to change the way aerial acts are performed in the Entertainment Industry. The sole intent of Natura was to create a change in the way safety and risk is looked at, in the industry we work in. */}
                As the country's first ever professional aerial rigging company,
                Natura works closely with event planners, production houses and
                choreographers to conceptualize, design, develop, and safely
                execute aerial performances and entries, product launches,
                building unveilings and create personalized adventure
                experiences – we're your go-to when it comes to creating the
                "oomph" that makes any stage performance, brand event and
                celebration that much more exciting.
              </p>
              {/* <h6 class="text-uppercase fw-bold">Connect</h6>
                            <hr
                                class="mb-4 mt-0 d-inline-block mx-auto"
                                style={{ width: "60px", background: "#7c4dff", height: "2px" }}
                            />
                            <div className='d-flex justify-content-center '>
                                <a href="" class="mx-1 icons">
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                                <a href="" class="mx-1 icons">
                                    <i class="fab fa-twitter"></i>
                                </a>
                                <a href="" class="mx-1 icons">
                                    <i class="fab fa-google"></i>
                                </a>
                                <a href="" class="mx-1 icons">
                                    <i class="fab fa-instagram"></i>
                                </a>
                                <a href="" class="mx-1 icons" >
                                    <i class="fab fa-linkedin"></i>
                                </a>
                                <a href="" class=" mx-1 icons" >
                                    <i class="fab fa-github" ></i>
                                </a>
                            </div> */}
            </div>
            <div class="col-md-1 d-none d-block-xl col-xl-1 mx-auto "></div>
            <div className="col-md-3 col-lg-2 col-xl-2  ">
              <h6 class="text-uppercase fw-bold">Services</h6>
              <hr
                class="mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: "60px", background: "#7c4dff", height: "2px" }}
              />
              {category &&
                category.map((c, index) => (
                  <p>
                    <Link
                      to={`/service/${c?.categoryName}`}
                      key={index}
                      class=""
                      state={{ image: "aerialacts.jpg" }}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {" "}
                      {c?.categoryName}
                    </Link>
                  </p>
                ))}
            </div>

            <div class="col-md-3 col-lg-2 col-xl-2  mb-3">
              <div>
                <h6 class="text-uppercase fw-bold"> Useful links</h6>
                <hr
                  class="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    background: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <Link to="/about-natura" class="">
                    About Natura
                  </Link>
                </p>

                <p>
                  <Link to="/Featured/event" class="">
                    Featured Events
                  </Link>
                </p>
                <p>
                  <Link to="/safety" class="">
                    Safety at Natura
                  </Link>
                </p>
                <p>
                  <Link to="/faq" class="">
                    Frequently Asked Questions
                  </Link>
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-12  col-xl-3  ml-auto"
              style={{
                marginTop: "39px",
              }}
            >
              <h6 class="text-uppercase fw-bold">Contact</h6>
              <hr
                class="mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: "60px", background: "#7c4dff", height: "2px" }}
              />
              <p>
                <i class="fas fa-home mr-3">&nbsp;&nbsp;</i>Unit no 1, Lower
                level 2, Wing B, ORM Premises Cooperative Society, Mayur Nagar,
                Goregaon, Mumbai, Mumbai Suburban, Maharashtra, 400065
              </p>
              <p>
                <a
                  href="mailto: info@natura.co.in"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fas fa-envelope mr-3">&nbsp;&nbsp;</i>
                  info@natura.co.in
                </a>
              </p>
              <p>
                <a href="tel:+91 80 9725 9195" target="_blank" rel="noreferrer">
                  <i class="fas fa-phone mr-3">&nbsp;&nbsp;</i>+91 80 9725 9195
                </a>
              </p>
              <p>
                <a
                  href="https://www.instagram.com/naturaadvcrew/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    class="fa-brands fa-instagram-square"
                    style={{ fontSize: 30 }}
                  ></i>
                </a>
                &nbsp;
                <a
                  href="https://twitter.com/naturaadvcrew"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    class="fa-brands fa-twitter-square"
                    style={{ fontSize: 30 }}
                  ></i>{" "}
                </a>
                <a
                  href="https://www.youtube.com/naturaindia"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i
                    class="fa-brands fa-youtube-square"
                    style={{ fontSize: 30 }}
                  ></i>
                </a>
                &nbsp;
                <a
                  href="https://www.linkedin.com/company/natura-outdoor-education-&-training-solutions-pvt-ltd"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    class="fa-brands fa-linkedin-square"
                    style={{ fontSize: 30 }}
                  ></i>{" "}
                </a>
                {/* <a href="https://www.youtube.com/naturaindia"> <i class="fa-brands fa-youtube-square" style={{ fontSize: 30 }}></i></a> */}
                <a
                  href="https://www.facebook.com/Adventure.Crew"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i
                    class="fa-brands fa-facebook-square"
                    style={{ fontSize: 30 }}
                  ></i>
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=918097259195&amp;text=Hello, I want more info about the Aerial Acts and Rigging Services you offer"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i
                    // class="fa-brands fa-square-whatsapp"
                    class="fa-brands fa-whatsapp-square"
                    style={{ fontSize: 30 }}
                  ></i>
                </a>
                {/* <a href="https://www.youtube.com/naturaindia"> <i class="fa fa-linkedin-square" style={{ fontSize: 30 }}></i></a> */}
              </p>
            </div>
          </div>
        </div>

        {/* <div className="container">
                    <div className="row">
                        <div className="col-lg-4 d-flex flex-column text-start">
                            <Link to="/saras-reengineered">SARAS The Re-engineer ed</Link>
                            <Link to="/cbse-affiliation-process">CBSE Affiliation Process</Link>
                        </div>
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4"></div>
                    </div>
                    </div> */}

        <div class="text-center p-3">
          © 2023 Copyright:
          <a style={{ color: "#FEBD15" }} href="https://natura.co.in/">
            {" "}
            Natura
          </a>
        </div>
      </div>

      {/* Footer=== */}
    </div>
  );
};
