import React,{useState,useEffect} from 'react'
import { axiosInstance } from '../../config';
import { Header } from '../../Home/Header';
import Topbanner from '../../components/topbanner/Topbanner';
import MainLoader from "../../components/mainloader/MainLoader";
import Card from "../../components/cardcomponent/Card";
import { Footer } from '../../Home/Footer';
import { useParams } from 'react-router-dom';
import { BiLoader } from 'react-icons/bi';



const Venue = () => {

    const {venuetype} = useParams();
  


    const [loader, setloader] = useState(false)
    const [event, setEvents] = useState([]);

    const getallevent = async () => {
        try {
            setloader(true)
            const { data } = await axiosInstance.get(`/api/event?VenueType=${venuetype}&VenueType="both"`);
            setEvents(data)
            setloader(false)
            // setEvents(data.filter(event => event.FeaturedEvent === true));
            // 
            setloader(false)

        } catch (error) {
            setloader(false)
        }
    };

    useEffect(() => {
        getallevent();
    }, []);


    return (
        <>
        <Header />
        <Topbanner heading={venuetype} />
        <section>
          <div className="container pb-5">
          {
          loader ? <BiLoader/>:<>
           <Card event={event} />
          </>
        }

          </div>
        </section>
        <Footer />
        </>
    )
}

export default Venue