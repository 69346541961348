import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../common/sidebar/Sidebar';
import { axiosInstance } from '../../config';
import { useNavigate } from 'react-router-dom';
import JoditEditor from "jodit-react";
const AdminAddFAq = () => {

    const navigate = useNavigate();

    const config = {
        uploader: {
            insertImageAsBase64URI: true,
        },
    };
    const editor = useRef(null);

    const [question, setquestion] = useState()
    const [answer, setanswer] = useState()
    const [faqcategory, setfaqcategory] = useState()
    const [value, setvalue] = useState()
    const [loader, setloader] = useState(false)


    useEffect(() => {
        getfaqcategory()
    }, [])


    const getfaqcategory = async () => {
        try {
            setloader(true)
            const { data } = await axiosInstance.get("/api/faqcategory/");
            setfaqcategory(data)
            setloader(false)
        } catch (error) {
            setloader(false)

        }
    }





    const togglehandle = (e) => {
        e.persist();
        var menu_btn = document.querySelector("#menu-btn");
        var sidebar = document.querySelector("#sidebar");
        var container = document.querySelector(".my-container");
        sidebar.classList.toggle("active-nav")
        container.classList.toggle("active-cont");
    }

    const handleUserdataSubmit = async (e) => {
        e.preventDefault();



        if (question == undefined && answer == undefined && value == undefined) {
            alert("Fill All the input field")
            return
        }

        try {
            const { data } = await axiosInstance.post("/api/faq", {
                "question": question,
                "answer": answer,
                "questionType": value
            })

            alert(data.message)
            navigate("/admin/faq")

        } catch (error) {
            alert(error.response.data)

        }
    }



    return (
        <>
            <Sidebar />

            <div className="p-1 my-container active-cont">
                {/* <!-- Top Nav --> */}
                <nav className="navbar top-navbar navbar-light bg-light px-5">
                    <a className="btn border-0" id="menu-btn" onClick={togglehandle}><i className="bx bx-menu"></i></a>
                </nav>
                {/* <!--End Top Nav --> */}
                <h3 className="text-dark p-3">ADMIN ADD FAQ 💻 📱
                </h3>



                <div className="row mx-2 " style={{ backgroundColor: "#FDF8DB" }}>


                    {/* <div className="toogler"><h3>Add Review</h3></div> */}

                    <form onSubmit={handleUserdataSubmit}>
                        <div className="col-md-6 align-items-center">
                            <label> Question</label>
                            <input className="form-control " type="text" value={question} onChange={e => setquestion(e.target.value)} placeholder=" Question" />
                        </div>
                        <div className="col-md-6">
                            <label>Answer</label>
                            <JoditEditor
                                ref={editor}
                                value={answer}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(newContent) => setanswer(newContent)} // preferred to use only this option to update the content for performance reasons
                                onChange={(newContent) => {
                                    // setContent(newContent);
                                }}
                            />
                            {/* <textarea className="form-control" type="text" value={answer} onChange={e => setanswer(e.target.value)} placeholder="Enter Answer" /> */}
                        </div>
                        <div className="col-md-6">
                            <label>Question Type</label>
                            <select class="form-select form-select-sm" aria-label=".form-select-sm example" onChange={(e) => setvalue(e.target.value)} required>
                                <option  >Select Question Type</option>
                                {
                                    faqcategory && faqcategory.map((category) => (
                                        <>
                                            <option value={category._id}>{category.faqQuestionType}</option>
                                        </>
                                    ))
                                }
                            </select>

                        </div>

                        <div className="col-md-6" style={{ padding: "5px" }}>
                            <div className="d-flex flex-row">
                                {
                                    loader ? <button className="btn btn-success" disabled>Please Wait...</button> : <button className="btn btn-success" >Submit</button>
                                }

                            </div>
                        </div>
                    </form>

                </div>

            </div>

        </>
    )
}

export default AdminAddFAq