import React from 'react';
import { Header } from '../../Home/Header';
import Topbanner from '../../components/topbanner/Topbanner';
import TestimoniaCarousal from '../../components/testimonalcarousal/TestimoniaCarousal';
import { Footer } from '../../Home/Footer';
import { useEffect, useState } from "react";
import { axiosInstance } from '../../config';
import MainTestimonial from '../../components/maintestMonial/MainTestimonial';
import TextTestiMonial from '../../components/maintestMonial/TextTestiMonial';
import VideoTestimonial from '../../components/maintestMonial/VideoTestimonial';





const ClientTestimonial = () => {
  const [testimonial, settestimonial] = useState()

  useEffect(() => {
    gettestimonial()
  }, [])

  const gettestimonial = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/testimonial`)
      settestimonial(data)
    } catch (error) {

    }
  }

  const item = [
    {
      id: 1
    }, {
      id: 2
    }, {
      id: 3
    }, {
      id: 4
    }
  ]

  return (
    <>
      <Header />
      <Topbanner heading="Testimonials" height="45vh" />
      <section style={{ backgroundColor: "rgb(204, 204, 204)" }} >
        <div className="container ">
          {/* Video Testimonial */}

          <div className='py-5'>
            {/* <TestimoniaCarousal /> */}
            <MainTestimonial testimonial={testimonial?.filter((p) => p.testimonialType === "Video")} Component={VideoTestimonial} phoneitems={3} />
          </div>


          <div className='pb-5'>
            <MainTestimonial testimonial={testimonial?.filter((p) => p.testimonialType === "Text")} Component={TextTestiMonial} phoneitems={2} />




            {/* <TestimoniaCarousal /> */}
          </div>
        </div>
      </section>
      <Footer />




    </>
  )
}

export default ClientTestimonial