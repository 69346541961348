import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../../config';
import Sidebar from '../common/sidebar/Sidebar';

const EditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate()

  const [productTitle, setproductTitle] = useState();
  const [productdescription, setproductdescription] = useState();
  const [ProductShortDescription, setProductShortDescription] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [productType, setproductType] = useState();
  const [loading, setLoading] = useState(false);
  const [cate, setcate] = useState()




  useEffect(() => {
    getcategory()
    getproductdetails()

  }, [])


  const getproductdetails = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/product/${id}`)
      setproductTitle(data.ProductTitle)
      setProductShortDescription(data?.ProductShortDescription)
      setproductdescription(data.ProductDescription)
      setproductType(data.productType)
    } catch (error) {
      alert(error.data.message)
    }

  }

  const getcategory = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/category`)
      setcate(data)
    } catch (error) {
     
      alert(error.response.data)
    }

  }



  const togglehandle = (e) => {
    e.persist();
    var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav")
    container.classList.toggle("active-cont");
  }


  const handleUserdataSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)

      if (productTitle.trim() == "" || productTitle == undefined) {
        alert("Product Title Can't be empty")
        return
      }
      if (productdescription.trim() == "" || !productdescription) {
        alert("Product Description Can't be empty")
        return
      }
      if (!productType) {
        alert("Product Type Can't be empty")
        return
      }
      const formdata = new FormData();
      formdata.append("ProductTitle", productTitle)
      formdata.append("ProductDescription", productdescription)
      formdata.append("productType", productType)
      formdata.append("ProductShortDescription", ProductShortDescription)

      if (selectedFile) {
        if (selectedFile.size >= 2000000) {
          alert("File Size Should Less Than 2 Mb")
          return
        }
        else {
          formdata.append("productimage", selectedFile)
        }
      }


      const { data } = await axiosInstance.put(`/api/product/${id}`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      
      setLoading(false)
      alert(data.message)
      navigate("/admin/product")
    } catch (error) {
      alert(error.response.data.message)
      setLoading(false)
    }
  }


  return (

    <>

      <Sidebar />

      <div className="p-1 my-container active-cont">
        {/* <!-- Top Nav --> */}
        <nav className="navbar top-navbar navbar-light bg-light px-5">
          <a className="btn border-0" id="menu-btn" onClick={togglehandle}><i className="bx bx-menu"></i></a>
        </nav>
        {/* <!--End Top Nav --> */}
        <h3 className="text-dark p-3">ADMIN ADD USER 💻 📱
        </h3>

        <div className="row mx-2 " style={{ backgroundColor: "#FDF8DB" }}>

          <div className="toogler"><h3>Add Category</h3></div>

          <form onSubmit={handleUserdataSubmit}>
            <div className="col-md-6 align-items-center">
              <label>Product Title</label>
              <input className="form-control " type="text" value={productTitle} onChange={e => setproductTitle(e.target.value)} placeholder="Product Title" required />
            </div>
            <div className="col-md-6 align-items-center">
                            <label>Product short Description</label>
                            <input className="form-control " type="text" value={ProductShortDescription } onChange={e => setProductShortDescription(e.target.value)} placeholder="Description" required />
                        </div>
            <div className="col-md-6 align-items-center">
              <label>Product Description</label>
              <input className="form-control " type="text" value={productdescription} onChange={e => setproductdescription(e.target.value)} placeholder="Description" required />
            </div>
            <div className="col-md-6">
              <label>Service Type</label>
              <select class="form-select form-select-sm" aria-label=".form-select-sm example" value={productType} onChange={(e) => setproductType(e.target.value)}>
                <option selected hidden>Select Category</option>
                {
                  cate && cate.map((category) => (
                    <>
                      <option value={category._id}>{category.categoryName}</option>
                    </>
                  ))
                }
                {/* <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option> */}
              </select>
            </div>

            <div className="col-md-6 align-items-center">
              <label>Product Image (1600 X 700 px) (2 mb size)</label>
              <input type="file" class="form-control" aria-describedby="emailHelp" placeholder="Enter file" onChange={e => setSelectedFile(e.target.files[0])}  />
            </div>

            <div className="col-md-6" style={{ padding: "5px" }}>
              <div className="d-flex flex-row">
                {
                  loading ? <>
                    <button className="btn btn-success" >Loading</button>
                  </> : <>
                    <button className="btn btn-success" >Submit</button>
                  </>
                }
              </div>
            </div>
          </form>

        </div>

      </div>


    </>
  )
}

export default EditProduct