import React from 'react'
import { Link } from 'react-router-dom'

const Eventbanner = () => {
  return (
    <>
      <div className="section2 d-flex justify-content-start align-items-center py-4">
            <div className="container-fluid px-0">
              <div
                className="text-start text-white  col-md-7 p-lg-5 p-3 "
                style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
              >
                <Link to="/safety">
                  <div className="container px-lg-5">
                    <p style={{ margin: "0px" }}>At Natura</p>
                    <h2>SAFETY</h2>
                    <p  style={{ marginBottom: "0.6rem",marginTop:"0px" }}>
                      isn't just a concept, it is a <span>mindset</span> espoused{" "}
                      <br />
                      by all the teammates at all levels.
                    </p>
                    <p style={{
                      lineHeight:"25px",
                      
                    }}><p
                    style={{ marginBottom: "0.3rem" }}
                    >Ensuring </p>
                    <b style={{ color: "#FEBD15" }}>ZERO MISHAPS</b>
                    </p>
                    
                  </div>
                </Link>
              </div>
            </div>
          </div>
    
    </>
  )
}

export default Eventbanner